<template>
    <div class="container-fluid z-2">

        <b-modal id="patient-summary-modal" hide-footer size="xl" no-close-on-backdrop>

            <template #modal-title>
                <div class="d-flex">

                    <div class="">
                        <b>Patient Name:</b>
                        {{ patient_summary.patientName }}
                    </div>
                    <div class="ml-3">
                        <b>HN:</b>
                        {{ patient_summary.HN }}
                    </div>
                    <div class="ml-3">
                        <b>Concession Type:</b>
                        {{ patient_summary.concessionType || '' }}
                    </div>
                    <div class="ml-3">
                        <b>Insurance:</b>
                        {{ patient_summary.insurance || '' }}
                    </div>
                </div>
            </template>

            <div class="row bg-blue">
                <div class="col-3">
                    Time :
                    <MazPicker v-model="patient_summary.time" :format="`DD/MM/YYYY HH:mm`"
                        :formatted="`DD/MM/YYYY HH:mm`" placeholder="Time" />
                </div>


                <div class="col-3">
                    <label>Branch
                        <select v-model="patient_summary.branch" class="form-control form-select">
                            <option v-for="dd in $store.state.branches" :key="dd.id" :value="(dd.name_en)">{{
                                dd.name_en }}
                            </option>
                        </select>
                    </label>
                </div>
                <div class="col-3">
                    Service Type :
                    <MazSelect label="Service Type" :placeholder="'Service Type'" v-model="patient_summary.serviceType"
                        :options="prepared_service" search />
                    <div v-if="patient_summary.serviceType === 'Other'">
                        <MazInput placeholder="Other Service Type" v-model="patient_summary.serviceType_other" />
                    </div>
                </div>
                <div class="col-3 ">
                    Sender :
                    <MazSelect label="Sender" :placeholder="'Sender'" v-model="patient_summary.sender"
                        :options="prepared_senders" search />
                    <div v-if="patient_summary.sender === 'Other'">
                        <MazInput placeholder="Other Sender" v-model="patient_summary.sender_other" />
                    </div>
                </div>
                <div class="col-3 ">
                    Sender Log :
                    <MazSelect placeholder="Sender Log" v-model="patient_summary.senderLog" :options="staff" multiple
                        search />
                </div>

            </div>
            <div class="row bg-green">
                <div class="col-12 d-flex" v-for="(i, index) in patient_summary.diagnosis" :key="index">
                    <div class="flex-fill">
                        Diagnosis :
                        <icd10-select v-model="patient_summary.diagnosis[index]" :iNo="index"></icd10-select>
                    </div>
                    <div class="ml-2">
                        <button type="button" class="btn btn-primary"
                            @click="addRowList('patient_summary', 'diagnosis', 1)">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div class="ml-2">
                        <button type="button" class="btn btn-secondary"
                            @click="removeRowList('patient_summary', 'diagnosis', index)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row bg-red">
                <div v-for="(item, index) in patient_summary.rejects" :key="index" class="col-12 mb-3">
                    <div class="row">
                        <div class="col-6">
                            <div class="d-flex">
                                <div class="flex-fill">
                                    Reject:
                                    <MazSelect v-model="item.reject" placeholder="Reject" :options="prepared_rejects"
                                        search />
                                    <div v-if="item.reject === 'Other'">
                                        <MazInput placeholder="Other Reject" v-model="item.reject_other" />
                                    </div>
                                </div>
                                <div class="ml-2 mt-3">
                                    <button type="button" class="btn btn-primary"
                                        @click="addRowListr('patient_summary', 'rejects', 1)">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                                <div class="ml-2 mt-3">
                                    <button type="button" class="btn btn-secondary"
                                        @click="removeRowList('patient_summary', 'rejects', index)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            Reason:
                            <MazInput placeholder="Reason" v-model="item.reason" />
                        </div>
                        <div class="col-12">
                            Problem:
                            <MazInput placeholder="Problem" v-model="item.problem" />
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    Retry:
                    <MazSelect placeholder="Retry" v-model="patient_summary.retry" :options="staff" multiple search />
                </div>
            </div>
            <div class="row bg-green d-flex">

                <div v-for="(item, index) in patient_summary.treatments" :key="index" class="col-12 mb-3">
                    <div class="row">
                        <div class="col-8">
                            <div class="d-flex">
                                <div class="flex-fill">

                                    Treatment :
                                    <MazSelect placeholder="Treatment" v-model="item.treatment"
                                        :options="prepared_treatments" search />
                                    <div v-if="item.treatment == 'Other'">
                                        <MazInput placeholder="Other Treatment" v-model="item.treatment_other" />
                                    </div>

                                </div>
                                <div class="ml-2 mt-3">
                                    <button type="button" class="btn btn-primary"
                                        @click="addRowListt('patient_summary', 'treatments', 1)">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                                <div class="ml-2 mt-3">
                                    <button type="button" class="btn btn-secondary"
                                        @click="removeRowList('patient_summary', 'treatments', index)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            Area :
                            <MazInput :placeholder="'Area'" v-model="item.area" />
                        </div>
                        <div class="col-2">
                            Unit :
                            <MazInput :placeholder="'Unit'" v-model="item.unit" />
                        </div>
                        <div class="col-3">
                            Order :
                            <MazSelect :placeholder="'Order'" v-model="item.order" :options="staff" multiple search />
                        </div>
                        <div class="col-3">
                            Practice :
                            <MazSelect :placeholder="'Practice'" v-model="item.practice" :options="staff" multiple
                                search />
                        </div>
                    </div>
                </div>

            </div>
            <div class="row bg-yellow d-flex ">

                <div class="col-3">
                    Bill Log :
                    <MazSelect :placeholder="'Bill Log'" v-model="patient_summary.billLog" :options="staff" multiple
                        search />
                </div>
                <div class="col-3">
                    Med Log :
                    <MazSelect :placeholder="'Med Log'" v-model="patient_summary.medLog" :options="staff" multiple
                        search />
                </div>
                <div class="col-12">
                    <textarea :placeholder="'Note'" v-model="patient_summary.note" cols="120" rows="3"
                        class="m-3"></textarea>
                </div>

            </div>
            <div class="row bg-gray d-flex ">

                <div class="col-4">
                    Bill Audit : {{ patient_summary.billAudit_status }}
                    <div class="d-flex">
                        <b-form-group size="xl" stacked :multiple="false">
                            <b-form-radio value="" v-model="patient_summary.billAudit_status"
                                v-if="($store.state.user.assigned_roles[0].name === 'SystemAdmin')">
                            </b-form-radio>
                            <b-form-radio value="Correct" :disabled="isDisabled_Bill('Correct')"
                                v-model="patient_summary.billAudit_status">
                                <i class="fa fa-check text-success ml-1" aria-hidden="true"></i>
                            </b-form-radio>
                            <b-form-radio value="Wrong" :disabled="isDisabled_Bill('Wrong')"
                                v-model="patient_summary.billAudit_status">
                                <i class="fa fa-times text-danger ml-1" aria-hidden="true"></i>
                            </b-form-radio>
                            <b-form-radio value="Revised" :disabled="isDisabled_Bill('Revised')"
                                v-model="patient_summary.billAudit_status">
                                <img :src="require('@/assets/revised-icon.png')" width="20" class="mb-1">
                            </b-form-radio>
                        </b-form-group>
                        <div class="ml-3">
                            <div v-for="(invoice, index) in patient_summary.payment_status[0].invoice_id" :key="index"
                                class="row">
                                <div class="col-10 mt-1">
                                    <span style="font-size: smaller;">
                                        Invoice ID : {{ invoice }}
                                    </span>
                                </div>

                                <div class="col-2">
                                    <button class="btn btn-light text-white" @click="showInvoiceInModal(invoice)">
                                        <i class="fas fa-receipt" style='color:#0b97e5'></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-9">
                        <MazSelect :placeholder="'Bill Audit'" v-model="patient_summary.billAudit"
                            :options="filteredInsuranceAudit" multiple search />
                    </div>
                </div>
                <div class="col-4">
                    Med Audit : {{ patient_summary.medAudit_status }}

                    <div class="row">
                        <b-form-group size="xl" stacked :multiple="false" class="col-6">
                            <b-form-radio value="" v-model="patient_summary.medAudit_status"
                                v-if="($store.state.user.assigned_roles[0].name === 'SystemAdmin')">
                            </b-form-radio>
                            <b-form-radio value="Correct" :disabled="isDisabled_Med('Correct')"
                                v-model="patient_summary.medAudit_status">
                                <i class="fa fa-check text-success ml-1" aria-hidden="true"></i>
                            </b-form-radio>

                            <b-form-radio value="Wrong" :disabled="isDisabled_Med('Wrong')"
                                v-model="patient_summary.medAudit_status">
                                <i class="fa fa-times text-danger ml-1" aria-hidden="true"></i>
                            </b-form-radio>

                            <b-form-radio value="Revised" :disabled="isDisabled_Med('Revised')"
                                v-model="patient_summary.medAudit_status">
                                <img :src="require('@/assets/revised-icon.png')" width="20" class="mb-1">
                            </b-form-radio>
                        </b-form-group>

                        <b-form-group size="xl" stacked :multiple="false" class="col-6">

                            <b-form-radio :value="true" v-model="patient_summary.medAudit_lock"
                                @change="handleLockChange">
                                <i class="fa fa-lock" aria-hidden="true"></i>
                                Lock
                            </b-form-radio>
                            <b-form-radio :value="false" v-model="patient_summary.medAudit_lock"
                                v-if="($store.state.user.assigned_roles[0].name === 'SystemAdmin')">
                                <i class="fa fa-unlock" aria-hidden="true"></i>
                                Unlock
                            </b-form-radio>
                        </b-form-group>
                    </div>
                    <div class="col-9">
                        <MazSelect :placeholder="'Med Audit'" v-model="patient_summary.medAudit"
                            :options="filteredPublicHealthAudit" multiple search />
                    </div>

                </div>
                <div class="col-4">
                    Status:
                    <div><i class="fa fa-check text-success ml-1" aria-hidden="true"></i>
                        Correct: The bill/medication is accurate and requires no changes.
                    </div>
                    <div><i class="fa fa-times text-danger ml-1 mr-1" aria-hidden="true"></i>
                        Wrong: The bill/medication contains errors and needs revision.
                    </div>
                    <div><img :src="require('@/assets/revised-icon.png')" width="20" class="">
                        Revised: The bill/medication previously incorrect but has been corrected already.
                    </div>
                </div>
                <div class="col-6 d-flex mt-3">
                    <div>
                        <button class="btn btn-success" @click="sendToObserve()"><i class="fa fa-bed"
                                aria-hidden="true"></i> Send
                            to
                            Observe</button>
                    </div>
                    <div class="ml-3">
                        <button class="btn btn-success" @click="sendToIPD()"><i class="fa fa-bed"
                                aria-hidden="true"></i> Send to
                            IPD</button>
                    </div>
                    <div class="ml-3">
                        <button class="btn btn-success" @click="sendToReferral()"><i class="fa fa-ship"
                                aria-hidden="true"></i> Send
                            to Referral</button>
                    </div>
                </div>

            </div>
            <div class="d-flex justify-content-end">
                <div v-if="!patient_summary.medAudit_lock">
                    <button class="btn btn-primary" @click="savePatientSummary()">Save</button>
                </div>
                <div class="ml-3" v-if="!patient_summary.medAudit_lock">
                    <button class="btn btn-danger" @click="deletePatientSummary()">Delete</button>
                </div>

            </div>
        </b-modal>

    </div>
</template>

<script>

import { db } from "../../db";
import moment from "moment";
export default {
    props: ['patient_summary', 'showInvoice'],
    firestore() {
        return {
            rejects: db.collection("Reject"),
            treatments: db.collection("Treatment"),
            senders: db.collection("Sender"),
            services: db.collection("ServiceType"),
        }
    },
    data() {
        return {
            staff: [],
            rejects: [],
            treatments: [],
            senders: [],
            services: [],
            doctor: [],
            insurance: [],
            nurse: [],
            paramedic: [],
            publicHealth: [],

        }
    },

    watch: {
        'patient_summary.rejects': {
            handler(newVal) {
                newVal.forEach((item) => {
                    if (item.reject !== 'Other' && item.reject_other) {
                        item.reject_other = '' // Clear the field if 'Other' is not selected
                    }
                })
            },
            deep: true, // Watch nested objects inside the array
        },
        'patient_summary.treatments': {
            handler(newVal) {
                newVal.forEach((item) => {
                    if (item.treatment !== 'Other' && item.treatment_other) {
                        item.treatment_other = ''
                    }
                })
            },
            deep: true,

        },
        'patient_summary.sender'(newVal) {
            if (newVal && newVal !== 'Other') {
                this.patient_summary.sender_other = ''
            }

        },
        'patient_summary.serviceType'(newVal) {
            if (newVal && newVal !== 'Other') {
                this.patient_summary.serviceType_other = ''
            }

        },

    },
    mounted() {
        this.fetchStaff()
    },
    methods: {
        async fetchStaff() {
            try {
                const [doctorSnap, insuranceSnap, nurseSnap, paramedicSnap, publicHealthSnap] = await Promise.all([
                    db.collection("doctor_lists").get(),
                    db.collection("insurance_lists").get(),
                    db.collection("nurse_lists").get(),
                    db.collection("paramedic_lists").get(),
                    db.collection("publicHealth_lists").get(),
                ])

                this.doctor = doctorSnap.docs.map(doc => doc.data())
                this.insurance = insuranceSnap.docs.map(doc => doc.data())
                this.nurse = nurseSnap.docs.map(doc => doc.data())
                this.paramedic = paramedicSnap.docs.map(doc => doc.data())
                this.publicHealth = publicHealthSnap.docs.map(doc => doc.data())
                this.staff = [
                    ...this.doctor,
                    ...this.insurance,
                    ...this.nurse,
                    ...this.paramedic,
                    ...this.publicHealth,
                ]

            } catch (error) {
                console.error("Error fetching staff data:", error)
            }

        },
        addRowList(modelGrp, model, value) {
            if (modelGrp && model) {
                this[modelGrp][model].push({ code: "", title: "" })
                this[modelGrp][model + "Length"] += value
            } else {
                this[model].push({})
                this[model + "Length"] += value
            }
        },
        removeRowList(modelGrp, model, index) {
            if (modelGrp && model && index) {
                if (this[modelGrp][model].length > 1) {
                    this[modelGrp][model].splice(index, 1)
                }
            }
        },
        addRowListr(modelGrp, model, value) {
            if (modelGrp && model) {
                this[modelGrp][model].push({ reject: "", reason: "", problem: "" })
                this[modelGrp][model + "Length"] += value
            } else {
                this[model].push({})
                this[model + "Length"] += value
            }
        },
        addRowListt(modelGrp, model, value) {
            if (modelGrp && model) {
                this[modelGrp][model].push({ treatment: "", area: "", unit: "", order: [], practice: [] })
                this[modelGrp][model + "Length"] += value
            } else {
                this[model].push({})
                this[model + "Length"] += value
            }
        },
        async handleLockChange(value) {

            if (value === true) {
                if (confirm('Are you sure you want to lock the patient summary?')) {
                    await this.savePatientSummary()
                } else {
                    this.patient_summary.medAudit_lock = false
                }
            }
        },
        async savePatientSummary() {
            let loader = this.$loading.show({})

            try {
                if(this.patient_summary.rejects && this.patient_summary.rejects.length > 0 && this.patient_summary.rejects[0].reject && (!this.patient_summary.retry || this.patient_summary.retry.length <= 0)){
                    this.$alert('please fill the retry')
                    loader.hide()
                    return
                }
                this.patient_summary.time = moment(this.patient_summary.time, "DD/MM/YYYY HH:mm").toDate()
                delete this.patient_summary.vgt_id
                delete this.patient_summary.originalIndex
                delete this.patient_summary.payment_status
                const patient_summary = { ...this.patient_summary }
                const cleanedData = this.replaceUndefinedWithNull(patient_summary)
                const querySnapshot = await db.collection("PatientSummary")
                    .where("HN", "==", cleanedData.HN)
                    .where("time", "==", cleanedData.time)
                    .get()

                if (!querySnapshot.empty) {
                    querySnapshot.forEach(async (doc) => {
                        await db.collection("PatientSummary").doc(doc.id).update(cleanedData)
                    })
                    this.$alert("Edit Patient Summary Success.", null, "success")
                    this.$emit("saved")
                } else {
                    let docRef = await db.collection("PatientSummary").add(cleanedData)
                    await db.collection("PatientSummary").doc(docRef.id).update({ id: docRef.id })
                    this.$alert("Save Patient Summary Success.", null, "success")
                    this.$emit("saved")
                }
                for (let data of cleanedData.rejects) {
                    if (data.reject_other && data.reject_other !== null) {
                        await db.collection("Reject").doc(data.reject_other).set({
                            label: data.reject_other,
                            value: data.reject_other,
                        })
                    }
                }
                for (let data of cleanedData.treatments) {
                    if (data.treatment_other && data.treatment_other !== null) {
                        await db.collection("Treatment").doc(data.treatment_other).set({
                            label: data.treatment_other,
                            value: data.treatment_other,
                        })
                    }
                }
                if (cleanedData.sender_other && cleanedData.sender_other !== null) {
                    await db.collection("Sender").doc(cleanedData.sender_other).set({
                        label: cleanedData.sender_other,
                        value: cleanedData.sender_other,
                    })
                }
                if (cleanedData.serviceType_other && cleanedData.serviceType_other !== null) {
                    await db.collection("ServiceType").doc(cleanedData.serviceType_other).set({
                        label: cleanedData.serviceType_other,
                        value: cleanedData.serviceType_other,
                    })
                }
            } catch (error) {
                this.$alert(`error ${error}`, null, "error")
                loader.hide()
            }
            loader.hide()
            this.$bvModal.hide("patient-summary-modal")

        },
        replaceUndefinedWithNull(obj) {
            return Object.fromEntries(
                Object.entries(obj)
                    .map(([key, value]) => [key, value === undefined ? null : value])
            )
        },
        async sendToObserve() {
            let prepared_Observe = {}
            prepared_Observe.time = this.patient_summary.time
            prepared_Observe.HN = this.patient_summary.HN
            prepared_Observe.VN = this.patient_summary.VN
            prepared_Observe.concessionType = this.patient_summary.concessionType || null
            prepared_Observe.diagnosis = this.patient_summary.diagnosis
            prepared_Observe.treatments = [{ treatment: "", area: "", unit: "", order: [], practice: [] }]
            prepared_Observe.insurance = this.patient_summary.insurance
            prepared_Observe.assist_insurance = this.patient_summary.assist_insurance || null
            prepared_Observe.patientName = this.patient_summary.patientName
            prepared_Observe.ipd_status = 'Regular Round'
            prepared_Observe.billLog = []
            prepared_Observe.medLog = []
            prepared_Observe.serviceType = this.patient_summary.serviceType || null
            prepared_Observe.staff_refer = []
            prepared_Observe.sender = this.patient_summary.sender || null
            prepared_Observe.senderLog = this.patient_summary.senderLog || []
            prepared_Observe.patientTitle = this.patient_summary.patientTitle || null
            prepared_Observe.patientDateofBirth = this.patient_summary.patientDateofBirth || null
            prepared_Observe.patientAge = this.patient_summary.patientAge ? this.patient_summary.patientAge : null
            prepared_Observe.nationality = this.patient_summary.nationality ? this.patient_summary.nationality : null
            prepared_Observe.round = "0"
            prepared_Observe.billAudit = []
            prepared_Observe.medAudit = []

            let docRef = await db.collection("ObservePatientSummary").add(prepared_Observe)
            await db.collection("ObservePatientSummary").doc(docRef.id).update({ id: docRef.id })

            this.$bvModal.hide("patient-summary-modal")
            this.$alert("Send Patient Summary Data to Observe Success.", null, "success")
        },
        async sendToIPD() {
            let prepared_IPD = {}
            prepared_IPD.time = this.patient_summary.time
            prepared_IPD.HN = this.patient_summary.HN
            prepared_IPD.VN = this.patient_summary.VN
            prepared_IPD.concessionType = this.patient_summary.concessionType || null
            prepared_IPD.diagnosis = this.patient_summary.diagnosis
            prepared_IPD.treatments = [{ treatment: "", area: "", unit: "", order: [], practice: [] }]
            prepared_IPD.insurance = this.patient_summary.insurance
            prepared_IPD.assist_insurance = this.patient_summary.assist_insurance || null
            prepared_IPD.patientName = this.patient_summary.patientName
            prepared_IPD.ipd_status = 'Regular Round'
            prepared_IPD.billLog = []
            prepared_IPD.medLog = []
            prepared_IPD.serviceType = this.patient_summary.serviceType || null
            prepared_IPD.staff_refer = []
            prepared_IPD.sender = this.patient_summary.sender || null
            prepared_IPD.senderLog = this.patient_summary.senderLog || []
            prepared_IPD.patientTitle = this.patient_summary.patientTitle || null
            prepared_IPD.patientDateofBirth = this.patient_summary.patientDateofBirth || null
            prepared_IPD.patientAge = this.patient_summary.patientAge ? this.patient_summary.patientAge : null
            prepared_IPD.nationality = this.patient_summary.nationality ? this.patient_summary.nationality : null
            prepared_IPD.billAudit = []
            prepared_IPD.medAudit = []
            let docRef = await db.collection("IpdPatientSummary").add(prepared_IPD)
            await db.collection("IpdPatientSummary").doc(docRef.id).update({ id: docRef.id })

            this.$bvModal.hide("patient-summary-modal")
            this.$alert("Send Patient Summary Data to IPD Success.", null, "success")
        },
        async sendToReferral() {
            let prepared_refers = {}
            prepared_refers.time = this.patient_summary.time
            prepared_refers.HN = this.patient_summary.HN
            prepared_refers.VN = this.patient_summary.VN
            prepared_refers.concessionType = this.patient_summary.concessionType || null
            prepared_refers.diagnosis = this.patient_summary.diagnosis
            prepared_refers.treatments = [{ treatment: "", area: "", unit: "", order: [], practice: [] }]
            prepared_refers.insurance = this.patient_summary.insurance
            prepared_refers.assist_insurance = this.patient_summary.assist_insurance || null
            prepared_refers.patientName = this.patient_summary.patientName
            prepared_refers.type = 'OPD'
            prepared_refers.practice = []
            prepared_refers.order = []
            prepared_refers.billLog = []
            prepared_refers.medLog = []
            prepared_refers.serviceType = this.patient_summary.serviceType || null
            prepared_refers.onDuty = []
            prepared_refers.onCall = []
            prepared_refers.sender = this.patient_summary.sender || null
            prepared_refers.senderLog = this.patient_summary.senderLog || []
            prepared_refers.patientTitle = this.patient_summary.patientTitle || null
            prepared_refers.patientDateofBirth = this.patient_summary.patientDateofBirth || null
            prepared_refers.patientAge = this.patient_summary.patientAge ? this.patient_summary.patientAge : null
            prepared_refers.nationality = this.patient_summary.nationality ? this.patient_summary.nationality : null
            prepared_refers.billAudit = []
            prepared_refers.medAudit = []
            let docRef = await db.collection("ReferralPatientSummary").add(prepared_refers)
            await db.collection("ReferralPatientSummary").doc(docRef.id).update({ id: docRef.id })
            this.$bvModal.hide("patient-summary-modal")
            this.$alert("Send Patient Summary Data to Referral Success.", null, "success")
        },
        async deletePatientSummary() {
            let loader = this.$loading.show({})
            await db.collection('PatientSummary').doc(this.patient_summary.id).delete()
            loader.hide()
            this.$alert("Delete Patient Summary Success.", null, "success")
            this.$bvModal.hide("patient-summary-modal")
            this.$emit("delete")
        },
        isDisabled_Bill(option) {
            const currentStatus = this.patient_summary.billAudit_status
            if (option === 'Correct') {
                return currentStatus === 'Wrong' || currentStatus === 'Revised'
            }
            if (option === 'Revised') {
                return currentStatus === 'Correct'
            }
            return false
        },
        isDisabled_Med(option) {
            const currentStatus = this.patient_summary.medAudit_status
            if (option === 'Correct') {
                return currentStatus === 'Wrong' || currentStatus === 'Revised'
            }
            if (option === 'Revised') {
                return currentStatus === 'Correct'
            }
            return false
        },
        showInvoiceInModal(invoice) {
            this.showInvoice(invoice)

        },
    },
    computed: {
       
        filteredInsuranceAudit() {
            return this.insurance.filter(option => option.is_auditer)
        },
        filteredPublicHealthAudit() {
            return this.publicHealth.filter(option => option.is_auditer)
        },
        prepared_rejects() {
            const blankOptions = [{ label: "", value: null }]
            const otherOptions = [{ label: "Other", value: "Other" }]
            return [
                ...blankOptions,
                ...this.rejects.map((i) => ({
                    label: i.label,
                    value: i.value,
                })),
                ...otherOptions
            ]
        },
        prepared_treatments() {
            const blankOptions = [{ label: "", value: null }]
            const otherOptions = [{ label: "Other", value: "Other" }]
            return [
                ...blankOptions,
                ...this.treatments.map((i) => ({
                    label: i.label,
                    value: i.value,
                })),
                ...otherOptions
            ]
        },
        prepared_senders() {
            const blankOptions = [{ label: "", value: null }]
            const otherOptions = [{ label: "Other", value: "Other" }]
            return [
                ...blankOptions,
                ...this.senders.map((i) => ({
                    label: i.label,
                    value: i.value,
                })),
                ...otherOptions
            ]
        },
        prepared_service() {
            const blankOptions = [{ label: "", value: null }]
            const otherOptions = [{ label: "Other", value: "Other" }]
            return [
                ...blankOptions,
                ...this.services.map((i) => ({
                    label: i.label,
                    value: i.value,
                })),
                ...otherOptions
            ]
        },
    }

}
</script>

<style lang="scss"></style>