<template>
  <div>
    <div class="d-flex">
      <!-- <button
        class="btn btn-primary"
        style="width: 200px; height: 40px;"
        type="button"
      >
        <i class="fas fa-print"></i> Print
      </button> -->
      <div class="text-center ml-auto" style="cursor: pointer;" @click.prevent="$i18n.locale = 'en'">
        <country-flag country="us" size="big" />
        <div>English</div>
      </div>
      <div class="text-center ml-2" style="cursor: pointer;" @click.prevent="$i18n.locale = 'th'">
        <country-flag country="th" size="big" />
        <div>Thai</div>
      </div>
    </div>
    <div class="form-row mt-2">
      <div class="form-group col-md-4 text-muted">
        <label>Branch {{ $store.state.branches[$store.state.branch - 1].name_en }}</label>
        <!-- <MazSelect
          v-model="patient.branch_name"
          :placeholder="$t('branch_select')"
          :options="
            $store.state.branches.map(b => {
              return {
                label: `${b.name_en}`,
                value: `${b.name_en}`,
              };
            })
          "
        /> -->
      </div>
      <div class="col-md-5"></div>
      <div class="form-group col-md-3 text-muted">
        <MazPicker v-model="patient.visit_date" format="DD/MM/YYYY HH:mm" formatted="DD/MM/YYYY HH:mm"
          position="bottom right" :placeholder="$t('visit_date')" />
      </div>
    </div>
    <div class="bg-gray p-3">
      <span class="text-head">{{ $t("patient_info") }}</span>
      <div class="row">
        <div class="col-xl-2 col-sm-12">
          <div class="profile-box mt-2">
            <input type="file" ref="patient_img" capture name="profile" @change.prevent="previewImage"
              class="input-file" accept="image/*" />
            <p v-if="!profileImg">
              <i class="fa fa-camera-retro" aria-hidden="true" style="font-size: 40px; color: rgba(0, 0, 0, 0.1);"></i>
            </p>
            <img v-if="profileImg" class="img-preview" :src="profileImg" />
          </div>
          <div class="d-flex mt-2">
            <b-button class="btn btn-primary" v-b-modal.take-photo><i class="fas fa-camera"></i> Take Photo</b-button>
            <button class="ml-2 btn btn-danger" @click="() => {
              this.profileImg = null;
              this.profileBase64 = null;
              this.profileFile = null;
            }"><i class="fas fa-times"></i></button>
          </div>
          <b-modal id="take-photo" title="Take Photo" size="lg" hide-footer>
            <div class="container">
              <div class="row">
                <web-cam class="col-12" :onUsePhoto="onTakenPhoto" />
              </div>
            </div>
          </b-modal>
          <span class="red" v-if="uploadImageError">File size must not exceed 3MB.</span>
        </div>
        <div class="col-xl-10 col-sm-12">
          <div class="row">
            <div class="col-xl-2 col-sm-12">
              <MazSelect v-model="patient.title" :placeholder="$t('title')" :options="[
                { label: 'Mr', value: 'Mr' },
                { label: 'Mrs', value: 'Mrs' },
                { label: 'Miss', value: 'Miss' },
                { label: 'Ms', value: 'Ms' },
                { label: 'ด.ช.', value: 'ด.ช.' },
                { label: 'ด.ญ.', value: 'ด.ญ.' },
                { label: 'นาย', value: 'นาย' },
                { label: 'นาง', value: 'นาง' },
                { label: 'นางสาว', value: 'นางสาว' },
                { label: 'None', value: 'None' },
              ]" />
            </div>
            <div class="col-xl-4 col-sm-12">
              <MazInput :placeholder="$t('firstName')" v-model="patient.first_name"
                :right-icon-name="patient.first_name ? 'check' : 'warning'" />
            </div>
            <div class="col-xl-6 col-sm-12">
              <MazInput :placeholder="$t('lastName')" v-model="patient.last_name"
                :right-icon-name="patient.last_name ? 'check' : 'warning'" />
            </div>
          </div>

          <div class="row mt-3">

            <div class="col-xl-3 col-sm-12">
              <MazPicker v-model="patient.date_of_birth" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY"
                :placeholder="$t('dob')" :left-icon-name="patient.date_of_birth ? 'check' : 'warning'" />
            </div>
            <div class="col-xl-3 col-sm-12">
              <MazRadio v-model="patient.gender" value="Male" radioValue="Male">
                {{ $t("male") }}
              </MazRadio>
              <MazRadio v-model="patient.gender" value="Female" radioValue="Female">
                {{ $t("female") }}
              </MazRadio>
            </div>
            <div class="col-xl-3 col-sm-12" v-if="!emergency">
              <MazSelect v-model="patient.marital_status" :placeholder="$t('marital_status')" :options="[
                { label: 'None', value: null },
                ...maritalStatus.map(item => {
                  return {
                    label: `${$i18n.locale === 'th' ? item.name_th : item.name_en
                      }`,
                    value: item.name_en
                  };
                })
              ]
                " />
            </div>
            <div class="col-xl-3 col-sm-12">
              <MazSelect v-model="patient.blood_group" :placeholder="'Blood Group'" :options="[
                { label: 'N/A', value: 'N/A' },
                { label: 'A', value: 'A' },
                { label: 'B', value: 'B' },
                { label: 'O', value: 'O' },
                { label: 'AB', value: 'AB' },
              ]" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xl-3">
              <MazSelect v-model="patient.nationality" :placeholder="$t('nation')" search :options="[
                { label: 'None', value: null },
                ...nationalities.map(item => {
                  return {
                    label: `${item}`,
                    value: item
                  };
                })
              ]
                " :left-icon-name="patient.nationality ? 'check' : 'warning'" />
            </div>
            <div class="col-xl-3">
              <MazInput :placeholder="$t('id_no')" v-model="patient.passport_id"></MazInput>
            </div>
            <div class="col-xl-3" v-if="!emergency">
              <MazSelect v-model="patient.occupation" :placeholder="$t('occupation')" search :options="[
                { label: 'None', value: null },
                { label: 'Other', value: 'Other' },
                ...$store.state.occupations.map(item => {
                  return {
                    label: `${item}`,
                    value: item
                  };
                })
              ]
                " />
              <input v-if="patient.occupation === 'Other'" type="text" class="form-control mt-2"
                v-model="patient.occupation_other" placeholder="Occupation">
            </div>
            <div class="col-xl-3" v-if="!emergency">
              <MazSelect v-model="patient.religion" :placeholder="$t('religion')" search :options="[
                { label: 'None', value: null },
                { label: 'Other', value: 'Other' },
                ...religions.map(item => {
                  return {
                    label: `${$i18n.locale === 'th' ? item.name_th : item.name_en
                      }`,
                    value: item.name_en
                  };
                })
              ]
                " />
              <input v-if="patient.religion === 'Other'" type="text" class="form-control mt-2"
                v-model="patient.religion_other" placeholder="Religion">
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-xl-6">
              <MazPhoneNumberInput no-example default-country-code="TH" :only-countries="['TH']"
                :placeholder="$t('phone_th')" v-model="patient.phone_th" />
            </div>
            <div class="col-xl-6" v-if="$i18n.locale !== 'th'">
              <MazPhoneNumberInput no-example :placeholder="$t('phone_inter')" v-model="patient.phone_inter" />
            </div>

          </div>
          <div class="row mt-4">
            <div class="col-xl-6" v-if="!emergency">
              <MazInput :placeholder="$t('email')" v-model="patient.email"></MazInput>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="bg-gray">
      <div class="pd10">
        <div class="row">
          <div class="col-xl-4">
            <div><span class="text-danger">*</span><span style="color:#d63384;"> Our team will assist your claim
              </span><img :src="require('@/assets/love-icon.png')"> </div>
            <span> {{ $t("insurance") }}</span>
            <div class="d-flex">
              <div class="form-check form-check-inline ml-3">
                <input class="form-check-input" type="radio" name="is_travel_insulance" id="is_travel_insulance_yes"
                  value="yes" v-model="patient.has_travel_insurance" />
                <label class="form-check-label" for="is_travel_insulance_yes">{{
                  $t("yes")
                }}</label>
              </div>
              <div class="form-check form-check-inline ml-3">
                <input class="form-check-input" type="radio" name="is_travel_insulance" id="is_travel_insulance_no"
                  value="no" v-model="patient.has_travel_insurance" />
                <label class="form-check-label" for="is_travel_insulance_no">{{
                  $t("no")
                }}</label>
              </div>
              <div class="form-check form-check-inline ml-3">
                <input class="form-check-input" type="radio" name="is_travel_insulance" id="is_travel_insulance_notsure"
                  value="notsure" v-model="patient.has_travel_insurance" />
                <label class="form-check-label" for="is_travel_insulance_notsure">{{ $t("not_sure") }}</label>
              </div>
            </div>
            <div class="mt-2">
              <MazSelect v-if="this.patient.has_travel_insurance === 'yes'" v-model="patient.travel_insurance"
                :placeholder="$t('Insurance')" search :options="[
                  { label: 'None', value: null },
                  { label: 'Other', value: 'Other' },
                  ..._.sortBy(
                    $store.state.insurances
                      .filter(c => c.id !== 'II000')
                      .map(c => ({ label: `${c.name_en}`, value: c.id })),
                    'label'
                  )
                ]" />

              <input v-if="patient.travel_insurance === 'Other'" type="text" class="form-control mt-2"
                v-model="patient.travel_insurance_other" placeholder="Insurance">
              <input v-if="this.patient.has_travel_insurance === 'yes'" type="text" class="form-control mt-2"
                v-model="patient.assist_insurance" placeholder="Assistance Insurance">
            </div>
          </div>
          <div class="col-xl-4" style="" v-if="!emergency">
            <span style="color: red;">* </span>
            <MazSelect v-model="patient.how_did_you_find_us" :placeholder="$t('how_did_you_find_us')" search multiple
              searchPlaceholder="Select more than one option" :options="howDidYouFindUs.map(item => {
                return {
                  label: item,
                  value: item
                };
              })
                "></MazSelect>
          </div>
          <div class="col-xl-4" style="margin-top: 13px;">
            <MazInput v-model="patient.note" :placeholder="$t('note')" textarea />
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="bg-red p-3">
      <div class="mb-3">
        <MazInput :placeholder="$t('symptom')" v-model="patient.symptom"></MazInput>
      </div>
      <span class="text-head">
        <b>{{ $t("allergy") }}</b>
        <i class="fa fa-exclamation-triangle icon-red ml-2"></i>
      </span>
      <div class="row mt-3">
        <div class="col-xl-2">
          <label for="Drug Allergy">{{ $t("drug_allergy") }}</label>
        </div>
        <div class="col-xl-4 mt-1">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="is_allergy" id="allergy_yes" :value="true"
              v-model="patient.is_allergy" />
            <label class="form-check-label" for="allergy_yes">{{
              $t("yes")
            }}</label>
          </div>
          <div class="form-check form-check-inline pl-5">
            <input class="form-check-input" type="radio" name="is_allergy" id="allergy_no" :value="false"
              v-model="patient.is_allergy" />
            <label class="form-check-label" for="allergy_no">{{
              $t("no")
            }}</label>
          </div>
          <div v-if="patient.is_allergy">
            <div class="d-flex mt-1" v-for="(i, index) in patient.allergies" :key="index">
              <div class="flex-4">
                <MazSelect v-model="patient.allergies[index]" placeholder="Drug" search :options="[
                  { label: 'None', value: null },
                  ..._.filter($store.state.allergies, { type: 'medical' }).map(
                    b => {
                      return {
                        label: `${b.name}`,
                        value: b.id
                      };
                    }
                  )
                ]
                  " />
              </div>
              <div class="ml-2">
                <button type="button" class="btn btn-primary" @click.prevent="patient.allergies.push('')">
                  <i class="fa fa-plus"></i>
                </button>
              </div>
              <div class="ml-1">
                <button type="button" class="btn btn-secondary" @click.prevent="removeAllergy(index)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-xl-2"></div> -->
        <div class="col-xl-2">
          <label for="Drug Allergy">{{ $t("other_allergy") }}</label>
        </div>
        <div class="col-xl-4 mt-1">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="is_other_allergy" id="other_allergu_yes" :value="true"
              v-model="patient.is_other_allergy" />
            <label class="form-check-label" for="other_allergu_yes">{{
              $t("yes")
            }}</label>
          </div>
          <div class="form-check form-check-inline pl-5">
            <input class="form-check-input" type="radio" name="is_other_allergy" id="other_allergu_no" :value="false"
              v-model="patient.is_other_allergy" />
            <label class="form-check-label" for="other_allergu_no">{{
              $t("no")
            }}</label>
          </div>
          <div v-if="this.patient.is_other_allergy">
            <div class="d-flex mt-1" v-for="(i, index) in patient.other_allergies" :key="index">
              <div class="flex-4">
                <MazSelect v-model="patient.other_allergies[index]" placeholder="Allergen" search :options="[
                  { label: 'None', value: null },
                  ..._.filter($store.state.allergies, item => {
                    return item.type !== 'medical';
                  }).map(b => {
                    return {
                      label: `${b.name}`,
                      value: b.id
                    };
                  })
                ]
                  " />
              </div>
              <div class="ml-2">
                <button type="button" class="btn btn-primary" @click.prevent="patient.other_allergies.push('')">
                  <i class="fa fa-plus"></i>
                </button>
              </div>
              <div class="ml-1">
                <button type="button" class="btn btn-secondary" @click="removeOtherAllergy(index)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="bg-gray" v-if="!emergency">
      <div class="pd10">
        <span class="text-head">
          {{ $t("home_addr") }}
          <i class="icon-red fa fa-exclamation-triangle icon-red"></i>
        </span>
        <div class="row mt-2">
          <div class="col-xl-4">
            <MazInput v-model="patient.home_address" placeholder="address" textarea />
          </div>
          <div class="col-xl-3">
            <MazInput v-model="patient.home_city" :placeholder="$t('city')" />
          </div>
          <div class="col-xl-3">
            <MazSelect v-model="patient.home_country" :placeholder="$t('country')" search :options="[
              { label: 'None', value: null },
              ...$store.state.countries.map(c => {
                return { label: c.name, value: c.name };
              })
            ]
              " />
          </div>
          <div class="col-xl-2">
            <MazInput v-model="patient.home_zipcode" :placeholder="$t('zipcode')" />
          </div>
        </div>
        <div class="mt-3">
          <span class="text-head">
            {{ $t("type_stay_phiphi") }}
            <i class="icon-red fa fa-exclamation-triangle icon-red"></i>
          </span>
          <div>
            <span style="color:#d63384;">* To provide personalized care tailored to your situation</span>
          </div>
          <div class="row mt-2">
            <div class="col-xl-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value id="is_tourist" v-model="patient.is_tourist" />
                <label class="form-check-label" for="is_tourist">{{
                  $t("tourist")
                }}</label>
              </div>
            </div>
            <div class="col-xl-4" v-if="patient.is_tourist">
              <!-- <div>
                <MazPicker v-model="patient.arrival_date" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY"
                  :placeholder="$t('arrival_date_in')" />
              </div> -->
              <div>
                <MazPicker v-model="patient.departure_date" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY"
                  :placeholder="$t('departure_date_out')" :min-date="date_now" />
              </div>
            </div>
            <div class="col-xl-4" v-if="patient.is_tourist">
              <MazSelect v-model="patient.tourist_hotel" :placeholder="$t('present_addr_th')" search :options="[
                { label: 'None', value: null },
                { label: 'Other', value: 'Other' },
                ..._.sortBy(_.filter($store.state.places, { type: 'hotel' }).map(c => {
                  return { label: c.name_en, value: c.name_en };
                }), 'label')
              ]
                " />
              <input v-if="patient.tourist_hotel === 'Other'" type="text" class="form-control mt-2"
                v-model="patient.tourist_hotel_other" placeholder="Hotel">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-xl-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value id="is_resident" v-model="patient.is_resident" />
                <label class="form-check-label" for="is_resident">{{
                  $t("resident")
                }}</label>
              </div>
            </div>
            <div class="col-xl-4" v-if="patient.is_resident">
              <div>
                <MazPicker v-model="patient.departure_date" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY"
                  :placeholder="$t('departure_date_out')" :min-date="date_now" />
              </div>
            </div>
            <!-- <div class="col-xl-4">
              <MazSelect v-model="patient.resident_workplace" :placeholder="$t('work_place')" search :options="[
                { label: 'None', value: null },
                { label: 'Other', value: 'Other' },
                ..._.sortBy($store.state.places.map(c => {
                  return { label: c.name_en, value: c.name_en };
                }), 'label')
              ]
                " />
              <input v-if="patient.resident_workplace === 'Other'" type="text" class="form-control mt-2"
                v-model="patient.resident_workplace_other" placeholder="Work Place">
            </div> -->
            <div class="col-xl-4" v-if="patient.is_resident">
              <MazSelect v-model="patient.resident_hotel" :placeholder="$t('hotel_name')" search :options="[
                { label: 'None', value: null },
                { label: 'Other', value: 'Other' },
                ..._.sortBy(_.filter($store.state.places, { type: 'hotel' }).map(c => {
                  return { label: c.name_en, value: c.name_en };
                }), 'label')
              ]
                " />
              <input v-if="patient.resident_hotel === 'Other'" type="text" class="form-control mt-2"
                v-model="patient.resident_hotel_other" placeholder="Hotel">
            </div>

          </div>
          <div class="row mt-2">
            <div class="col-xl-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value id="phiphi_resident"
                  v-model="patient.is_phiphi_resident" />
                <label class="form-check-label" for="phiphi_resident">{{
                  $t("phiphi_resident")
                }}</label>
              </div>
            </div>
            <div class="col-xl-4" v-if="patient.is_phiphi_resident">
              <MazSelect v-model="patient.resident_workplace" :placeholder="$t('work_place')" search :options="[
                { label: 'None', value: null },
                { label: 'Other', value: 'Other' },
                ..._.sortBy($store.state.places.map(c => {
                  return { label: c.name_en, value: c.name_en };
                }), 'label')
              ]
                " />
              <input v-if="patient.resident_workplace === 'Other'" type="text" class="form-control mt-2"
                v-model="patient.resident_workplace_other" placeholder="Work Place">
            </div>
            <div class="col-xl-4" v-if="patient.is_phiphi_resident">
              <MazSelect v-model="patient.resident_hotel" :placeholder="$t('hotel_name')" search :options="[
                { label: 'None', value: null },
                { label: 'Other', value: 'Other' },
                ..._.sortBy(_.filter($store.state.places, { type: 'hotel' }).map(c => {
                  return { label: c.name_en, value: c.name_en };
                }), 'label')
              ]
                " />
              <input v-if="patient.resident_hotel === 'Other'" type="text" class="form-control mt-2"
                v-model="patient.resident_hotel_other" placeholder="Hotel">
            </div>
          </div>

        </div>
      </div>
    </div>
    <legend></legend>
    <div class="bg-gray">
      <div class="pd10">
        <span class="text-head">{{ $t("emergency_contact") }}</span>
        <div class="row mt-3">
          <div class="col-xl-3 col-sm-12">
            <MazInput v-model="patient.emergency_first_name" :placeholder="$t('firstName')" />
          </div>
          <div class="col-xl-3 col-sm-12">
            <MazInput v-model="patient.emergency_last_name" :placeholder="$t('lastName')" />
          </div>
          <div class="col-xl-2">
            <MazSelect v-model="patient.emergency_relationship" :placeholder="$t('relation')" search :options="[
              { label: 'None', value: null },
              ...$store.state.relationships.map(c => {
                return { label: c, value: c };
              })
            ]
              " />
            <MazInput class="mt-2" v-if="patient.emergency_relationship === 'Other'"
              v-model="patient.emergency_relationship_other" :placeholder="'other relationships'" />
          </div>
          <div class="col-xl-4">
            <MazPhoneNumberInput no-example :placeholder="$t('phone')" v-model="patient.emergency_phone" />
          </div>
        </div>
        <div class="mt-3" v-if="!emergency">
          <span>{{ $t("home_addr") }}</span>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="patient.is_emergency_address_same_home"
              @change="handleHomeAddress" />
            <label class="form-check-label" for="is_same_addr">{{
              $t("same_home_addr")
            }}</label>
          </div>
        </div>
        <div class="row mt-2" v-if="!emergency">
          <div class="col-xl-4">
            <MazInput v-model="patient.emergency_address" placeholder="address" textarea
              :readonly="patient.is_emergency_address_same_home" />
          </div>
          <div class="col-xl-3">
            <MazInput v-model="patient.emergency_city" :placeholder="$t('city')"
              :readonly="patient.is_emergency_address_same_home" />
          </div>
          <div class="col-xl-3">
            <MazSelect v-model="patient.emergency_country" :placeholder="$t('country')" search :options="$store.state.countries.map(c => {
              return { label: c.name, value: c.name };
            })
              " :readonly="patient.is_emergency_address_same_home" />
          </div>
          <div class="col-xl-2">
            <MazInput v-model="patient.emergency_zipcode" :placeholder="$t('zipcode')"
              :readonly="patient.is_emergency_address_same_home" />
          </div>
        </div>
      </div>
    </div>
    <legend></legend>

    <div class="row" v-if="mode !== 'edit'">
      <div class="col-xl-4"></div>
      <div class="col-xl-4">
        <div class="box-border">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value id="is_certify" v-model="patient.is_certify" />
            <label class="form-check-label" for="is_certify">{{
              $t("certify_check")
            }}</label>
          </div>
        </div>
        <div class="sign-border" style="text-align: center;">
          <div>
            <span class="text-muted">{{ $t("signature") }}</span>
          </div>
          <div class="row">
            <div class="col-12 mt-2">
              <VueSignaturePad id="signature" width="100%" height="300px" ref="signaturePad"
                :options="{ penColor: '#000', onEnd: onEndSignature }" v-model="patient.signature_url" />
            </div>
          </div>
          <div class="mt-2">
            <a class="btn btn-outline-secondary" @click="clear">Clear</a>
          </div>
        </div>
        <div class="sign-detail">
          <div class="row">
            <div class="form-check form-check-inline">
              <MazRadio v-model="patient.certify_relation" value="Patient" :radioValue="'Patient'">
                {{ $t("patient") }}
              </MazRadio>
            </div>
            <div class="form-check form-check-inline" style="margin-left: 10px;">
              <MazRadio v-model="patient.certify_relation" value="Parent" :radioValue="'Parent'">
                {{ $t("parent") }}
              </MazRadio>
            </div>
          </div>
          <div class="row">
            <div class="form-check form-check-inline">
              <MazRadio v-model="patient.certify_relation" value="Spouse" :radioValue="'Spouse'">
                {{ $t("spouse") }}
              </MazRadio>
            </div>

          </div>

          <div class="row mt-2">
            <div class="form-check form-check-inline">
              <MazRadio v-model="patient.certify_relation" value="Other" :radioValue="'Other'">
                {{ $t("other") }}
              </MazRadio>
            </div>
            <div class="form-check form-check-inline" v-if="patient.certify_relation === 'Other'">
              <input type="text" class="form-control" :placeholder="$t('relation_to_patient')"
                v-model="patient.certify_relation_other" />
            </div>
          </div>
          <div class="row mt-2">
            <input type="text" class="form-control" :placeholder="$t('fullname_information')"
              v-model="patient.patient_full_name" />
          </div>

        </div>
      </div>
      <div class="col-xl-4"></div>
    </div>
    <hr />

    <div class="row w-100">
      <div class="col-8">
        <span class="d-none">
          {{ patient.registerLog.length }}
        </span>
      </div>
      <div class="col-4 d-flex">
        <i class="icon-red fa fa-exclamation-triangle icon-red mt-1"></i>
        {{ $t("staff") }}
        <MazSelect class="ml-3" :placeholder="'Register by'" v-model="patient.registerLog" :options="staff" multiple
          search />
      </div>
    </div>

    <hr />
    <div class="text-center">
      <button v-if="mode === 'edit'" type="button" class="btn btn-primary btn-lg" style="width: 250px;"
        @click="updatePatient">
        <i class="fas fa-edit"></i> Update Patient
      </button>
      <button v-else type="button" class="btn btn-primary btn-lg" style="width: 250px;"
        @click="savePatient(patient.branch)">
        <i class="fas fa-save"></i> {{ $t("create_patient") }}
      </button>

      <button style="margin-left: 10px; width: 250px;" type="button" class="btn btn-danger btn-lg">
        <i class="fas fa-times"></i> {{ $t("cancel") }}
      </button>
    </div>
  </div>

  

</template>

<script>
const howDidYouFindUs = [
  "Other",
  "Hotel",
  "Hospital",
  "Tour Guide",
  "Pharmacy",
  "Street Sign",
  "Bar",
  "Friend",
  "Local People",
  "Restaurant",
  "Walk Pass",
  "Brochure",
  "Insurance",
  "Boat Man",
  "Travel agency",
  "Facebook",
  "Google",
  "Google map",
  "Tatoo Shop",
  "Diving Shop",
  "Market",
  "Website"
];
const maritalStatus = [
  { name_en: "Single", name_th: "โสด" },
  { name_en: "Married", name_th: "แต่งงาน" },
  { name_en: "Engaged", name_th: "หมั้น" },
  { name_en: "Seperated", name_th: "แยกทาง" },
  { name_en: "Divorced", name_th: "หย่า" },
  { name_en: "Widowed", name_th: "หม้าย" }
];
const gender = ["Male", "Female"];
import patientMixins from "../mixins/patient";
import nationalities from "../assets/nationalities.json";
import religions from "../assets/religions.json";

import { storage } from "../db";
import _ from "lodash";
import moment from 'moment'
export default {
  props: ["emergency", "mode", "initData"],
  mixins: [patientMixins],
  created() {


    if (this.initData) {
      let patient = {
        ...this.initData
      }
      if (patient.visit_date) patient.visit_date = moment(patient.visit_date.toDate()).format('DD/MM/YYYY HH:mm')
      patient.arrival_date = moment().format('DD/MM/YYYY')
      if (patient.departure_date) patient.departure_date = moment(patient.departure_date.toDate()).format('DD/MM/YYYY')
      if (patient.date_of_birth) patient.date_of_birth = moment(patient.date_of_birth.toDate()).format('DD/MM/YYYY')

      if (patient.allergies) {
        patient.allergies = patient.allergies.map(item => item.id)
      }

      if (patient.other_allergies) {
        patient.other_allergies = patient.other_allergies.map(item => item.id)
      }

      if (patient.travel_insurance) {
        patient.travel_insurance = patient.travel_insurance.id
      }
      this.patient = patient
      this.profileImg = patient.img_url
    }
  },
  data() {
    return {
      profileImg: null,
      profileFile: null,
      profileBase64: null,
      signatureFile: null,
      uploadImageError: false,
      // dd list
      howDidYouFindUs: howDidYouFindUs,
      maritalStatus: maritalStatus,
      nationalities,
      religions,
      disabled: 0,
      other: null,
      date_now: moment().format("DD/MM/YYYY"),


    };
  },

  methods: {

    submitDidYouFindUs(item) {
      this.patient.how_did_you_find_us.push(item);
      this.other = null;
    },
    handleHomeAddress() {
      if (this.patient.is_emergency_address_same_home) {
        this.patient.emergency_address = this.patient.home_address;
        this.patient.emergency_city = this.patient.home_city;
        this.patient.emergency_country = this.patient.home_country;
        this.patient.emergency_zipcode = this.patient.home_zipcode;
      } else {
        this.patient.emergency_address = null;
        this.patient.emergency_city = null;
        this.patient.emergency_country = null;
        this.patient.emergency_zipcode = null;
      }
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    onEndSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      this.signatureFile = data;
    },
    previewImage(event) {
      this.profileFile = null;
      this.uploadImageError = false;
      const input = event.target;

      if (input.files && input.files[0]) {
        let file = input.files[0];
        const vm = this;

        const reader = new FileReader();

        reader.onload = e => {
          vm.profileImg = e.target.result;
        };

        reader.readAsDataURL(input.files[0]);

        this.profileFile = file;
      } else {
        this.profileFile = null;
      }
    },
    fillSameAddr() {
      if (this.patient.is_same_addr) {
        if (this.patient.address) {
          this.patient.emergency_address = this.patient.address;
          this.patient.emergency_city = this.patient.city;
          this.patient.emergency_country = this.patient.country;
          this.patient.emergency_zipcode = this.patient.zipcode;
          this.disabled = 1;
        }
      } else {
        this.disabled = 0;
      }
    },
    onTakenPhoto(canvas) {
      console.log({
        image: canvas
      })
      this.profileImg = canvas;
      this.profileBase64 = canvas;
    }
  }
};
</script>

<style lang="scss" scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-color: white;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.text-head {
  font-size: 20px;
  padding-bottom: 8px;
}

.label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
}

.img-preview {
  height: 100%;
  width: 100%;
  border-radius: 0.25em;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.profile-box {
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  height: 150px;
  /* minimum height */
  width: 150px;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  border-radius: 0.25em;

  &:hover {
    background: #e6e6e6;
  }

  .input-file {
    opacity: 0;
    /* invisible but it's there! */
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }

  p {
    font-size: 1em;
    text-align: center;
    padding: 50px 10px;
  }
}

.input-icon {
  position: absolute;
  top: 32px;
  right: 22px;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.2), 1px -1px 0 rgba(0, 0, 0, 0.2),
    -1px 1px 0 rgba(0, 0, 0, 0.2), 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.icon-green {
  color: rgb(14, 174, 0);
}

.icon-red {
  color: rgb(214, 38, 38);
}

.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.sign-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  width: 100%;
  height: 430px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.sign-detail {
  padding: 20px;
  margin: auto;
  width: 100%;
  margin-bottom: 20px;
}

input[type="radio"] {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

.fade-noselect {
  color: rgba(0, 0, 0, 0.3);
}

.other-option {
  padding: 5px;
}
</style>

<i18n>
{
  "en": {
    "pageTitle": "Regular Registration Form",
    "patient_info": "Patient Information",
    "branch_select": "Select Branch",
    "branch_hospital": "Hospital",
    "branch_clinic": "Clinic",
    "visit_date": "First Visit Date",
    "title": "Title",
    "firstName": "First Name",
    "lastName": "Last Name",
    "dob": "Date of Birth",
    "male": "Male",
    "female": "Female",
    "marital_status": "Marital Status",
    "nation": "Nationality",
    "id_no": "I.D. No./ Passport No.",
    "occupation": "Occupation",
    "religion": "Religion",
    "phone_th": "Thai Mobile Phone",
    "phone_inter": "International Mobile Phone",
    "phone": "Mobile Phone",
    "email": "Email Address",
    "symptom": "Symptom",
    "allergy": "Allergy",
    "drug_allergy": "Drug Allergy",
    "yes": "Yes",
    "no": "No",
    "other_allergy": "Other Allergy",
    "other_allergy_ph": "",
    "home_addr": "Home Address",
    "city": "City",
    "country": "Country",
    "zipcode": "Zip Code",
    "type_stay_phiphi": "Type of Stay in Phiphi",
    "tourist": "Tourist",
    "arrival_date_in": "Arrival Date in Phiphi",
    "departure_date_out": "Departure Date out in Phiphi",
    "present_addr_th": "Present Address in PhiPhi",
    "resident": "Resident",
    "phiphi_resident": "PhiPhi Resident",
    "work_place": "Work Place",
    "hotel_name": "Hotel Name",
    "emergency_contact": "Emergency Contact Person",
    "relation": "Relation",
    "same_home_addr": "Same as my home address",
    "address_placeholder": "Bldg. No. / Blk No. / Street / District",
    "insurance": "Insurance",
    "not_sure": "Not Sure",
    "how_did_you_find_us": "How did you find us?",
    "note": "Note",
    "certify_check": "I certify that the information provided above is true and correct.",
    "signature": "Signature",
    "patient": "Patient",
    "parent": "Parents / Guardian",
    "spouse": "Spouse / Friend",
    "staff": "Staff",
    "other": "Other",
    "relation_to_patient": "Relationship to patient",
    "fullname_information": "Full Name of person who give the information",
    "create_patient": "Create Patient",
    "cancel": "Cancel"
  },
  "th": {
    "pageTitle": "ลงทะเบียนผู้ป่วยใหม่",
    "patient_info": "ข้อมูลพื้นฐาน",
    "branch_select": "เลือกสาขา",
    "branch_hospital": "สาขา เวิลด์เมดบีชฟร้อน (ข้างสถานีตำรวจ)",
    "branch_clinic": "สาขา เวิลด์เมดคลินิก (ซอยสลิงกี้)",
    "visit_date": "วันที่ลงทะเบียน",
    "title": "คำนำหน้า",
    "firstName": "ชื่อ",
    "lastName": "นามสกุล",
    "dob": "วันเกิด",
    "male": "ชาย",
    "female": "หญิง",
    "marital_status": "สถานภาพ",
    "nation": "สัญชาติ",
    "id_no": "หมายเลขบัตรประชาชน/ หนังสือเดินทาง",
    "occupation": "อาชีพ",
    "religion": "ศาสนา",
    "phone_th": "เบอร์โทรศัพท์ไทย",
    "phone_inter": "",
    "phone": "เบอร์โทรศัพท์",
    "email": "อีเมล",
    "symptom": "อาการสำคัญ",
    "allergy": "ประวัติการแพ้",
    "drug_allergy": "แพ้ยา",
    "yes": "มี",
    "no": "ไม่มี",
    "other_allergy": "แพ้สิ่งอื่นๆ",
    "other_allergy_ph": "",
    "home_addr": "ที่อยู่ปัจจุบัน",
    "city": "จังหวัด",
    "country": "ประเทศ",
    "zipcode": "รหัสไปรษณีย์",
    "type_stay_phiphi": "วัตถุประสงค์ในเกาะพีพี",
    "tourist": "นักท่องเที่ยว",
    "arrival_date_in": "วันที่เดินทางมาถึงเกาะพีพี",
    "departure_date_out": "วันที่เดินทางออกจากเกาะพีพี",
    "present_addr_th": "ที่พักในเกาะพีพี",
    "resident": "นักท่องเที่ยวไทย",
    "phiphi_resident": "อาศัยในเกาะพีพี",
    "work_place": "สถานที่ทำงาน",
    "hotel_name": "ที่พักในเกาะพีพี",
    "emergency_contact": "บุคคลติดต่อกรณีฉุกเฉิน",
    "relation": "ความสัมพันธ์กับผู้ป่วย",
    "same_home_addr": "ที่อยู่เดียวกันกับข้าพเจ้า",
    "address_placeholder": "เลขที่/ ตรอก/ ซอย/ ถนน/ ตำบล/ อำเภอ",
    "insurance": "ประกันสุขภาพ",
    "not_sure": "ไม่แน่ใจ",
    "how_did_you_find_us": "รู้จักเราได้อย่างไร",
    "note": "เพิ่มเติม",
    "certify_check": "ข้าพเจ้ารับรองว่าข้อมูลดังกล่าวข้างต้นเป็นความจริง",
    "signature": "ลายมือชื่อผู้ให้ข้อมูล",
    "patient": "ผู้ป่วย",
    "parent": "ผู้ปกครอง",
    "spouse": "คู่สมรส/เพื่อน",
    "staff": "พนักงาน",
    "other": "อื่นๆ",
    "relation_to_patient": "ความสัมพันธ์กับคนไข้",
    "fullname_information": "ชื่อ-นามสกุล ผู้ให้ข้อมูลแทนผู้ป่วย",
    "create_patient": "บันทึก",
    "cancel": "ยกเลิก"
  }
}
</i18n>
