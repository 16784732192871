<template>
    <div>
        <toast-notification :visible="showNotification" @dismiss="dismissNotification"></toast-notification>
        <b-form-group size="xl" stacked :multiple="false"
            v-if="($store.state.user.assigned_roles[0].name === 'SystemAdmin') || ($store.state.user.assigned_roles[0].name === 'HOP')">

            <b-form-radio :value="true" @change="handleLockChange">
                <i class="fa fa-lock" aria-hidden="true"></i>
                Lock
            </b-form-radio>
            <b-form-radio :value="false" @change="handleUnLockChange">
                <i class="fa fa-unlock" aria-hidden="true"></i>
                Unlock
            </b-form-radio>
        </b-form-group>
        <vue-good-table :columns="columns" :rows="filteredItems" :lineNumbers="true" :sort-options="{ enabled: false, }"
            :fixed-header="true" max-height="1000px" class="observe-patient-summary-table"
            :group-options="{ enabled: true }" style="z-index: 0;" :row-style-class="getRowClass">

            <template slot="table-row" slot-scope="props">

                <div v-if="props.column.field == 'patientDetails'">
                    <div class="text-dark d-flex justify-content-between">
                        <h5 class="text-primary clickable" @click="GoToPatient(props.row.HN)">
                            <b>{{ (props.row.patientTitle || '') + " " + props.row.patientName }}</b>
                        </h5>
                        <button class="btn ml-2" @click="showPatient(props)" v-if="!props.row.medAudit_lock">
                            <i class="fa-solid fa-pen-to-square" aria-hidden="true"></i>
                        </button>
                        <button class="btn ml-2" @click="showPatient(props)" v-else>
                            <i class="fa fa-lock mr-2" aria-hidden="true"></i>
                            <i class="fa-solid fa-pen-to-square" aria-hidden="true"></i>
                        </button>

                    </div>
                    <div class="d-flex">
                        <i class="text-muted"><b>HN: </b><small>{{ props.row.HN }}</small></i>
                        <div class="ml-3" v-if="props.row.patientAge">
                            <b>Age: </b> {{ props.row.patientAge ? props.row.patientAge : '' }} years
                        </div>
                    </div>
                    <div class="d-flex">
                        <div v-if="props.row.nationality" class="mr-3">
                            <b>Nationality: </b> <small>{{ props.row.nationality }} </small>
                        </div>
                    </div>

                    <div v-if="props.row.sender">
                        <hr>
                        <b>Senders :</b> <span class="text-info">{{ props.row.sender_other ? props.row.sender_other :
                            props.row.sender }}</span>
                    </div>

                </div>
                <div v-else-if="props.column.field == 'time'">
                    <h5>{{ formatTime(props.row.time) }}</h5>
                </div>
                <div v-else-if="props.column.field == 'concession'">

                    <div v-if="props.row.payment_status && props.row.payment_status.length">

                        <div v-for="(status, index) in props.row.payment_status[0].status" :key="index">
                            <div class="d-flex justify-content-between ">
                                <div class="mt-2 d-flex align-items-center">
                                    <span class="font-weight-bold me-2" :class="{
                                        'resident-row': props.row.payment_status[0].concession_type[index] == 'Resident',
                                        'foreigner-row': props.row.payment_status[0].concession_type[index] == 'Foreigner',
                                        'insurance-row': props.row.payment_status[0].concession_type[index] == 'Insurance',
                                    }">
                                        {{ props.row.payment_status[0].concession_type[index] }}
                                    </span>
                                    <span class="font-weight-bold" v-if="props.row.payment_status[0].case_type[index]"
                                        :class="{
                                            'insurance-row-caseType-OP': showCaseType(props.row.payment_status[0].case_type[index]) === 'OP' || showCaseType(props.row.payment_status[0].case_type[index]) === 'OP+',
                                            'insurance-row-caseType-ST': showCaseType(props.row.payment_status[0].case_type[index]) === 'ST' || showCaseType(props.row.payment_status[0].case_type[index]) === 'ST+',
                                        }">
                                        {{ showCaseType(props.row.payment_status[0].case_type[index]) }}
                                    </span>
                                    <span v-if="statusData(status)"
                                        :class="[statusData(status).class, 'font-weight-bold']">
                                        {{ statusData(status).label }}
                                    </span>
                                </div>
                                <div class="d-flex align-items-center">

                                    <button class="btn btn-light text-white"
                                        @click="showInvoice(props.row.payment_status[0].invoice_id[index])">

                                        <span :class="[statusData(status).color]" style="">
                                            <i :class="['fa', statusData(status).icon]" data-bs-toggle="tooltip"
                                                data-bs-placement="top" :title="statusData(status).title"
                                                style="transform: rotate(270deg); transition: transform 0.3s;"></i>
                                            <i class="fas fa-receipt" :class="[statusData(status).color]"></i>
                                        </span>


                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <hr>
                    <div v-if="props.row.insurance" class="d-flex align-items-center justify-content-between">
                        <div>
                            <span class="text-danger" v-if="props.row.insurance === 'notsure'"> CLARIFYING WITH PATIENT
                            </span>
                            <span class="text-danger" v-else-if="props.row.insurance === 'no'"> NO INSURANCE </span>
                            <span class="font-weight-bold" v-else>
                                {{ props.row.insurance }}
                            </span>
                            <div class="mt-2">
                                <span><b>Bill Log : </b></span>
                                <span class="mt-2 mb-2" v-if="(props.row.billLog && props.row.billLog.length > 0)">
                                    <span v-for="(name, index) in props.row.billLog" :key="index" class="mr-3"
                                        style="font-size: 75%;">
                                        <Tag :text="name" :tagColor="getRoleColor(name)" />
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center flex-wrap">
                            <span class="d-flex flex-column align-items-center ml-2 mb-1"
                                v-if="props.row.level === 'BEST'" style="font-size: 75px; position: relative;">
                                <i class="fa fa-certificate" style="margin-bottom: -1px; color: #007f5f;"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="BEST"></i>
                                <p style="font-size: 10px; margin-bottom: 10px; font-weight: bold; color: #ffffff; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                    class="text-center">BEST</p>
                            </span>

                            <span class="d-flex flex-column align-items-center ml-2 mb-1"
                                v-if="props.row.level === 'GOOD'" style="font-size: 75px; position: relative;">
                                <i class="fa fa-certificate" style="margin-bottom: -1px; color: #80b918;"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="GOOD"></i>
                                <p style="font-size: 10px; margin-bottom: 10px; font-weight: bold; color: #ffffff; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                    class="text-center">GOOD</p>
                            </span>

                            <span class="d-flex flex-column align-items-center ml-2 mb-1"
                                v-if="props.row.level === 'NORMAL'" style="font-size: 75px; position: relative;">
                                <i class="fa fa-certificate" style="margin-bottom: -1px; color: #ffb100;"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="NORMAL"></i>
                                <p style="font-size: 8px; margin-bottom: 10px; font-weight: bold; color: #ffffff; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                    class="text-center">NORMAL</p>
                            </span>

                            <span class="d-flex flex-column align-items-center ml-2"
                                v-if="props.row.level === 'GRAYLIST'" style="font-size: 60px;">
                                <i class="fa fa-exclamation-triangle" style="margin-bottom: -1px; color: #5f6266;"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="GRAYLIST"></i>
                                <p style="font-size: 10px; margin-bottom: 1px; font-weight: bold; color: #5f6266;"
                                    class="text-center ">
                                    GRAY</p>
                            </span>

                            <span class="d-flex flex-column align-items-center ml-2"
                                v-if="props.row.level === 'BLACKLIST'" style="font-size: 60px;">
                                <i class="fa fa-exclamation-triangle" style="margin-bottom: -1px; color: #1b1b1e;"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="BLACKLIST"></i>
                                <p style="font-size: 10px; margin-bottom: 1px; font-weight: bold; color: #1b1b1e;"
                                    class="text-center">
                                    BLACK</p>
                            </span>

                            <span class="d-flex flex-column align-items-center ml-2"
                                v-if="props.row.level === 'TEMPORARY BAN'" style="font-size: 60px;">
                                <i class="fa fa-exclamation-triangle" style="margin-bottom: -1px; color: #dc0000ff;"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="TEMPORARY BAN"></i>
                                <p style="font-size: 10px; margin-bottom: 1px; font-weight: bold; color: #dc0000ff;"
                                    class="text-center ">
                                    TEMP BAN</p>
                            </span>
                        </div>

                    </div>
                    <!-- <div v-if="props.row.assist_insurance">
                        <b>Assist Insurance : </b>
                        <span class="text-info">{{ props.row.assist_insurance }}</span>
                    </div> -->

                </div>

                <div v-else-if="props.column.field == 'room'">
                    <div class="font-weight-bold d-flex" v-if="props.row.room">
                        <div>
                            <span>{{ props.row.room }}</span>
                        </div>
                        <div class="ml-3">
                            <span class="text-info">{{ props.row.observe_time || null }}</span>
                        </div>

                    </div>
                    <div v-if="props.row.rangeOfStay">
                        <hr>
                        <b>Range Of Stay :</b> {{ props.row.rangeOfStay || '' }}
                    </div>
                    <div v-if="props.row.round">
                        <hr>
                        <b>Round :</b> {{ props.row.round || '' }}
                    </div>
                </div>

                <div v-else-if="props.column.field == 'diagnosis&treatment'">
                    <div v-for="(item, index) in props.row.diagnosis" :key="props.row.HN + '-diagnosis-' + index"
                        class="diag-class">
                        <b v-if="index === 0" class="mr-3">Diagnosis :</b>
                        <span class="text-info font-weight-bold">{{ item.title ? (index + 1 + '. ') + item.title : ''
                            }}</span>
                    </div>
                    <div class="mt-2">
                        <span><b>Med Log : </b></span>
                        <span class="mt-2" v-if="(props.row.medLog && props.row.medLog.length > 0)">
                            <span v-for="(name, index) in props.row.medLog" :key="index" class="mr-3"
                                style="font-size: 70%;">
                                <Tag :text="name" :tagColor="getRoleColor(name)" />
                            </span>
                        </span>
                    </div>

                    <hr>

                    <div v-for="(item, index) in props.row.treatments" :key="props.row.HN + '-treatment-' + index">

                        <div class="row"
                            :class="{ 'attend-class': item.treatment === 'Attended', 'observe-class': item.treatment === 'Observation', 'text-info': item.treatment !== 'Observation' && item.treatment !== 'Attended' }">

                            <div class="col-6">
                                <!-- <b v-if="index === 0" class="mr-3">Treatment :</b> #ffee93 -->
                                <span class="font-weight-bold">
                                    {{ item.treatment_other ? (index + 1 + '. ') + item.treatment_other :
                                        (item.treatment ? (index + 1 +
                                            '.') + item.treatment : '') }}
                                </span>

                            </div>
                            <div class="col-3" v-if="item.area">
                                <b>Area: </b> <span class="text-info">{{ item.area }}</span>
                            </div>
                            <div class="col-3" v-if="item.unit">
                                <b>Unit: </b> <span class="text-info">{{ item.unit }}</span>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6" v-if="item.order && item.order.length > 0">
                                <span><b>Order : </b></span>
                                <span v-for="(name, index) in item.order" :key="props.row.HN + '-order-' + index"
                                    style="font-size: 75%;">
                                    <Tag :text="getNameTitle(name)" :tagColor="getRoleColor(name)" />
                                </span>
                            </div>
                            <div class="col-6" v-if="item.practice && item.practice.length > 0">
                                <span><b>Practice : </b></span>
                                <span v-for="(name, index) in item.practice" :key="props.row.HN + '-practice-' + index"
                                    style="font-size: 75%;">
                                    <Tag :text="getNameTitle(name)" :tagColor="getRoleColor(name)" />
                                </span>
                            </div>
                        </div>
                        <hr v-if="index < props.row.treatments.length - 1">

                    </div>
                </div>

                <div v-else-if="props.column.field == 'note'">
                    {{ props.row.note || '-' }}
                    <div v-if="props.row.billAudit && props.row.billAudit.length > 0" class="mt-2">
                        <span><b>Bill Audit: </b></span>
                        <img :src="require('@/assets/revised-icon.png')" width="20" class="mb-1"
                            v-if="props.row.billAudit_status === 'Revised'">
                        <i class="fa fa-times text-danger" aria-hidden="true"
                            v-else-if="props.row.billAudit_status === 'Wrong'"></i>
                        <i class="fa fa-check text-success" aria-hidden="true"
                            v-else-if="props.row.billAudit_status === 'Correct'"></i>
                        <span v-for="(name, index) in props.row.billAudit" :key="props.row.HN + '-billaudit-' + index"
                            class="ml-2" style="font-size: 75%;">
                            <Tag :text="getNameTitle(name)" :tagColor="getRoleColor(name)" />
                        </span>
                    </div>
                    <div v-if="props.row.medAudit && props.row.medAudit.length > 0" class="mt-2">
                        <span><b>Med Audit: </b></span>
                        <img :src="require('@/assets/revised-icon.png')" width="20" class="mb-1"
                            v-if="props.row.medAudit_status === 'Revised'">
                        <i class="fa fa-times text-danger" aria-hidden="true"
                            v-else-if="props.row.medAudit_status === 'Wrong'"></i>
                        <i class="fa fa-check text-success" aria-hidden="true"
                            v-else-if="props.row.medAudit_status === 'Correct'"></i>
                        <span v-for="(name, index) in props.row.medAudit" :key="props.row.HN + '-medaudit-' + index"
                            class="ml-2" style="font-size: 75%;">
                            <Tag :text="getNameTitle(name)" :tagColor="getRoleColor(name)" />
                        </span>
                    </div>
                </div>
            </template>
        </vue-good-table>


        <observe-patient-summary-modal :patient_summary="patient_summary" @saved="SearchData" @delete="SearchData"
            :show-invoice="showInvoice" />
        <patient-invoice-modal :invoice="invoice" :visits="visits" @saveInvoice="SearchData" @deleteInvoice="SearchData"
            @showNewPayment="showNewPayment" />
        <patient-payment-modal :payment="payment" :total_invoiced="total_invoiced" :payment_applied="payment_applied"
            :total_outstanding="total_outstanding" @displayPaymentModal="displayPaymentModal" />
    </div>
</template>

<script>

import { db, Timestamp } from "../../db";
import moment from "moment";
import { getTotalPaymentApplied, getTotalOutstanding } from '../../helper/payment'
export default {
    props: ['dateStart', 'dateEnd', 'branch'],
    firestore() {
        return {
            insurances: db.collection("Insurance"),
        }
    },
    data() {
        return {
            staff: [],
            patient_summarys: [],
            temporaryPatientSummarys: [],
            patient_summary: {},
            doctor: [],
            insurance: [],
            nurse: [],
            paramedic: [],
            publicHealth: [],
            isEditPatient: false,
            showNotification: false,
            columns: [
                { label: "Time", field: "time", width: "75px", tdClass: (row) => { return this.changeColorClass(row.isTemporary) } },
                { label: "Patient Details", field: "patientDetails", width: "290px", tdClass: (row) => { return this.changeColorClass(row.isTemporary) } },
                { label: "Consession", field: "concession", width: "250px", tdClass: (row) => { return this.changeColorClass(row.isTemporary) } },
                { label: "Room", field: "room", thClass: 'room-tab', width: "138px", tdClass: (row) => { return this.changeColorClass(row.isTemporary) } },
                { label: "Diagnosis & Treatment", field: "diagnosis&treatment", thClass: 'med-tab', width: "400px", tdClass: (row) => { return this.changeColorClass(row.isTemporary) } },
                { label: "Note", field: "note", thClass: 'note-tab', width: "220px", tdClass: (row) => { return this.changeColorClass(row.isTemporary) } },

            ],
            visits: [],
            invoice: [],
            payment: {
                payment_id: null,
                issue_date: this.$moment(new Date(), 'HH:mm DD/MM/YYYY').toDate(),
                HN: null,
                VN: null,
                insurance_name: null,
                insurance_name_other: null,
                patient_name: null,
                cash: 0,
                credit: 0,
                credit_bank_name: null,
                insurance: 0,
                other: 0,
                internet_banking: 0,
            },
            total_invoiced: 0,
            payment_applied: 0,
            total_outstanding: 0,
        };
    },
    mounted() {
        this.fetchStaff()
        this.SearchData()
        //this.migrate_data()
    },
    methods: {
        async migrate_data() {
            console.log('Starting migration...')
            try {
                let patientSummaryRef = await db.collection('IpdPatientSummary').get()
                let withVNCount = 0
                let withoutVNCount = 0
                for (const doc of patientSummaryRef.docs) {
                    const data = doc.data()
                    if (data.VN) {
                        withVNCount++
                    } else {
                        withoutVNCount++
                    }
                }

                console.log(`IPD Documents with VN: ${withVNCount}`)
                console.log(`IPD Documents without VN: ${withoutVNCount}`)

                patientSummaryRef = await db.collection('ObservePatientSummary').get()
                withVNCount = 0
                withoutVNCount = 0
                for (const doc of patientSummaryRef.docs) {
                    const data = doc.data()
                    if (data.VN) {
                        withVNCount++
                    } else {
                        withoutVNCount++
                    }
                }

                console.log(`Observe Documents with VN: ${withVNCount}`)
                console.log(`Observe Documents without VN: ${withoutVNCount}`)

                patientSummaryRef = await db.collection('ReferralPatientSummary').get()
                withVNCount = 0
                withoutVNCount = 0
                for (const doc of patientSummaryRef.docs) {
                    const data = doc.data()
                    if (data.VN) {
                        withVNCount++
                    } else {
                        withoutVNCount++
                    }
                }

                console.log(`Referral Documents with VN: ${withVNCount}`)
                console.log(`Referral Documents without VN: ${withoutVNCount}`)

                // const batch = db.batch()
                // for (const doc of patientSummaryRef.docs) {
                //     const data = doc.data()
                //     if (!data.VN) {
                //         console.log(data.id)
                //         const patientDoc = await db.collection('Patient').doc(data.HN).get()
                //         if (patientDoc.exists) {
                //             const patientData = patientDoc.data()
                //             data.medAudit = []
                //             data.billAudit = []
                //             const visit_date = patientData.visit_date.toDate()
                //             let start = moment(visit_date).startOf('day').toDate()
                //             let end = moment(visit_date).endOf('day').toDate()
                //             const visitRef = await db.collection('Visit')
                //                 .where("visit_date", ">=", start)
                //                 .where("visit_date", "<", end)
                //                 .where("patient", "==", db.collection("Patient").doc(data.HN))
                //                 .get();
                //             if (visitRef.docs.length == 1) {
                //                 const visit = visitRef.docs[0].data()
                //                 data.VN = visit.VN
                //             }
                //         } else {
                //             console.warn(`Patient document not found for HN: ${data.HN}`)
                //         }
                //         batch.update(doc.ref, data)
                //     }
                // }
                // await batch.commit()
                // console.log('Migration completed successfully!')
            } catch (error) {
                console.error('Error during migration:', error)
            }

        },
        calculateAge(dateOfBirthTimestamp) {
            const dob = moment(dateOfBirthTimestamp.toDate())
            const now = moment()
            return now.diff(dob, 'years')
        },
        async fetchStaff() {
            try {
                const [doctorSnap, insuranceSnap, nurseSnap, paramedicSnap, publicHealthSnap] = await Promise.all([
                    db.collection("doctor_lists").get(),
                    db.collection("insurance_lists").get(),
                    db.collection("nurse_lists").get(),
                    db.collection("paramedic_lists").get(),
                    db.collection("publicHealth_lists").get(),
                ])

                this.doctor = doctorSnap.docs.map(doc => doc.data())
                this.insurance = insuranceSnap.docs.map(doc => doc.data())
                this.nurse = nurseSnap.docs.map(doc => doc.data())
                this.paramedic = paramedicSnap.docs.map(doc => doc.data())
                this.publicHealth = publicHealthSnap.docs.map(doc => doc.data())
                this.staff = [
                    ...this.doctor,
                    ...this.insurance,
                    ...this.nurse,
                    ...this.paramedic,
                    ...this.publicHealth,
                ]

            } catch (error) {
                console.error("Error fetching staff data:", error)
            }

        },
        async GetPaymentStatus(patient_summary) {
            patient_summary.payment_status = []

            const initialInvoiceRef = await db.collection('Invoice')
                .where('VN', '==', patient_summary.VN)
                .get()

            if (initialInvoiceRef.docs.length > 0) {
                const status = initialInvoiceRef.docs.map(doc => doc.data().status)
                const concession_type = initialInvoiceRef.docs.map(doc => doc.data().concession_type)
                const invoice_id = initialInvoiceRef.docs.map(doc => doc.data().invoice_id)
                const case_type = initialInvoiceRef.docs.map(doc =>
                    doc.data().concession_type === "Insurance" ? doc.data().case_type : null
                )
                const billLog = initialInvoiceRef.docs.map(doc => doc.data().billLog)
                const combine_data = { "status": status, "concession_type": concession_type, "invoice_id": invoice_id, "case_type": case_type, "billLog": billLog }
                patient_summary.payment_status.push(combine_data)
                this.SetConcession(patient_summary)
            } else {
                patient_summary.payment_status = [{ "status": [], concession_type: [], "invoice_id": [], "case_type": [], "billLog": [] }]
            }

        },
        SetConcession(patient_summary) {
            if (patient_summary.payment_status && patient_summary.payment_status.length) {
                // Define the priority order
                const priority = { "Insurance": 1, "Foreigner": 2, "Resident": 3 }

                // Find the highest-priority concession type in the payment_status array
                const highestPriority = patient_summary.payment_status
                    .filter(element => Array.isArray(element.concession_type) ? element.concession_type.length : element.concession_type)
                    .sort((a, b) => {
                        const typeA = Array.isArray(a.concession_type) ? a.concession_type[0] : a.concession_type
                        const typeB = Array.isArray(b.concession_type) ? b.concession_type[0] : b.concession_type
                        return priority[typeA] - priority[typeB]
                    })[0]

                // Set the concessionType in patient_summary based on the highest-priority item
                if (highestPriority) {
                    const concessionType = Array.isArray(highestPriority.concession_type)
                        ? highestPriority.concession_type[0] // Use the first value if it's an array
                        : highestPriority.concession_type

                    patient_summary.concessionType = concessionType
                }
            }
        },
        changeColorClass(value) {

            if (value == true) {
                return 'temporary-row'
            }
            else {
                return ''
            }

        },
        getNameTitle(name) {
            if (this.doctor.some(doctor => doctor.label === name)) {
                return `Dr. ${name}`
            }
            return name
        },
        getRoleClass(name) {
            if (this.doctor.some(element => element.label === name)) {
                return "doctor"
            }

            if (this.insurance.some(element => element.label === name)) {
                return "insurance"
            }

            if (this.nurse.some(element => element.label === name)) {
                return "nurse"
            }

            if (this.paramedic.some(element => element.label === name)) {
                return "paramedic"
            }

            if (this.publicHealth.some(element => element.label === name)) {
                return "publicHealth"
            }

            return ""
        },
        getRoleColor(name) {
            if (this.doctor.some(element => element.label === name)) {
                return "#274e13ff"
            }

            if (this.insurance.some(element => element.label === name)) {
                return "#3c78d8ff"
            }

            if (this.nurse.some(element => element.label === name) || this.paramedic.some(element => element.label === name)) {
                return "#1d800eff"
            }

            if (this.publicHealth.some(element => element.label === name)) {
                return "#a4c964ff"
            }

            return ""
        },
        formatTime(time) {
            return moment(time, "DD/MM/YYYY HH:mm").format("HH:mm")
        },
        showPatient(params) {
            if (params) {
                this.patient_summary = {
                    id: params.row.id,
                    ...params.row,
                }
                this.isEditPatient = true
            } else {
                this.isEditPatient = false
                this.patient_summary = {}
            }
            this.$bvModal.show("observe-patient-summary-modal")
        },
        checkObserveTime() {
            const now = moment();
            const sixHours = 6 * 60 * 60 * 1000 // 6 hours in milliseconds
            const observedPatients = this.patient_summarys.filter(patient => !patient.isObserve)
            observedPatients.forEach(patient => {
                const observeTime = moment(patient.observe_time, "DD/MM/YYYY HH:mm")
                const timeDiff = now.diff(observeTime);

                if (timeDiff > sixHours) {
                    this.showNotification = true
                }
            })
        },
        dismissNotification() {
            this.showNotification = false;
        },
        async fetchAllDataInChunks(collectionName, field, values) {
            const chunkSize = 10 // Firestore 'in' query limit
            const chunks = []

            // Split the values array into chunks of size 10
            for (let i = 0; i < values.length; i += chunkSize) {
                chunks.push(values.slice(i, i + chunkSize))
            }

            const results = []
            for (const chunk of chunks) {
                const snapshot = await db.collection(collectionName)
                    .where(field, "in", chunk)
                    .get()
                results.push(...snapshot.docs.map(doc => doc.data()))
            }

            return results
        },
        async SearchData() {
            const loader = this.$loading.show({})
            this.$bvModal.hide("new-patient-invoice-modal")
            this.patient_summarys = []
            this.temporaryPatientSummarys = []
            try {

                let start = moment(this.dateStart, "DD/MM/YYYY").startOf('day').toDate()
                let end = moment(this.dateEnd, "DD/MM/YYYY").endOf('day').toDate()

                let patientSummaryRef = await db.collection('ObservePatientSummary')
                    .where("time", ">=", start)
                    .where("time", "<", end)
                    .get()
                const HNList = patientSummaryRef.docs.map(visit => visit.data().HN)
                const patientData = await this.fetchAllDataInChunks('Patient', 'HN', HNList)
                const patientMap = {}
                patientData.forEach(patient => {
                    patientMap[patient.HN] = patient
                })
                const insuranceData = await db.collection('Insurance').get()
                const insuranceMap = {}
                insuranceData.forEach(insurance => {
                    insuranceMap[insurance.id] = insurance.data()
                })
                const promises = patientSummaryRef.docs.map(async (doc) => {
                    let patient_summary = doc.data()
                    patient_summary.time = patient_summary.time.toDate()
                    patient_summary.patientAge = patient_summary.patientDateofBirth ? this.calculateAge(patient_summary.patientDateofBirth) : null
                    patient_summary.isTemporary = false
                    patient_summary.patientName = patientMap[patient_summary.HN].first_name + ' ' + patientMap[patient_summary.HN].last_name
                    patient_summary.insurance = patientMap[patient_summary.HN].has_travel_insurance == 'no' ? 'no' : patientMap[patient_summary.HN].has_travel_insurance == 'notsure' ? 'notsure' : patientMap[patient_summary.HN].travel_insurance_other ? patientMap[patient_summary.HN].travel_insurance_other : (patientMap[patient_summary.HN].travel_insurance ? insuranceMap[patientMap[patient_summary.HN].travel_insurance.id].name_en : '')
                    patient_summary.level = patientMap[patient_summary.HN].has_travel_insurance == 'no' ? '' : patientMap[patient_summary.HN].has_travel_insurance == 'notsure' ? '' : patientMap[patient_summary.HN].travel_insurance_other ? '' : (patientMap[patient_summary.HN].travel_insurance ? insuranceMap[patientMap[patient_summary.HN].travel_insurance.id].level : '')
                    patient_summary.treatments.map(record => {
                        if (!record.hasOwnProperty('order')) record.order = []
                        if (!record.hasOwnProperty('practice')) record.practice = []
                        return record
                    })
                    await this.GetPaymentStatus(patient_summary)
                    patient_summary.billLog = [...new Set(patient_summary.payment_status[0].billLog.flat().filter(item => item !== undefined))]
                    return patient_summary

                })
                this.patient_summarys = await Promise.all(promises)

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.checkObserveTime()
                loader.hide()
            }
        },
        getRowClass() {
            return 'row-observation-round'
        },
        async showInvoice(invoiceId) {
            const InvoiceRef = await db.collection('Invoice').doc(invoiceId).get()

            if (InvoiceRef.exists) {
                const InvoiceData = InvoiceRef.data()

                this.invoice = {
                    ...InvoiceData,
                    issue_date: moment(InvoiceData.issue_date.toDate()).format(
                        "DD/MM/YYYY HH:mm"
                    ),
                    visit_date: moment(InvoiceData.visit_date.toDate()).format(
                        "DD/MM/YYYY HH:mm"
                    ),
                    billLog: InvoiceData.billLog ? InvoiceData.billLog : [],
                }
                if (this.invoice.HN) {
                    const patientDoc = await db.collection("Patient").where("HN", "==", this.invoice.HN).get()
                    if (!patientDoc.empty) {
                        const patientData = patientDoc.docs[0].data()
                        this.invoice.patient_name = `${patientData.first_name} ${patientData.last_name}`

                    }
                }
                this.visits = await this.fetchVisits(this.invoice.HN)

                this.$bvModal.show("new-patient-invoice-modal")
            } else {
                console.log("No such document!")
            }

        },
        async fetchVisits(patientId) {
            const visits = (
                await db
                    .collection("Visit")
                    .where("patient", "==", db.collection("Patient").doc(patientId))
                    .orderBy("visit_date", "desc")
                    .get()
            ).docs.map((visit) => visit.data())

            if (!visits.length)
                this.$alert(
                    "No visit information, patient is required to register new visit first before proceed.",
                    null,
                    "error"
                )

            return visits
        },
        showCaseType(caseType) {
            switch (caseType) {
                case 'OPEN':
                    return 'OP'
                case 'OPEN+':
                    return 'OP+'
                case 'SEND':
                    return 'ST'
                case 'SEND+':
                    return 'ST+'
                default:
                    return ''
            }
        },
        async GoToPatient(HN) {
            const routeData = this.$router.resolve(`/patient/${HN}`)
            window.open(routeData.href, '_blank')
        },
        async handleLockChange(value) {

            if (value === true) {
                if (confirm('Are you sure you want to lock the patient summary?')) {
                    await this.savePatientSummary(true)
                }
            }
        },
        async handleUnLockChange(value) {

            if (value === false) {
                if (confirm('Are you sure you want to unlock the patient summary?')) {
                    await this.savePatientSummary(false)
                }
            }
        },
        async savePatientSummary(bool) {
            let loader = this.$loading.show({})
            try {
                let start = moment(this.dateStart, "DD/MM/YYYY").startOf('day').toDate()
                let end = moment(this.dateEnd, "DD/MM/YYYY").endOf('day').toDate()

                let patientSummaryRef = await db.collection('ObservePatientSummary')
                    .where("time", ">=", start)
                    .where("time", "<", end)
                    .get()

                const batch = db.batch()
                patientSummaryRef.docs.forEach(doc => {
                    const docRef = doc.ref
                    const data = doc.data()
                    data.medAudit_lock = bool
                    delete data.payment_status
                    batch.update(docRef, data)
                })
                await batch.commit()
                console.log("Patient summaries updated successfully.")
                await this.SearchData()
            } catch (error) {
                console.error('Error during migration:', error)
            }
            loader.hide()
        },
        async showNewPayment(invoice) {
            const loader = this.$loading.show({})
            this.payment = {
                payment_id: null,
                invoice_id: invoice.invoice_id,
                issue_date: Timestamp.fromDate(new Date()),
                HN: invoice.HN || null,
                VN: invoice.VN || null,
                patient_name: invoice.patient_name || null,
                billed_to: invoice.billed_to || null,
                cash: 0,
                credit: 0,
                insurance: 0,
                other: 0,
                internet_banking: 0,
                concession_type: invoice.concession_type,
                insurance_name: invoice.insurance_name || null,
                insurance_type: invoice.insurance_type || null,
                insurance_name_other: invoice.insurance_name_other || null,
                practitioner: invoice.practitioner,
                type: invoice.type,
                note: invoice.note || null,
                extra_charge: 0.03,
                assist_insurance: invoice.assist_insurance || null,
            }
            this.total_invoiced = invoice.total_invoiced
            this.payment_applied = await getTotalPaymentApplied(invoice)
            this.total_outstanding = await getTotalOutstanding(invoice)
            loader.hide()

            this.$bvModal.show('new-patient-payment-modal')
        },
        async displayPaymentModal(paymentId = null) {
            const loader = this.$loading.show({})
            try {
                const paymentRef = await db.collection('Payment').doc(paymentId).get()
                this.payment = {
                    ...paymentRef.data()
                }
                const invoiceRef = await db.collection('Invoice').doc(this.payment.invoice_id).get()
                const invoice = {
                    ...invoiceRef.data()
                }
                this.total_invoiced = invoice.total_invoiced
                this.payment_applied = await getTotalPaymentApplied(invoice)
                this.total_outstanding = await getTotalOutstanding(invoice)
                loader.hide()
            } catch (error) {
                loader.hide()
                this.$alert('Error: ', error)
                return
            }

            this.$bvModal.show('new-patient-payment-modal')
        },
        changeColorPaymentStatus(temporary, insurance, level) {

            if (temporary == true) {
                return 'temporary-row'
            }
            else if (insurance == 'no' || insurance == 'notsure') {
                return 'row-noInsurance'
            }
            else if (level == 'BEST') {
                return 'best-row'
            }
            else if (level == 'GOOD') {
                return 'good-row'
            }
            else if (level == 'NORMAL') {
                return 'normal-row'
            }
            else if (level == 'GRAYLIST') {
                return 'graylist-row'
            }
            else if (level == 'TEMPORARY BAN') {
                return 'temporaryban-row'
            }
            else if (level == 'BLACKLIST') {
                return 'blacklist-row'
            }
            else {
                return ''
            }



        },


    },

    computed: {
        statusData() {
            return (row) => {
                const statuses = {
                    paid: { icon: 'fa-battery-full', color: 'text-success', title: 'Paid', label: 'PAID', class: 'paid-row' },
                    outstanding: { icon: 'fa-battery-three-quarters', color: 'text-orange', title: 'Outstanding', label: 'OUTSTAND', class: 'outstanding-row' },
                    gop: { icon: 'fa-battery-half', color: 'text-warning', title: 'GOP', label: 'GOP', class: 'gop-row' },
                    unpaid: { icon: 'fa-battery-empty', color: 'text-danger', title: 'Unpaid', label: 'UNPAID', class: 'unpaid-row' },
                    canceled: { icon: 'fa-ban', color: 'text-danger', title: 'Canceled', label: 'CANCEL', class: 'canceled-row' }
                }
                return statuses[row] || null
            }
        },
        filteredItems() {
            let items = _.clone([...this.patient_summarys, ...this.temporaryPatientSummarys])

            let itemGroups = []
            let groupedItems = this._.groupBy(items, item => {
                return 'Observation ' + moment(item.time, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY")
            })
            const groupedByDate = Object.entries(groupedItems)
                .reduce((acc, [key, value]) => {
                    const date = key.match(/\d{2}\/\d{2}\/\d{4}/)?.[0]
                    if (date) {
                        if (!acc[date]) acc[date] = []
                        acc[date].push([key, value])
                    }
                    return acc
                }, {})

            const sortedItems = Object.entries(groupedByDate)
                .sort(([dateA], [dateB]) => new Date(dateA.split('/').reverse().join('-')) - new Date(dateB.split('/').reverse().join('-')))
                .flatMap(([date, entries]) => {
                    return entries.sort(([keyA], [keyB]) => {
                        const priorityA = getPriority(keyA)
                        const priorityB = getPriority(keyB)
                        return priorityA - priorityB
                    })
                })
            const orderedGroupedItems = Object.fromEntries(sortedItems)
            for (let group in orderedGroupedItems) {
                let groupLabel = {
                    mode: "span",
                    label: group,
                    html: false,
                    children: []
                }
                for (let item of groupedItems[group]) {
                    groupLabel.children.push(item)
                }
                itemGroups.push(groupLabel)
            }
            return itemGroups
        }
    }

}
</script>

<style lang="scss">
@keyframes pulse {
    0% {
        background-color: #f8d7da;
    }

    50% {
        background-color: #f5c6cb;
    }

    100% {
        background-color: #f8d7da;
    }
}

.doctor {
    font-weight: bold;
    background-color: #fff;
    color: #274e13ff;
    padding: 3px;
    border: 3px solid #274e13ff;
    border-radius: 0.5em;
}

.insurance {
    font-weight: bold;
    background-color: #fff;
    color: #3c78d8ff;
    padding: 3px;
    border: 3px solid #3c78d8ff;
    border-radius: 0.5em;
}

.nurse,
.paramedic {
    font-weight: bold;
    background-color: #fff;
    color: #1d800eff;
    padding: 3px;
    border: 3px solid #1d800eff;
    border-radius: 0.5em;
}

.publicHealth {
    font-weight: bold;
    background-color: #fff;
    color: #a4c964ff;
    padding: 3px;
    border: 3px solid #a4c964ff;
    border-radius: 0.5em;
}

.diag-class {
    background-color: #fcf5c7;

    margin-right: -10px;
    margin-left: -10px;
}

.clickable {
    cursor: pointer;
    transition: color 0.3s ease;
    /* Optional: smooth color transition */
}

.clickable:hover {
    color: #0056b3;
    /* Adjust hover color */
    text-decoration: underline;
    /* Optional: underline on hover */
}

.observe-patient-summary-table {

    .header-regular-round {
        background-color: #1d800eff;
        color: #fff;
        font-weight: bold;
    }

    .vgt-table.bordered td,
    .vgt-table.bordered th {
        border: 1px solid #acacac;
    }

    .best-row {
        background-color: #007f5f;
        color: #fff;
    }

    .good-row {
        background-color: #80b918;
        color: #fff;
    }

    .normal-row {
        background-color: #ffb100;
        color: #fff;
    }

    .graylist-row {
        background-color: #5f6266;
        color: #fff;
    }

    .temporaryban-row {
        background-color: #990000;
        color: #fff;
    }

    .blacklist-row {
        background-color: #1b1b1e;
        color: #fff;
    }

    .temporary-row {
        //background-color: #FFCDD2;
        animation: pulse 2s infinite;

    }

    .row-observation-round {
        border: 2px solid #F9D83F !important;
        background-color: #fffbea !important;
    }

    .room-tab {
        background-color: #757575 !important;
    }

    .med-tab {
        background-color: #274e13ff !important;
    }

    .note-tab {
        background-color: #FFC300 !important;
    }

    .paymentstatus-row {
        padding: 5px;
        border-radius: 0.5em;
    }

    .resident-row {
        background-color: #A0A0A0;
        color: #fff;
        padding: 5px;
        border-radius: 0.5em;
    }

    .foreigner-row {
        background-color: #A0A0A0;
        color: #fff;
        padding: 5px;
        border-radius: 0.5em;
    }

    .insurance-row {
        background-color: #1565C0;
        color: #fff;
        padding: 5px;
        border-radius: 0.5em;
    }

    .hospital-row {
        background-color: #1976D2;
        color: #fff;
        padding: 5px;
        border-radius: 0.5em;
    }

    .slinky-row {
        background-color: #D32F2F;
        color: #fff;
        padding: 5px;
        border-radius: 0.5em;
    }

    .aesthetic-row {
        background-color: #FBC02D;
        color: #fff;
        padding: 5px;
        border-radius: 0.5em;
    }

    .pier-row {
        background-color: #388E3C;
        color: #fff;
        padding: 5px;
        border-radius: 0.5em;
    }

    .central-row {
        background-color: #7B1FA2;
        color: #fff;
        padding: 5px;
        border-radius: 0.5em;
    }

    .insurance-row {
        background-color: #1565C0;
        color: #fff;
        padding: 5px;
        border-radius: 0.5em;
    }

    .special-insurance-row {
        background-color: #64B5F6;
        color: #fff;
        padding: 5px;
        border-radius: 0.5em;
    }

    .insurance-row-caseType-ST {
        width: 40px;
        text-align: center;
        background-color: #1565C0;
        margin-left: -6px;
        color: #fff;
        padding: 5px;
    }

    .insurance-row-caseType-OP {
        width: 40px;
        text-align: center;
        background-color: #A0A0A0;
        margin-left: -6px;
        color: #fff;
        padding: 5px;
    }

    .paid-row {
        background-color: #28a745;
        width: 50px;
        text-align: center;
        margin-left: -4px;
        color: #fff;
        padding: 5px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .outstanding-row {
        background-color: #ffa500;
        width: 100px;
        text-align: center;
        margin-left: -4px;
        color: #fff;
        padding: 5px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .gop-row {
        background-color: #ffc107;
        width: 45px;
        text-align: center;
        margin-left: -4px;
        color: #fff;
        padding: 5px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .unpaid-row {
        background-color: #dc3545;
        width: 75px;
        text-align: center;
        margin-left: -4px;
        color: #fff;
        padding: 5px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .canceled-row {
        background-color: #6c757d;
        width: 75px;
        text-align: center;
        margin-left: -4px;
        color: #fff;
        padding: 5px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }


}
</style>