<template>
  <b-container v-if="!newsItems || newsItems.length === 0" class="Announcement-day">
  <div class="loader-container">
    <div class="loader-ring"></div>
    <span>loading...</span>
  </div>
  </b-container>
  <b-container v-else class="Announcement-day">
    <h3 style="color: white;" class="text-center">Latest News</h3>

    <!-- แสดงการ์ดจาก newsItems ตามหน้าปัจจุบัน -->
    <div v-for="(card, index) in paginatedItems" :key="index" @click="openModalToday(card)">
      <b-card no-body class="overflow-hidden rounded-0 custom-card">
        <b-row no-gutters>
          <b-col md="5" class="d-flex align-items-center justify-content-center">
             <b-card-img :src="card.Images ? card.Images : require('./images/NoImg.svg')" alt="Image" class="rounded-2 img-fixed-size"></b-card-img>
          </b-col>

          <b-col md="7">
            <b-card-body class="custom-card d-flex flex-column">
              <b-card-title style="font-size: 15px; padding: none !important;">
                {{ card.Title }}
              </b-card-title>
              <b-card-text style="font-size: 15px;">
                {{ card.Subtitle }}
              </b-card-text>
              <small class="custom-footer mt-auto">Last updated {{ card.updatedAt }}</small>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      <hr v-if="index !== paginatedItems.length - 1" :key="'hr-' + index" style="border-color: aliceblue;" />
    </div>

    <!-- pagination-today -->
    <div class="container" v-if="newsItems.length > 5">
      <ul class="pagination-today">
        <li v-if="currentPage > 1">
          <a href="#" @click.prevent="goToPage(currentPage - 1)">Prev</a>
        </li>
        <li v-for="page in totalPages" :key="page">
          <a href="#" :class="{ active: currentPage === page }" @click.prevent="goToPage(page)">
            {{ page }}
          </a>
        </li>
        <li v-if="currentPage < totalPages">
          <a href="#" @click.prevent="goToPage(currentPage + 1)">Next</a>
        </li>
      </ul>
    </div>


    <!-- Modal -->
    <div v-if="showModalToday" class="modal-day" @click.self="closeModalToday">
      <div :class="['modal-content', { 'fullscreen': isFullScreen }]">
        <div class="row" v-if="!isFullScreen">
          <div class="col-11">
            <h1>{{ modalContent.Title }}</h1>
          </div>
          <div class="col-1">
            <button class="btn-icon" @click="closeModalToday">
              <i class="fa fa-times-circle"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12  modal-image-container">
            <img :src="modalContent.Images ? modalContent.Images : require('./images/NoImg.svg')" alt="Image"
            class="modal-image" @click="toggleFullScreen" />
          </div>
        </div>
        <div style="padding: 15px;">
          <div class="row"  v-if="!isFullScreen">
            <div class="col-12">
              <h2>{{ modalContent.Subtitle }}</h2>
            </div>
          </div>
          <div class="row"  v-if="!isFullScreen">
            <div class="col-12">
              <h3 v-html="modalContent.Content"></h3>
            </div>
          </div>
          <div class="row"  v-if="!isFullScreen">
            <div class="col-6" v-if="modalContent.Link">
              <h5>Link:

                <a v-if="modalContent.Link" :href="modalContent.Link" target="_blank" rel="noopener noreferrer">
                  {{ modalContent.Link }}
                </a>
              </h5>
            </div>
            <div class="col-6" v-if="modalContent.File">
              <h5>Documents :
                <a v-if="modalContent.File" :href="modalContent.File" target="_blank">Download</a>
              </h5>
            </div>
          </div>
          <div class="row"  v-if="!isFullScreen">
            <div class="col-12">
              <small class="custom-footer">Last updated: {{ modalContent.updatedAt }}</small>
            </div>
          </div>
          </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  props: {
    newsItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isFullScreen: false, // เช็คว่าอยู่ในโหมดเต็มจอหรือไม่
      showModalToday: false,
      modalContent: {},
      currentPage: 1,
      perPage: 5, // แสดงผล 5 รายการต่อหน้า
    };
  },
  computed: {
    // คำนวณรายการที่จะแสดงในหน้า
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.newsItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.newsItems.length / this.perPage);
    }
  },
  methods: {
    toggleFullScreen() {
            this.isFullScreen = !this.isFullScreen;
        },
    openModalToday(card) {
      this.modalContent = card;
      this.showModalToday = true;
    },
    closeModalToday() {
      this.isFullScreen = false;
      this.showModalToday = false;
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.Announcement-day {


.btn-icon {
  background: none;
  /* เอาสีพื้นหลังออก */
  border: none;
  /* เอาเส้นขอบออก */
  font-size: 32px;
  /* ปรับขนาดไอคอน */
  cursor: pointer;
  padding: 1px;
  /* ปรับพื้นที่รอบๆ */
  margin-left: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.btn-icon i {
  color: #ff0000;
  /* สีของไอคอน */
  font-size: 40px;
  /* ขนาดไอคอนใหญ่ขึ้น */
}

.loader-container {
  left: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #00000025;
  height: 1120px !important;
  border-radius: 5px;
  padding: 5px;
}

.loader-ring {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  animation: loader-ring 2s linear infinite;
}

@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 1px 5px 2px #e65c00;
  }

  50% {
    transform: rotate(180deg);
    box-shadow: 1px 5px 2px #18b201;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: 1px 5px 2px #0456c8;
  }
}

.loader-ring:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255, 255, 255, .3);
}

span {
  color: #737373;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 200px;
  animation: text 3s ease-in-out infinite;
}

@keyframes text {
  50% {
    color: black;
  }
}


  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.0941176471);
  padding: 15px !important;


.custom-card {
  width: 100%;
  height: 190px !important;
  margin-bottom: 20px;
  color: #fff;
  border-radius: 10px;
  background-color: transparent;
  border: none !important;
}

.img-fixed-size {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.custom-footer {
  color: #ff5722;
        font: 1em Arial;
        font-weight: bold;
}

.pagination-today {
  position: relative;
  height: 40px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  border-radius: 10px !important;
}

.pagination-today li {
  list-style-type: none;
  display: inline-block;
}

.pagination-today li a {
  position: relative;
  padding: 10px 17px;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.pagination-today li a:hover,
.pagination-today li.active a {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px !important;
}

/* สไตล์สำหรับ modal */
.modal-day {

    position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(30, 31, 41, 0.829);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        overflow: auto;
  }


  .modal-content {
    background-color: rgb(255, 255, 255);
        width: 100%;
        max-width: 1200px;
        max-height: 90vh;    
        overflow: auto;   
        padding: 20px;
        border-radius: 5px;
  }
  .modal-image {
    width: auto;
        height: 100vh;       
        max-width: 100%;    
        object-fit: contain;       
        cursor: pointer;
    }
  .modal-image-container {
    display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
    }
   /* โหมดเต็มจอ */
   .fullscreen {
        width: 100vw;
        height: 100vh;
        max-width: 100%;
        max-height: 100%;
        background: black;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fullscreen .ModalSlides-image {
        width: 100vw;
        height: 100vh;
        object-fit: contain;
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 30px;
      cursor: pointer;
    }

}
</style>