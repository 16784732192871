<template>
  <div v-if="!newsMinis || newsMinis.length === 0" class="Announcement-mini">
    <div class="loader-container">
      <div class="loader-ring"></div>
      <span>loading...</span>
    </div>
  </div>
  <div v-else class="Announcement-mini">
    <div class="row">
      <div v-for="(item, i) in paginatedItems" :key="i" class="col-12 col-sm-6 col-md-4 col-lg-4">
        <div class="mini-card">
          <img v-if="item.Images" :src="item.Images ? item.Images : require('./images/NoImg.svg')" :alt="item.Title"
            class="mini-card-img " @click="openModal(item)" />
          <img v-else :src="require('./images/NoImg.svg')" :alt="item.Title" class="mini-card-img"
            @click="openModal(item)" />
          <div class="mini-card-body">
            <p class="mini-card-text">{{ item.Title }}</p>
            <hr />
            <small class="mini-footer">Last updated {{ item.updatedAt }}</small>
          </div>
        </div>
      </div>
    </div>

    <!-- pagination-mini -->
    <div v-if="totalPagesmini > 1" style="margin-top: 10px;">
      <ul class="pagination-mini">
        <li v-if="currentPageMini > 1">
          <a href="#" @click.prevent="goToPage(currentPageMini - 1)">Prev</a>
        </li>
        <li v-for="page in totalPagesmini" :key="page">
          <a href="#" :class="{ active: currentPageMini === page }" @click.prevent="goToPage(page)">
            {{ page }}
          </a>
        </li>
        <li v-if="currentPageMini < totalPagesmini">
          <a href="#" @click.prevent="goToPage(currentPageMini + 1)">Next</a>
        </li>
      </ul>
    </div>

    <!-- Modal -->
    <div v-if="showModalMini" class="modal" @click.self="closeModal">
      <div :class="['modal-content', { 'fullscreen': isFullScreen }]">
        <div class="row" style="margin: 10px;" v-if="!isFullScreen">
          <div class="col-11">
            <h1>{{ modalContent.Title }}</h1>
          </div>
          <div class="col-1">
            <button class="btn-icon" @click="closeModal">
              <i class="fa fa-times-circle"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12  modal-image-container">
            <img :src="modalContent.Images ? modalContent.Images : require('./images/NoImg.svg')" alt="Image"
              class="modal-image" @click="toggleFullScreen" />
          </div>
        </div>
        <div style="padding: 15px;">
          <div class="row" v-if="!isFullScreen">
            <div class="col-12">
              <h2>{{ modalContent.Subtitle }}</h2>
            </div>
          </div>
          <div class="row" v-if="!isFullScreen">
            <div class="col-12">
              <h3 v-html="modalContent.Content"></h3>
            </div>
          </div>
          <div class="row" v-if="!isFullScreen">
            <div class="col-6" v-if="modalContent.Link">
              <h5>Link:

                <a v-if="modalContent.Link" :href="modalContent.Link" target="_blank" rel="noopener noreferrer">
                  {{ modalContent.Link }}
                </a>
              </h5>
            </div>
            <div class="col-6" v-if="modalContent.File">
              <h5>Documents :
                <a v-if="modalContent.File" :href="modalContent.File" target="_blank">Download</a>
              </h5>
            </div>
          </div>
          <div class="row " v-if="!isFullScreen">
          <div class="col-12">
            <small class="model-footer">Last updated: {{ modalContent.updatedAt }}</small>
          </div>
        </div>
        </div>
    
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    newsMinis: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isFullScreen: false,
      item: [],
      currentPageMini: 1,
      itemsPerPage: 3,
      showModalMini: false,
      modalContent: {},
      loading: false,
    };
  },
  watch: {

    newsMinis(newNewsMinis) {
      this.item = newNewsMinis;
      this.currentPageMini = 1;
      this.loading = newNewsMinis.length === 0;
    }
  },
  computed: {
    totalPagesmini() {
      return Math.max(1, Math.ceil(this.item.length / this.itemsPerPage));
    },
    paginatedItems() {
      const start = (this.currentPageMini - 1) * this.itemsPerPage;
      return this.item.slice(start, start + this.itemsPerPage);
    }
  },
  methods: {
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
    },
    openModal(item) {
      this.modalContent = item;
      this.showModalMini = true;
    },
    closeModal() {
      this.isFullScreen = false;
      this.showModalMini = false;
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPagesmini) {
        this.currentPageMini = page;
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.Announcement-mini {
  .mini-card {
    display: flex;
    flex-direction: column;
    width: auto;
    min-height: 530px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    transition: 0.3s ease-in-out;
    margin-top: 10px;
  }

  .mini-card:hover {
    transform: translateY(-5px);
  }

  .mini-card-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .mini-card-body {
    padding: 15px;
    flex-grow: 1;
  }

  .mini-card-text {
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    height: 197px;
  }

  .mini-footer {
    color: #ff5722;
    font: 0.9em Arial;
  }

  /////////////////pagination///////////////////////////
  .pagination-mini {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    backdrop-filter: blur(3px);
    border-radius: 10px;
    padding: 20px;
  }

  .pagination-mini li {
    list-style-type: none;
    display: inline-block;
  }

  .pagination-mini li a {
    padding: 10px 25px;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
  }

  .pagination-mini li a:hover,
  .pagination-mini li a.active {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }

  ///////////////////////modal////////////////////////////
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 31, 41, 0.829);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow: auto;
  }

  .modal-header {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // padding: 10px 20px;
    // background-color: #a09e9e;
    color: #1d1c1c;
  }

  .modal-image {
    width: auto;
    height: 100vh;
    max-width: 100%;
    object-fit: contain;
    cursor: pointer;
  }

  .modal-content {
    background-color: rgb(255, 255, 255);
    width: 100%;
    max-width: 1200px;
    max-height: 90vh;
    overflow: auto;
    padding: 20px;
    border-radius: 5px;
  }

  .modal-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }

  .model-footer {
    color: #ff5722;
        font: 1em Arial;
        font-weight: bold;
    // display: flex;
    // justify-content: space-between;
    // font: 1.4em 'Arial';
    // align-items: center;
    // padding: 15px 25px;
    //  background-color: #585454;
    // color: #fafafa;
  }

  .fullscreen {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fullscreen .ModalSlides-image {
    width: 100vw;
    height: 100vh;
    object-fit: contain;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
  }

  .btn-icon {
    background: none;
    border: none;
    font-size: 32px;
    cursor: pointer;
    padding: 1px;
    margin: 10px;
    margin-left: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .btn-icon i {
    color: #cc1414;
    font-size: 40px;
  }

  //////////////////////loader////////////////////////////////
  .loader-container {
    margin-top: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #00000025;
    width: 100%;
    height: 75%;
    min-height: 450px;
    border-radius: 5px;
    padding: 5px;
  }

  .loader-ring {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    animation: loader-ring 2s linear infinite;
  }

  @keyframes loader-ring {
    0% {
      transform: rotate(0deg);
      box-shadow: 1px 5px 2px #e65c00;
    }

    50% {
      transform: rotate(180deg);
      box-shadow: 1px 5px 2px #18b201;
    }

    100% {
      transform: rotate(360deg);
      box-shadow: 1px 5px 2px #0456c8;
    }
  }

  .loader-ring:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(255, 255, 255, .3);
  }

  span {
    color: #737373;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 200px;
    animation: text 3s ease-in-out infinite;
  }

  @keyframes text {
    50% {
      color: black;
    }
  }

  ///////////////////END//////////////////////////
}
</style>
