<template>
    <div class="meeting-report">
        <section class="sheet">
            <div class="row">
                <div class="col-12">
                    <h3 v-if="meetings.length && meetings[0].department" class="text-primary text-center ">
                        {{ meetings[0].department.join(', ') }} Meeting Report
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="text-primary text-center"> on {{ formattedDate }} at {{ formattedTime }}</h3>
                </div>
            </div>
            <div class="row ">
                <table class="table table-bordered mt-3  meeting-table meeting-report">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Status</th>
                            <th>Content</th>
                            <th>Responsible</th>
                            <th>Assistant</th>
                            <th>Deadline</th>
                        </tr>
                    </thead>
                    <tbody class="table-tbody meeting-table" v-if="meetings.length && meetings[0].title_items">
                        <template v-for="(titleItem, titleIndex) in meetings[0].title_items">
                            <tr v-if="(titleItem, titleIndex) in meetings[0].title_items" :key="'title-' + titleIndex">
                                <td :rowspan="titleItem.content_items.length" style="width: 10%;">
                                    <strong>{{ titleItem.title }}</strong>
                                </td>
                                <td :rowspan="titleItem.content_items.length">
                                    <span :class="getStatusClass(titleItem.task_status)">
                                        {{ titleItem.task_status }}
                                    </span>
                                </td>
                                <td style="text-align: left; width: 50%;">{{ titleItem.content_items[0].content }}</td>
                                <td>{{ titleItem.content_items[0].staff_main.join(', ') }}</td>
                                <td>{{ titleItem.content_items[0].staff_assist.length > 0 ?
                                    titleItem.content_items[0].staff_assist.join(', ') : '-' }}</td>
                                <td>{{ titleItem.content_items[0].due_date || '-' }}</td>
                            </tr>
                            <tr v-for="(item, itemIndex) in titleItem.content_items.slice(1)"
                                :key="'item-' + titleIndex + '-' + itemIndex">
                                <td style="text-align: left;">{{ item.content }}</td>
                                <td>{{ item.staff_main.join(', ') }}</td>
                                <td>{{ item.staff_assist.length > 0 ? item.staff_assist.join(', ') : '-' }}</td>
                                <td>{{ item.due_date || '-' }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </section>
    </div>
</template>


<script>
export default {
    data() {
        return {
            meetings: [],
        };
    },
    computed: {
        // จัดรูปแบบวันที่
        formattedDate() {
            if (!this.meetings.length || !this.meetings[0].time) return "-"; // กรณีที่ข้อมูลยังไม่มี
            const date = new Date(this.meetings[0].time);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },
        formattedTime() {
            if (!this.meetings.length || !this.meetings[0].time) return "-"; // ตรวจสอบค่าก่อนใช้
            const date = new Date(this.meetings[0].time);
            let hours = date.getHours();
            let minutes = String(date.getMinutes()).padStart(2, '0');
            const period = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12; // ทำให้เวลาในรูปแบบ 12 ชั่วโมง
            return `${hours}:${minutes} ${period}`;
        }
    },
    mounted() {
        // รับข้อมูลจาก query params
        if (this.$route.query.meetings) {
            const meetingsString = decodeURIComponent(this.$route.query.meetings);
            try {
                this.meetings = JSON.parse(meetingsString);
            } catch (error) {
                console.error("Error parsing meetings data:", error);
            }
        }
    },
    methods: {
        getStatusClass(status) {
            switch (status) {
                case "Complete":
                    return "badge badge-success";
                case "In progress":
                    return "badge badge-warning";
                default:
                    return "badge badge-secondary";
            }
        },

    }
};
</script>

<style lang="scss">
/* ========== สไตล์หลัก ========== */
.meeting-report {
    .sheet {
        width: 300mm !important;
        min-height: 297mm;
        padding: 20mm;
        margin: 5mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        margin-top: -40px !important;
    }


    /* ตาราง */
    table {
        width: 100%;
        border-collapse: collapse;
        /* ให้เส้นตารางติดกัน */
        table-layout: fixed;
        /* ให้ขนาดคอลัมน์คงที่ */
    }

    /* ส่วนหัวของตาราง */
    thead {
        border: 1px solid #797777;
        display: table-header-group;
        /* ให้หัวตารางซ้ำเมื่อขึ้นหน้า */
    }

    thead th {
        background-color: #2F6EBA !important;
        color: white !important;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        padding: 8px;

    }

    /* เนื้อหาตาราง */
    .table-tbody {
        font-size: 14px;
        color: #333 !important;
        text-align: left;

    }

    /* ช่องของตาราง */
    td,
    th {
        padding: 8px;
        text-align: center;
        border: 1px solid #ddd !important;
        word-wrap: break-word;
    }

    /* กำหนดขนาดเฉพาะของแต่ละคอลัมน์ */
    th:nth-child(1),
    td:nth-child(1) {
        width: 15%;
    }

    /* Title */
    th:nth-child(2),
    td:nth-child(2) {
        width: 10%;
    }

    /* Status */
    th:nth-child(3),
    td:nth-child(3) {
        width: 35%;
    }

    /* Content */
    th:nth-child(4),
    td:nth-child(4) {
        width: 15%;
    }

    /* Responsible */
    th:nth-child(5),
    td:nth-child(5) {
        width: 15%;
    }

    /* Assistant */
    th:nth-child(6),
    td:nth-child(6) {
        width: 10%;
    }

    /* Deadline */

    /* ป้ายสถานะ */
    .badge {
        padding: 5px 10px !important;
        font-size: 0.9em !important;
        border-radius: 5px !important;

        &-success {
            background-color: #28a745 !important;
            color: white !important;
        }

        &-warning {
            background-color: #ffc107 !important;
            color: black !important;
        }
    }


    /* ====== ปรับการพิมพ์ ====== */
    @media print {

        @page {
            size: landscape !important;
            margin: 0;
        }

        .sheet {
            margin-top: -40px !important;
            font-size: 10px !important;
            width: 300mm !important;
            height: auto !important;
            min-height: 279 !important;
            background: white !important;
        }

        .meeting-table {
            width: 95% !important;

            thead {
                display: table-header-group !important;
            }

            th,
            td {
                padding: 6px !important;
                page-break-inside: avoid !important;
            }

            tr {
                page-break-inside: avoid !important;
                page-break-after: auto !important;
            }
        }

        /* ซ่อนองค์ประกอบที่ไม่ต้องการพิมพ์ */
        .no-print {
            display: none !important;
        }

    }
}
</style>
