<template>
    <div class="container-fluid z-2">
        <b-modal id="deposit-modal" hide-footer size="xl" no-close-on-backdrop>
            <template #modal-title>
                <div class="d-flex">

                    <div class="">
                        เติมเงินเข้าระบบ PettyCash
                    </div>

                </div>
            </template>

            <template>
                <div class="row bg-blue">
                    <div class="row my-3 w-100">
                        <div class="col-3">
                            <MazPicker v-model="sender.time" :format="`DD/MM/YYYY HH:mm`"
                                :formatted="`DD/MM/YYYY HH:mm`" placeholder="Time" />
                        </div>

                        <div class="col-3">
                            <MazSelect :placeholder="'Branch'" v-model="sender.branch" :options="branchOptions"
                                search />
                        </div>
                        <div class="col-3">
                            <MazSelect :placeholder="'Staff'" v-model="sender.staff" :options="publicHealth" multiple
                                search />
                        </div>
                    </div>

                </div>

                <div class="row bg-green">
                    <div class="row w-100 my-3">

                        <div class="col-4">
                            <MazSelect :placeholder="'Details'" v-model="sender.detail" :options="detailOptions"
                                search />
                        </div>
                        <div class="col-3">
                            <MazSelect :placeholder="'Payment Method'" v-model="sender.paymentMethod"
                                :options="paymentMethodOptions" search />
                        </div>
                        <div class="col-3">
                            <MazInput type="number" v-model="sender.deposit" placeholder="Deposit" no-buttons />
                        </div>
                        <div class="col-2 d-flex justify-content-end">
                            <div>
                                <button class="btn btn-primary" @click="saveSender()">Save</button>
                            </div>

                        </div>
                    </div>
                </div>
                {{ sender }}
            </template>

        </b-modal>
    </div>
</template>

<script>

import { db } from "../../db";
import moment from "moment";
export default {
    props: ["publicHealth", "branch"],
    firestore() {
        return {
            locations: db.collection("Sender"),
            details: db.collection("senderDetails"),
        }
    },
    data() {
        return {
            sender: { staff: [] },
            locations: [],
            details: [],
        }
    },
    mounted() {
        this.initDataDeposit()
    },
    watch: {
        branch: {
            deep: true,
            handler() {
                this.initDataDeposit()
            }
        }
    },
    methods: {
        initDataDeposit() {
            let sender = {}
            sender.staff = []
            sender.branch = this.branch
            sender.detail = "เติมเงินเข้าระบบ petty cash"
            sender.time = moment().format("DD/MM/YYYY HH:mm")
            sender.deposit = 0
            sender.paymentMethod = "CASH"
            this.sender = sender
        },

        async saveSender() {
            let loader = this.$loading.show({})

            try {
                if (this.sender.staff && this.sender.staff.length != 1) {
                    this.$alert('enter staff')
                    loader.hide()
                    return
                }
                this.sender.time = moment(this.sender.time, "DD/MM/YYYY HH:mm").toDate()
                const sender = { ...this.sender }                
                let docRef = await db.collection("SenderLog").add(sender)
                await db.collection("SenderLog").doc(docRef.id).update({ id: docRef.id })
            } catch (error) {
                this.$alert(`error ${error}`, null, "error")
                loader.hide()
            }
            loader.hide()
            this.$alert("Save Sender Success.", null, "success")
            this.$bvModal.hide("deposit-modal")

        },
        replaceUndefinedWithNull(obj) {
            return Object.fromEntries(
                Object.entries(obj)
                    .map(([key, value]) => [key, value === undefined ? null : value])
            )
        },

    },
    computed: {
        prepared_senders() {
            const blankOptions = [{ label: "", value: null }]
            const otherOptions = [{ label: "Other", value: "Other" }]
            return [
                ...blankOptions,
                ...this.locations.map((i) => ({
                    label: i.label,
                    value: i.value,
                })),
                ...otherOptions
            ]
        },
        branchOptions() {
            return this.$store.state.branches.map(dd => ({
                label: dd.name_en,
                value: dd.name_en
            }))
        },
        detailOptions() {
            return [
                ...this.details
            ]
        },
        paymentMethodOptions() {
            return [
                { label: "CASH", value: "CASH" },
                { label: "SCB", value: "SCB" },
                { label: "KTB", value: "KTB" },
                { label: "KRUNGSRI", value: "KRUNGSRI" },
                { label: "KBANK", value: "KBANK" },
                { label: "BBL", value: "BBL" },
            ]
        }
    }



}
</script>

<style lang="scss"></style>