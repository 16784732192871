<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="row">
            <div class="col-sm-4">
              <div class="box-bookMark-Patient">
              <h3 class="box-bookMark-Patient text-center">Patient</h3>
            <!-- <h3 class="text-center" :class="$store.state.branch == 3 ? 'text-wa' : 'text-primary'">Patient Summary</h3>-->
            </div>
          </div>
          <div class="col-sm-8 d-flex align-items-center " >
              <div class="span3 text-center mx-2">
                <MazSelect v-model="search_date_type" style="z-index: 1" placeholder="select date filter type"
                  @change="handleFilterDateSearchType" :options="[
                    { label: 'Search by day', value: 'by-day' },
                    { label: 'Search by range', value: 'by-range' },
                  ]" />
              </div>
              <div v-if="search_date_type === 'by-day'" class="span3 text-center d-flex mx-3">
                <MazPicker v-model="search_date_value" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY"
                  placeholder="Period Date" style="z-index: 1 !important;" @input="onDateChange" />
              </div>
              <div v-if="search_date_type === 'by-range'" class="span3 text-center">
                <MazPicker v-model="filterDate" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY"
                  placeholder="Period Date Range" style="z-index: 1 !important" range @input="onDateChange" />
              </div>
            <button class="btn btn-primary ml-3" @click.prevent="triggerInitData" v-if="currentMenu === 'OPD'">
              <h5><i class="fa fa-plus" aria-hidden="true"></i> Add</h5>
            </button>
          </div>
        </div>
        <!--<div class="d-flex align-items-center">
          <div class="box-bookMark-Patient">
            <h3 class="box-bookMark-Patient text-center">Patient</h3>
           <h3 class="text-center" :class="$store.state.branch == 3 ? 'text-wa' : 'text-primary'">Patient Summary</h3>
          </div>
       
          <div class="d-flex">
            <div class="span3 text-center mx-2">
              <MazSelect v-model="search_date_type" style="z-index: 1" placeholder="select date filter type"
                @change="handleFilterDateSearchType" :options="[
                  { label: 'Search by day', value: 'by-day' },
                  { label: 'Search by range', value: 'by-range' },
                ]" />
            </div>

            <div v-if="search_date_type === 'by-day'" class="span3 text-center d-flex mx-3">
              <MazPicker v-model="search_date_value" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY"
                placeholder="Period Date" style="z-index: 1 !important;" @input="onDateChange" />
            </div>
            <div v-if="search_date_type === 'by-range'" class="span3 text-center">
              <MazPicker v-model="filterDate" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY"
                placeholder="Period Date Range" style="z-index: 1 !important" range @input="onDateChange" />
            </div>

            <button class="btn btn-primary" @click.prevent="triggerSearchData">
              <h5>Search</h5>
            </button> 
            <button class="btn btn-primary ml-3" @click.prevent="triggerInitData" v-if="currentMenu === 'OPD'">
              <h5><i class="fa fa-plus" aria-hidden="true"></i> Add</h5>
            </button>
          </div>
        </div>-->

        <div class="mt-2">
          <div class="row">

            <div class="col-4">
            </div>
            <div class="col-4" style="z-index: 0;" >
              <form @submit.prevent="handleKeywordSearch">
                <div class="input-group mt-2">
                  <input type="text" class="form-control" placeholder="Search patient by name, passport id, hn"
                    v-model="keyword" :readonly="isLoading" />
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary" aria-expanded="false">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-4">
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-2" v-for="(patient, index) in patientFromSearch" :key="'patient-' + index">
                  <button class="btn btn-outline-primary" @click="GoToPatient(patient)">
                    {{ patient.first_name + " " + patient.last_name + " HN: " + patient.HN }}
                  </button>

                </div>
              </div>

            </div>
          </div>

          <b-nav class="mb-2" tabs>
            <b-nav-item :active="currentMenu === menu.name" v-for="(menu, index) in menus" :key="index"
              @click="currentMenu = menu.name">{{ menu.name }}</b-nav-item>
          </b-nav>
          <div v-if="currentMenu === 'OPD'">
            <opd-tab ref="opdTab" :dateStart="search_date_type === 'by-day' ? search_date_value : filterDate.start"
              :dateEnd="search_date_type === 'by-day' ? search_date_value : filterDate.end" :branch="branch" />
          </div>
          <div v-if="currentMenu === 'OBSERVATION'">
            <observe-tab ref="observeTab" :dateStart="search_date_type === 'by-day' ? search_date_value : filterDate.start"
              :dateEnd="search_date_type === 'by-day' ? search_date_value : filterDate.end" :branch="branch" />
          </div>
          <div v-if="currentMenu === 'IPD'">
            <ipd-tab ref="ipdTab" :dateStart="search_date_type === 'by-day' ? search_date_value : filterDate.start"
              :dateEnd="search_date_type === 'by-day' ? search_date_value : filterDate.end" :branch="branch" />
          </div>
          <div v-if="currentMenu === 'REFERRAL'">
            <referral-tab ref="referralTab"
              :dateStart="search_date_type === 'by-day' ? search_date_value : filterDate.start"
              :dateEnd="search_date_type === 'by-day' ? search_date_value : filterDate.end" :branch="branch" />
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment"
import opd from './opd.vue'
import observe from './observe.vue'
import ipd from './ipd.vue'
import referral from './referral.vue'

import { searchPatient } from '../../meili'
export default {
  components: {
    "opd-tab": opd,
    "observe-tab": observe,
    "ipd-tab": ipd,
    "referral-tab": referral,
  },
  data() {
    return {
      currentMenu: "OPD",
      search_date_type: 'by-day',
      search_data_time_value: moment(new Date(), 'DD/MM/YYYY T h:mm:ss').toDate(),
      search_date_value: moment(new Date(), 'DD/MM/YYYY').toDate(),
      filterDate: {
        start: moment(new Date(), "DD/MM/YYYY").startOf('day').toDate(),
        end: moment(new Date(), "DD/MM/YYYY").endOf('day').toDate()
      },
      branch: this.$store.state.branch
        ? this.$store.state.branch
        : "all",
      keyword: "",
      isLoading: false,

      patientFromSearch: [],
      patient: {},
      visit_date: null,
      selectedBranch: null,

    }
  },
  methods: {
    async GoToPatient(patient) {
      let HN = patient.HN
      const routeData = this.$router.resolve(`/patient/${HN}/visit`)
      window.open(routeData.href, '_blank')

    },
    onDateChange(newDate) {
      this.$nextTick(() => {
        this.triggerSearchData()
      })
    },
    handleFilterDateSearchType(type) {
      this.search_date_type = type
      this.triggerSearchData()
    },
    triggerInitData() {
      if (this.currentMenu === "OPD") {
        this.$refs.opdTab.$refs.PatientSummaryTable.initData()
      } else if (this.currentMenu === "IPD") {
        this.$refs.ipdTab.$refs.PatientSummaryTable.initData()
      } else if (this.currentMenu === "REFERRAL") {
        this.$refs.referralTab.$refs.PatientSummaryTable.initData()
      } else if (this.currentMenu === "OBSERVATION") {
        this.$refs.observeTab.$refs.PatientSummaryTable.initData()
      }
      
    },
    triggerSearchData() {
      if (this.currentMenu === "OPD") {
        this.$refs.opdTab.$refs.PatientSummaryTable.SearchData()
      } else if (this.currentMenu === "IPD") {
        this.$refs.ipdTab.$refs.PatientSummaryTable.SearchData()
      } else if (this.currentMenu === "REFERRAL") {
        this.$refs.referralTab.$refs.PatientSummaryTable.SearchData()
      } else if (this.currentMenu === "OBSERVATION") {
        this.$refs.observeTab.$refs.PatientSummaryTable.SearchData()
      }
    },

    async handleKeywordSearch() {
      if (this.keyword.length < 3) {
        this.$alert('Minimum keyword length: 3')
        return
      }

      let loader = this.$loading.show({})
      this.$emit('onLoading', true)
      let result = await searchPatient(this.keyword)

      let filter_patient = null
      if (this.$store.state.branch != 3) {
        filter_patient = result.hits.filter(doc => {
          return doc.HN.slice(0, 2) !== "WA"
        })
      }
      else {
        filter_patient = result.hits.filter(doc => {
          return doc.HN.slice(0, 2) === "WA"
        })
      }
      this.patientFromSearch = filter_patient

      loader.hide()
    },

  },
  watch: {
    'keyword': function (newItemName) {
      if (newItemName === "") {
        this.patientFromSearch = []
      }
    },
  },

  computed: {
    menus() {
      const menus = [
        { name: "OPD" },
        { name: "OBSERVATION" },
        { name: "IPD" },
        { name: "REFERRAL" },
      ]
      return menus
    },
  }
};
</script>
<style lang="css">
.box-bookMark-Patient {
  width: 200px;
  height: 100px;
  background-color: #2F6EBA;
  margin-top: -11px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-bookMark-Patient h3{
  color: #ffffff;
}
</style>