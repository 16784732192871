<template>
    <div>
        <div class="row">
            <div class="col-12">
                <b-card class="mb-3 head">
                    <div class="row">
                        <div class="col-3">
                            <label for="group">Group</label>
                                <multiselect       
                                    v-model="SearchData.Group" 
                                    placeholder="Group" 
                                    :options="GroupOptions" 
                                    multiple 
                                    search 
                                    track-by="value" 
                                    label="label" 
                                    :allow-empty="true" 
                                    :close-on-select="false"
                                     id="group"
                                    class="w-100  mb-3"
                            />
                        </div>
                        <div class="col-3">
                            <label for="department">Department</label>
                            <multiselect v-model="SearchData.Department" placeholder="department" :options="devOptions"
                                multiple track-by="value" label="label" :allow-empty="true" :close-on-select="false"
                                class="w-100" id="department" />
                        </div>
                        <div class=" justify-content-center" style="margin-top: 34px;">
                            <button class="btn btn-success " @click="handleSearch" style="margin-right: 10px;">
                            Search
                            </button>
                            <button class="btn btn-warning" @click="handleclear">
                            Clear
                            </button>
                        </div>
                    </div>

                </b-card>
            </div>
        </div>
        <div class="row">
            <!-- Left Section -->
            <div class="col-lg-9 col-md-12">
                <div class="row">
                    <div class="col-12">
                        <!-- Carousel with dynamic height -->
                        <Carousel :newsSlides="Slides" style="height: 650px;" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <Cardmini :newsMinis="Minis" style="height: 650px; " />
                    </div>
                </div>
            </div>

            <!-- Right Section -->
            <div class="col-lg-3 col-md-12" style="min-height: 798px;">
                <Cardtoday :newsItems="Todays" />
            </div>
        </div>

    </div>
</template>

<script>
import { db } from '../../db'
import moment from 'moment'

import Carousel from "./Announcement/card-carousel.vue"
import Cardmini from './Announcement/card-mini.vue'
import Cardtoday from "./Announcement/card-today.vue"

export default {
    components: {
        Carousel,
        Cardmini,
        Cardtoday
    },
    data() {
        return {
            News: [],
            Slides: [],
            Todays: [],
            Minis: [],
            searchModal: false,
            SearchData: {
                Department: [],
                Group: [],
            },
            GroupOptions: [
                { value: "Company", label: "Company" },
                { value: "Human Resources", label: "Human Resources" },
                { value: "Financial", label: "Financial" },
                { value: "Meeting", label: "Meeting" },
                { value: "Employee Activities", label: "Employee Activities" },
                { value: "Project or New Initiative", label: "Project or New Initiative" },
                { value: "Internal Communication", label: "Internal Communication" },
                { value: "Safety", label: "Safety" },
                { value: "Training and Development", label: "Training and Development" },
                { value: "Health and Welfare", label: "Health and Welfare" },
            ],
            devOptions: [
                { value: "Management", label: "Management" },
                { value: "Account", label: "Account" },
                { value: "Development", label: "Development" },
                { value: "AR", label: "AR" },
                { value: "Insurance", label: "Insurance" },
                { value: "Doctor", label: "Doctor" },
                { value: "Nurse", label: "Nurse" },
                { value: "Public Health", label: "Public Health" },
                { value: "Med Tech", label: "Med Tech" },
                { value: "Wa", label: "Wa" },
                { value: "Driver", label: "Driver" },
                { value: "Housekeeper", label: "Housekeeper" },
                { value: "All", label: "All" },
            ],
            // selectedDepartment: 'All'

        };
    },
    computed: {
        selectedDepartment() {
            // ตรวจสอบค่าที่เลือกใน SearchData.Group และ SearchData.Department
            if (this.SearchData.Group.length === 0 && this.SearchData.Department.length === 0) {
                return 'All';  // ถ้าไม่มีการเลือก ค่า selectedDepartment จะเป็น 'All'
            }
            return 'Select';  // ถ้ามีการเลือก จะเป็น 'Select'
        }
    },
    watch: {

    },
    mounted() {
        this.fetchNews(),
        this.fetchSlides()
        this.fetchTodays()
        this.fetchmini();
    },
    methods: {
        fetchNews() {
            db.collection("Ft_announcement")
                .orderBy('createdAt', 'desc')
                .get()
                .then((querySnapshot) => {
                    this.News = [];
                    querySnapshot.forEach((doc) => {
                        let newsData = doc.data();

                        // แปลง createdAt เป็น "YYYY-MM-DD HH:mm"
                        if (newsData.createdAt && typeof newsData.createdAt.toDate === "function") {
                            newsData.createdAt = moment(newsData.createdAt.toDate()).format("YYYY-MM-DD HH:mm");
                        }

                        // แปลง Showing เป็น "YYYY-MM-DD"
                        if (newsData.Showing && typeof newsData.Showing.toDate === "function") {
                            newsData.Showing = moment(newsData.Showing.toDate()).format("YYYY-MM-DD");
                            //console.log(newsData.Showing); // ตรวจสอบค่าที่แปลง
                        }
                        // แปลง updatedAt เป็น "YYYY-MM-DD HH:mm"
                        if (newsData.updatedAt && typeof newsData.updatedAt.toDate === "function") {
                            newsData.updatedAt = moment(newsData.updatedAt.toDate()).format("YYYY-MM-DD HH:mm");
                        }
                        // เก็บข้อมูลที่แปลงแล้วใน this.News
                        this.News.push({
                            ...newsData
                        });
                    });


                    this.fetchSlides();
                    this.fetchTodays();
                    this.fetchmini();
                })
                .catch(error => {
                    console.log("Error getting documents: ", error);
                });
        },
        processNews(category, selectedItemsCount) {
            let normalItems = [];
            let finalItems = [];

            let mainItems = this.News.filter(item => item.Category === category);

            // ขั้นตอนที่ 2: ตรวจสอบว่า Showing Date <= วันที่ปัจจุบัน
            for (let index = 0; index < mainItems.length; index++) {
                const showingDate = new Date(mainItems[index].Showing);
                const currentDate = new Date(); // Current date

                if (showingDate <= currentDate) {
                    const formattedDate = showingDate.toISOString().split('T')[0]; // แปลงวันที่เป็น "YYYY-MM-DD"
                    mainItems[index].formattedDate = formattedDate;
                    normalItems.push(mainItems[index]); // เก็บไว้ใน normalItems
                }
            }

            // ขั้นตอนที่ 3: เลือกข่าวที่มี Hold ไปเก็บไว้ใน Slides
            let holdItems = normalItems.filter(item => item.Hold === true);
            finalItems = [...holdItems]; // เก็บ Hold ไว้ใน Slides

            // ขั้นตอนที่ 4: เลือก 5 ชุดจากที่เหลือ โดยจัดลำดับตาม Level (High -> Low) และเลือกจากเวลาล่าสุด
            let levelItems = normalItems.filter(item => item.Hold !== true); // ลบออกจาก Hold

            // เรียงจาก Level High -> Low
            levelItems.sort((a, b) => {
                // เรียงตาม Level: High มาก่อน
                if (a.Level === "High" && b.Level !== "High") return -1;
                if (a.Level !== "High" && b.Level === "High") return 1;

                // ถ้า Level เท่ากัน ให้เรียงตามเวลาล่าสุด
                return new Date(b.createdAt) - new Date(a.createdAt); // ใช้เวลาล่าสุดในการเรียง
            });

            // เลือก 5 ชุดจากที่เหลือ
            let topLevelItems = [];

            // ตัวอย่าง: เลือก 5 รายการถ้าไม่มีเงื่อนไขพิเศษ
            if (selectedItemsCount) {
                topLevelItems = levelItems.slice(0, selectedItemsCount);  // ใช้ค่า count ที่เลือกจาก UI หรือเงื่อนไขอื่น ๆ
            } else {
                // หรือเลือกทุกชุดที่เหลือทั้งหมด
                topLevelItems = levelItems;  // เลือกทุกชุด
            }

            // รวม normalItems, holdItems, และ topLevelItems
            return [...finalItems, ...topLevelItems]; // คืนค่าผลลัพธ์ที่รวมทั้งหมด
        },
        setectNews(category, selectedItemsCount) {
            let normalItems = [];
            let finalItems = [];

            // ขั้นตอนที่ 1: ตรวจสอบว่า Category
            let mainItems = this.News.filter(item => item.Category === category);
            //  console.log(mainItems);
            // ขั้นตอนที่ 2: ตรวจสอบว่า Showing Date <= วันที่ปัจจุบัน
            for (let index = 0; index < mainItems.length; index++) {
                const showingDate = new Date(mainItems[index].Showing);
                const currentDate = new Date(); // Current date

                if (showingDate <= currentDate) {
                    const formattedDate = showingDate.toISOString().split('T')[0]; // แปลงวันที่เป็น "YYYY-MM-DD"
                    mainItems[index].formattedDate = formattedDate;
                    normalItems.push(mainItems[index]); // เก็บไว้ใน normalItems
                }
            }
            // console.log(`normalItems`, normalItems);
            let DateItems = normalItems.filter(item => {
                // ตรวจสอบว่า Department ตรงกับค่าที่เลือกใน SearchData หรือไม่
                const departmentMatch = this.SearchData.Department.length > 0
                    ? this.SearchData.Department.some(dep =>
                        item.Department.some(dept => dept.value === dep.value)  // ตรวจสอบว่า department value ตรงกัน
                    )
                    : true;

                // ตรวจสอบว่า Group ตรงกับค่าที่เลือกใน SearchData หรือไม่
                const groupMatch = this.SearchData.Group.length > 0
                    ? this.SearchData.Group.some(group => item.Group === group.value)  // ตรวจสอบว่า Group value ตรงกัน
                    : true;

                // ตรวจสอบทั้ง Department และ Group ว่าตรงกันทั้งหมด
                return departmentMatch && groupMatch;
            });


            console.log(`DateItems`, DateItems);
            // ขั้นตอนที่ 4: เลือกข่าวที่มี Hold ไปเก็บไว้ใน Slides
            let holdItems = DateItems.filter(item => item.Hold === true);
            finalItems = [...holdItems]; // เก็บ Hold ไว้ใน Slides

            // ขั้นตอนที่ 5: เลือก 5 ชุดจากที่เหลือ โดยจัดลำดับตาม Level (High -> Low) และเลือกจากเวลาล่าสุด
            let levelItems = DateItems.filter(item => item.Hold !== true); // ลบออกจาก Hold

            // เรียงจาก Level High -> Low
            levelItems.sort((a, b) => {
                // เรียงตาม Level: High มาก่อน
                if (a.Level === "High" && b.Level !== "High") return -1;
                if (a.Level !== "High" && b.Level === "High") return 1;

                // ถ้า Level เท่ากัน ให้เรียงตามเวลาล่าสุด
                return new Date(b.createdAt) - new Date(a.createdAt); // ใช้เวลาล่าสุดในการเรียง
            });
            console.log(`levelItems`, levelItems);
            // ตัวเลือกที่ยืดหยุ่น: คุณสามารถเลือกจำนวนที่ต้องการ
            let topLevelItems = [];

            // ตัวอย่าง: เลือก 5 รายการถ้าไม่มีเงื่อนไขพิเศษ
            if (selectedItemsCount) {
                topLevelItems = levelItems.slice(0, selectedItemsCount);  // ใช้ค่า count ที่เลือกจาก UI หรือเงื่อนไขอื่น ๆ
            } else {
                // หรือเลือกทุกชุดที่เหลือทั้งหมด
                topLevelItems = levelItems;  // เลือกทุกชุด
            }
            // รวม normalItems, holdItems, และ topLevelItems
            return [...finalItems, ...topLevelItems]; // คืนค่าผลลัพธ์ที่รวมทั้งหมด
        },

        fetchSlides() {
            if (this.selectedDepartment === "All") {
                this.Slides = [];
                this.Slides = this.processNews("Main", 5);
            }
            else if (this.selectedDepartment === "Select") {
                this.Slides = [];
                this.Slides = this.setectNews("Main", 5)
            }
        },
        fetchTodays() {
            if (this.selectedDepartment === "All") {
                this.Todays = [];
                this.Todays = this.processNews("MainSub");
            }
            else if (this.selectedDepartment === "Select") {
                // console.log(`MainSub Select`);
                this.Todays = [];
                this.Todays = this.setectNews("MainSub")
            }
        },
        fetchmini() {
            if (this.selectedDepartment === "All") {
                this.Minis = [];
                this.Minis = this.processNews("Article");
            }
            else if (this.selectedDepartment === "Select") {
                // console.log(`fetchmini Select`);
                this.Minis = [];
                this.Minis = this.setectNews("Article")
            }
        },
        handleSearch() {
            this.fetchNews();
        },
        handleclear(){
            // รีเซ็ตค่า SearchData ให้เป็นค่าเริ่มต้น
            this.SearchData.Department = [];
            this.SearchData.Group = [];
            this.fetchNews();
            //console.log('Data has been cleared!');
        }
    },
};

</script>

<style lang="scss" scoped>
.head {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
}
</style>