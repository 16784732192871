<template>
  <div class="container-fluid mt-2">
    <div class="card shadow">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="box-bookMark-Insurance d-flex align-items-center justify-content-center">
            <h3 class="box-bookMark-Insurance">Insurance</h3>
          </div>
        </div>
      <!--  <div class="d-flex mt-2 mb-2">
            <div class="mr-auto">
              <h4 class="text-primary">Insurance</h4>
            </div>            
          </div>-->
           
        <date-filter v-model="filter" v-if="currentMenu === 'Invoice' || currentMenu === 'Payment'"></date-filter>
      
        <b-nav class="row mt-3" tabs v-if="!$route.meta.print">
          <b-nav-item :active="(currentMenu === menu.name)" v-for="menu in menus" :key="menu.name"
            @click="(currentMenu = menu.name)" class="mt-2">{{ menu.name }}</b-nav-item>
        </b-nav>
        <insurance v-if="currentMenu === 'Insurance'"/>
        <assist-insurance v-if="currentMenu === 'Assist Insurance'"/>
        <invoice-summary :start="filter.from" :end="filter.to" v-if="currentMenu === 'Invoice'"
          @displaySummaryModal="$bvModal.show('invoice-payment-summary-modal')"
          @displayNewPaymentModal="displayNewPaymentModal" @displayPaymentModal="displayPaymentModal" />

        <payment-summary :start="filter.from" :end="filter.to" v-if="currentMenu === 'Payment'"
          @displaySummaryModal="$bvModal.show('invoice-payment-summary-modal')"
          @displayPaymentModal="displayPaymentModal" />
      </div>
    </div>


    <!-- <invoice-payment-summary-modal :menu="currentMenu" /> -->

    <patient-payment-modal :payment="payment" :total_invoiced="total_invoiced" :payment_applied="payment_applied"
      :total_outstanding="total_outstanding" @displayPaymentModal="displayPaymentModal" />
  </div>
</template>

<script>
import { db, Timestamp } from '../../db'
import { getTotalPaymentApplied, getTotalOutstanding } from '../../helper/payment'

import Insurance from './Insurance.vue'
import AssistInsurance from './AssistInsurance.vue'
import Invoice from './Invoice.vue'
import Payment from './Payment.vue'

export default {
  components: {
    'insurance': Insurance,
    'assist-insurance': AssistInsurance,
    'invoice-summary': Invoice,
    'payment-summary': Payment,
  },

  data() {
    return {
      currentMenu: 'Invoice',
      menus: [
        { name: 'Insurance'},
        { name: 'Assist Insurance'},
        { name: 'Invoice' },
        { name: 'Payment' },
      ],
      payment: {
        payment_id: null,
        issue_date: this.$moment(new Date(), 'HH:mm DD/MM/YYYY').toDate(),
        HN: null,
        VN: null,
        insurance_name: null,
        insurance_name_other: null,
        patient_name: null,
        cash: 0,
        credit: 0,
        credit_bank_name: null,
        insurance: 0,
        other: 0,
        internet_banking: 0,
      },
      total_invoiced: 0,
      payment_applied: 0,
      total_outstanding: 0,
      filter: {
        from: this.$moment().format('DD/MM/YYYY'),
        to: this.$moment().format('DD/MM/YYYY'),
        period: {
          name: 'Day', from: this.$moment().add(-1, 'day').format('DD/MM/YYYY'), to: this.$moment().format('DD/MM/YYYY'),
          income: 'Daily',
          total: 'TODAY\'S'
        },
      }
    }
  },
  mounted() {
    // console.log('this filter ====>', this.filter)
  },
  methods: {
    async displayNewPaymentModal(invoice) {
      const loader = this.$loading.show({})
      this.payment = {
        payment_id: null,
        invoice_id: invoice.invoice_id,
        issue_date: this.$moment(new Date()).toDate(),
        HN: invoice.HN || null,
        VN: invoice.VN || null,
        patient_name: invoice.patient_name || null,
        billed_to: invoice.billed_to || null,
        cash: 0,
        credit: 0,
        insurance: 0,
        other: 0,
        internet_banking: 0,
        concession_type: invoice.concession_type,
        insurance_name: invoice.insurance_name || null,
        insurance_type: invoice.insurance_type || null,
        insurance_name_other: invoice.insurance_name_other || null,
        practitioner: invoice.practitioner,
        type: invoice.type,
        note: invoice.note || null,
        extra_charge: 0.03,
        assist_insurance: invoice.assist_insurance || null,
      }
      this.total_invoiced = invoice.total_invoiced
      this.payment_applied = await getTotalPaymentApplied(invoice)
      this.total_outstanding = await getTotalOutstanding(invoice)
      loader.hide()

      this.$bvModal.show('new-patient-payment-modal')
    },
    async displayPaymentModal(paymentId = null) {
      const loader = this.$loading.show({})
      try {
        const paymentRef = await db.collection('Payment').doc(paymentId).get()
        this.payment = {
          ...paymentRef.data()
        }
        const invoiceRef = await db.collection('Invoice').doc(this.payment.invoice_id).get()
        const invoice = {
          ...invoiceRef.data()
        }
        this.total_invoiced = invoice.total_invoiced
        this.payment_applied = await getTotalPaymentApplied(invoice)
        this.total_outstanding = await getTotalOutstanding(invoice)
        loader.hide()
      } catch (error) {
        loader.hide()
        this.$alert('Error: ', error)
        return
      }

      this.$bvModal.show('new-patient-payment-modal')
    }
  }
}
</script>

<style lang="css">
.box-bookMark-Insurance {
  width: 200px;
  height: 100px;
  background-color: #2F6EBA;
  margin-top: -11px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-bookMark-Insurance h3{
  color: #ffffff;
}
</style>