<template>
  <div class="container-fluid mt-2">
    <b-nav class="row" tabs v-if="!$route.meta.print">
      <b-nav-item :active="(currentMenu === menu.name)" v-for="menu in menus" :key="menu.name" @click="(currentMenu = menu.name)" class="mt-2">{{ menu.name }}</b-nav-item>
    </b-nav>

    <invoice-summary
      :start="filter.from"
      :end="filter.to"
      v-if="currentMenu === 'Invoice'"
      @displaySummaryModal="$bvModal.show('invoice-payment-summary-modal')"
      @displayNewPaymentModal="displayNewPaymentModal"
      @displayPaymentModal="displayPaymentModal"
    />

    <payment-summary
      :start="filter.from"
      :end="filter.to"
      v-if="currentMenu === 'Payment'"
      @displaySummaryModal="$bvModal.show('invoice-payment-summary-modal')"
      @displayPaymentModal="displayPaymentModal"
    />

    <audit
      :start="filter.from"
      :end="filter.to"      
      v-if="currentMenu === 'Audit'"  
    />
    
    <!-- <invoice-payment-summary-modal :menu="currentMenu" /> -->

    <patient-payment-modal
      :payment="payment"
      :total_invoiced="total_invoiced"
      :payment_applied="payment_applied"
      :total_outstanding="total_outstanding"
      @displayPaymentModal="displayPaymentModal"
    />
  </div>
</template>

<script>
import { db, Timestamp } from '../../../db'
import { getTotalPaymentApplied, getTotalOutstanding } from '../../../helper/payment'

import Invoice from './Invoice.vue'
import Payment from './Payment.vue'
import Audit from './Audit.vue'

export default {
  components: {
    'invoice-summary': Invoice,
    'payment-summary': Payment,
    'audit': Audit,
  },
  props: ['filter'],
  data() {
    return {
      currentMenu: 'Invoice',
      menus: [
        { name: 'Invoice' },
        { name: 'Payment' },
        { name: 'Audit' },
      ],
      payment: {
        payment_id: null,
        issue_date: this.$moment(new Date(), 'HH:mm DD/MM/YYYY').toDate(),
        HN: null,
        VN: null,
        insurance_name: null,
        insurance_name_other: null,
        patient_name: null,
        cash: 0,
        credit: 0,
        credit_bank_name: null,
        insurance: 0,
        other: 0,
        internet_banking: 0
      },
      total_invoiced: 0,
      payment_applied: 0,
      total_outstanding: 0,
    }
  },
  mounted() {
    // console.log('this filter ====>', this.filter)
  },
  methods: {
    async displayNewPaymentModal(invoice) {
      const loader = this.$loading.show({})
      this.payment = {
        payment_id: null,
        invoice_id: invoice.invoice_id,
        issue_date: Timestamp.fromDate(new Date()),
        HN: invoice.HN || null,
        VN: invoice.VN || null,
        patient_name: invoice.patient_name || null,
        billed_to: invoice.billed_to || null,
        cash: 0,
        credit: 0,
        insurance: 0,
        other: 0,
        internet_banking: 0,
        concession_type: invoice.concession_type,
        insurance_name: invoice.insurance_name || null,
        insurance_type: invoice.insurance_type || null,
        insurance_name_other: invoice.insurance_name_other || null,
        practitioner: invoice.practitioner,
        type: invoice.type,
        note: invoice.note || null,
        extra_charge: 0.03
      }
      this.total_invoiced = invoice.total_invoiced
      this.payment_applied = await getTotalPaymentApplied(invoice)
      this.total_outstanding = await getTotalOutstanding(invoice)
      loader.hide()

      this.$bvModal.show('new-patient-payment-modal')
    },
    async displayPaymentModal(paymentId = null) {
      const loader = this.$loading.show({})
      try {
        const paymentRef = await db.collection('Payment').doc(paymentId).get()
        this.payment = {
          ...paymentRef.data()
        }
        const invoiceRef = await db.collection('Invoice').doc(this.payment.invoice_id).get()
        const invoice = {
          ...invoiceRef.data()
        }
        this.total_invoiced = invoice.total_invoiced
        this.payment_applied = await getTotalPaymentApplied(invoice)
        this.total_outstanding = await getTotalOutstanding(invoice)
        loader.hide()
      } catch (error) {
        loader.hide()
        this.$alert('Error: ', error)
        return
      }

      this.$bvModal.show('new-patient-payment-modal')
    }
  }
}
</script>