var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-auto"},[_vm._v(" Sum Deposit: "+_vm._s(_vm.totalDeposit.toLocaleString())+" ")]),_c('div',{staticClass:"ml-3"},[_vm._v(" Sum Withdrawal: "+_vm._s(_vm.totalWithdrawal.toLocaleString())+" ")])]),_c('vue-good-table',{staticClass:"sender-table",attrs:{"columns":_vm.columns,"rows":_vm.filter_sender,"lineNumbers":true,"sort-options":{ enabled: false, }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'detail')?_c('div',[_c('div',[_vm._v(_vm._s(props.row.detail))]),(props.row.location)?_c('div',{staticClass:"text-secondary"},[_vm._v(" Location : "+_vm._s(props.row.location)+" ")]):_vm._e(),(props.row.admit)?_c('div',{staticClass:"text-secondary"},[_vm._v(" Admit : "+_vm._s(props.row.admit)+" Days ")]):_vm._e()]):(props.column.field == 'time')?_c('div',[_vm._v(" "+_vm._s(_vm.formatTime(props.row.time))+" ")]):(props.column.field == 'patientList')?_c('div',_vm._l((props.row.patientList),function(patient,index){return _c('div',{key:'patient-' + index},[_vm._v(" "+_vm._s(patient.visit_date)+" "+_vm._s(patient.name)+" ")])}),0):(props.column.field == 'staff')?_c('div',_vm._l((props.row.staff),function(name,index){return _c('span',{key:'staff-' + index,staticStyle:{"font-size":"75%"}},[_c('Tag',{attrs:{"text":name,"tagColor":_vm.getRoleColor(name)}})],1)}),0):(props.column.field == 'paymentMethod')?_c('div',[_c('div',{staticClass:"mt-1"},[_c('span',{class:{
                        'cash': props.row.paymentMethod === 'CASH',
                        'scb': props.row.paymentMethod === 'SCB',
                        'ktb': props.row.paymentMethod === 'KTB',
                        'krungsri': props.row.paymentMethod === 'KRUNGSRI',
                        'bbl': props.row.paymentMethod === 'BBL',
                        'kbank': props.row.paymentMethod === 'KBANK',
                    }},[_vm._v(" "+_vm._s(props.row.paymentMethod)+" ")])])]):(props.column.field == 'deposit')?_c('div',[_c('span',{class:{ 'deposit-row': props.row.deposit > 0 }},[_vm._v(_vm._s(props.row.deposit.toLocaleString()))])]):(props.column.field == 'withdrawal')?_c('div',[_c('span',{class:{ 'withdraw-row': props.row.withdrawal > 0 }},[_vm._v(_vm._s(props.row.withdrawal.toLocaleString()))])]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }