<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="box-bookMark-Staff d-flex align-items-center justify-content-center">
            <h3 class="box-bookMark-Staff">Staff</h3>
          </div>
        </div>
        
        <b-nav class="mt-2" tabs>
          <b-nav-item   
            :active="$route.meta.parent === 'Staff'"
            :to="{ name: 'Staff Summary' }">Staff</b-nav-item>
          <b-nav-item   
            :active="$route.meta.parent === 'Role'"
            :to="{ name: 'Role Summary' }">Role</b-nav-item>
        </b-nav>
        <router-view class="mt-2"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted(){
    console.log(this.$route)
  }
}
</script>
<style lang="css">
.box-bookMark-Staff {
  width: 200px;
  height: 100px;
  background-color: #2F6EBA;
  margin-top: -11px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-bookMark-Staff h3{
  color: #ffffff;
}
</style>


