<template>
  <div class="mt-4">
    <div class="d-flex mb-2">
      <h3 class="text-primary">Invoice</h3>
      <div class="ml-auto">
        <button class="btn btn-light ml-auto" @click="exportCSV">
          <i class="fas fa-file-excel"></i> Export CSV
        </button>

      </div>
      <div class="ml-3">
        <a target="_blank" :href="`/print/invoices?start=${start}&end=${end}`" class="btn btn-light ml-auto"><i
            class="fas fa-print"></i> Print Invoice Summary</a>
        <!-- <button
          class="btn btn-light"
          @click="$emit('displaySummaryModal')"
          tabs
          v-if="!$route.meta.print"
        >
          <i class="fas fa-print"></i> Print Invoice Summary
        </button> -->

      </div>
    </div>

    <invoice-table :data="invoices" @showInvoice="showInvoice" @displayPaymentModal="showPayment" />

    <patient-invoice-modal :invoice="invoice" :visits="visits" @refetchInvoices="fetchInvoices"
      @showNewPayment="showNewPayment" />
  </div>
</template>

<script>

import { db } from "../../../db"
import { getTotalDiscount } from "../../../helper/invoice"
import Parser from "@json2csv/plainjs/dist/cjs/Parser"

export default {
  props: ["start", "end"],
  data() {
    return {
      invoices: [],
      rawInvoices: [],
      invoice: {
        invoice_id: null,
        product_items: [],
        billable_items: [],
      },
      visits: [],
      services: [],
    }
  },
  mounted() {
    this.fetchInvoices()
  },
  methods: {
    displayPayment(payments) {
      if (!payments || !Array.isArray(payments)) {
        return ''
      }

      const paymentDetails = []

      payments.forEach((payment) => {
        if (payment.cash) paymentDetails.push(`Cash ${payment.cash}`)
        if (payment.credit) paymentDetails.push(`Credit ${payment.credit}`)
        if (payment.insurance) paymentDetails.push(`Insurance ${payment.insurance}`)
        if (payment.internet_banking) paymentDetails.push(`Internet banking ${payment.internet_banking}`)
        if (payment.other) paymentDetails.push(`Other ${payment.other}`)
      })

      return paymentDetails.join(', ')

    },
    async exportCSV() {

      const combine_invoices = this.invoices.flatMap(item => item.children)

      try {
        const selectedData = combine_invoices.map((data) => {
          const Total_amount = Number(data.total_invoiced || 0)
          const Cash = (data.payments || []).reduce((total, payment) => total + Number(payment.cash || 0), 0)
          const Credit = (data.payments || []).reduce((total, payment) => total + Number(payment.credit || 0), 0)
          const Internet_banking = (data.payments || []).reduce((total, payment) => total + Number(payment.internet_banking || 0), 0)
          const Other_name = (data.payments || [])
            .map(payment => payment.other_name)
            .filter(name => name) 
            .join(', ')
          const Other = (data.payments || []).reduce((total, payment) => total + Number(payment.other || 0), 0)
          const Insurance = (data.payments || []).reduce((total, payment) => total + Number(payment.insurance || 0), 0)
          const Discount = Number(data.discount || 0)
          const Paid = Cash + Credit + Internet_banking + Other + Insurance

          const Payment_ids = (data.payments || []).map(payment => payment.payment_id).join(', ');
          return {
            Invoice_time: this.$moment(data.issue_date.toDate()).format("DD/MM/YYYY HH:mm"),
            Invoice_id: data.invoice_id,
            Visit_date: this.$moment(data.visit_date.toDate()).format("DD/MM/YYYY HH:mm"),
            Patient_name: data.patient_name,
            Total_amount,
            Discount,
            Cash,
            Credit,
            Internet_banking,
            Other_name,
            Other,
            Insurance,
            Paid,
            Payment_ids,
            Status: data.status,
            Outstanding: Total_amount - Paid
          }
        })

        const parser = new Parser()
        const myData = JSON.parse(JSON.stringify(selectedData))
        const csv = parser.parse(myData)

        const bom = "\uFEFF";
        const csvWithBom = bom + csv;

        const anchor = document.createElement("a")
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csvWithBom)
        anchor.target = "_blank"
        anchor.download = "Invoice_" + this.start + "-" + this.end + ".csv"
        anchor.click()
        anchor.remove()


      } catch (err) {
        console.error(err)
      }
    },
    async fetchInvoices() {
      const loader = this.$loading.show({})

      const start = this.$moment(this.start, "DD/MM/YYYY")
        .startOf("day")
        .toDate()
      const end = this.$moment(this.end, "DD/MM/YYYY").endOf("day").toDate()
      const invoices = []
      let invoicesCollection = await db
        .collection("Invoice")
        .where("issue_date", ">", start)
        .where("issue_date", "<", end)
        .get()
      let filter_invoice = null
      if (this.$store.state.branch != 3) {
        filter_invoice = invoicesCollection.docs.filter(doc => {
          return doc.data().concession_type !== "Insurance" && doc.data().invoice_id.slice(0, 2) !== "03"
        })
      }
      else {
        filter_invoice = invoicesCollection.docs.filter(doc => {
          return doc.data().invoice_id.slice(0, 2) === "03"
        })
      }


      const invoicePromises = filter_invoice.map(
        async (invoiceDoc) => {
          const invoiceData = invoiceDoc.data()

          if (invoiceData && invoiceData.invoice_id) {
            const paymentsCollection = await db
              .collection("Payment")
              .where("invoice_id", "==", invoiceData.invoice_id)
              .get()

            const payments = paymentsCollection.docs.map((paymentDoc) =>
              paymentDoc.data()
            )
            invoiceData.payments = payments
          }
          if (invoiceData.HN) {
            const patientDoc = await db.collection("Patient").where("HN", "==", invoiceData.HN).get()
            if (!patientDoc.empty) {
              const patientData = patientDoc.docs[0].data()
              invoiceData.patient_name = `${patientData.first_name} ${patientData.last_name}`

            }
          }
          invoiceData.discount = Math.round(getTotalDiscount(invoiceData))

          return invoiceData
        }
      )

      const resolvedInvoices = await Promise.all(invoicePromises)
      invoices.push(...resolvedInvoices)

      this.rawInvoices = invoices

      const invoicesByDate = _.groupBy(invoices, (item) => {
        return this.$moment(item.issue_date.toDate()).format("DD MMM YYYY")
      })

      this.invoices = Object.entries(invoicesByDate).map((data) => {
        return {
          mode: "span",
          label: data[0],
          html: false,
          children: data[1],
        }
      })

      loader.hide()
    },
    async showInvoice(invoiceId) {
      const selectedInvoice = this.rawInvoices.filter(
        (invoice) => invoice.invoice_id === invoiceId
      )[0]

      this.invoice = {
        ...selectedInvoice,
        issue_date: this.$moment(selectedInvoice.issue_date.toDate()).format(
          "DD/MM/YYYY HH:mm"
        ),
        visit_date: this.$moment(selectedInvoice.visit_date.toDate()).format(
          "DD/MM/YYYY HH:mm"
        ),
        billLog: selectedInvoice.billLog ? selectedInvoice.billLog : [],

      }
      this.visits = await this.fetchVisits(selectedInvoice.HN)
      setTimeout(() => this.$bvModal.show("new-patient-invoice-modal"), 1)
      this.$bvModal.show("new-patient-invoice-modal")
    },
    async showNewPayment(invoice) {
      this.$emit("displayNewPaymentModal", invoice)
    },
    async showPayment(paymentId) {
      this.$emit("displayPaymentModal", paymentId)
    },
    async fetchVisits(patientId) {
      const visits = (
        await db
          .collection("Visit")
          .where("patient", "==", db.collection("Patient").doc(patientId))
          .orderBy("visit_date", "desc")
          .get()
      ).docs.map((visit) => visit.data())

      if (!visits.length)
        this.$alert(
          "No visit information, patient is required to register new visit first before proceed.",
          null,
          "error"
        )

      return visits
    },
  },
  watch: {
    start() {
      this.fetchInvoices()
    },
  },
}
</script>