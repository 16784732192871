<template>
  <div class="tag-container">
    <span class="tag" :style="{ backgroundColor: tagColor }">
      {{ text }}
      <span class="tag-hole"></span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "MARK",
    },
    tagColor: {
      type: String,
      default: "#E91E63", // Pink color like in your example
    },
  },
};
</script>

<style scoped>
.tag-container {
  display: inline-block;
}

.tag {
  display: inline-flex;
  align-items: center;
  padding: 4px 20px 4px 6px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  border-radius: 6px;
  min-width: 50px;
  max-width: fit-content;
  white-space: nowrap;
  position: relative;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.tag-hole {
  position: absolute;
  right: 8px;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
</style>
