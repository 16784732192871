import Sender from "../pages/Sender/";


export default [
  {
    path: "/sender",
    name: "Sender",
    component: Sender
  },

]