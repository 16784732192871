<template>
    <div class="container-fluid">
        <div class="card shadow">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="box-bookMark-Sender">
                            <h3 class="box-bookMark-Sender text-center">Sender</h3>
                        </div>

                    </div>
                    <div class="col-sm-8">
                        <div class="d-flex">
                            <div>
                                <b-dropdown id="branch-dropdown" :text="selectedBranchName" :variant="dropdownVariant">
                                    <b-dropdown-item v-for="dd in $store.state.branches" :key="dd.id"
                                        @click="branch = dd.name_en">
                                        {{ dd.name_en }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="span3 text-center ml-3">
                                <MazSelect v-model="search_date_type" style="z-index: 1"
                                    placeholder="select date filter type" @change="handleFilterDateSearchType" :options="[
                                        { label: 'Search by day', value: 'by-day' },
                                        { label: 'Search by range', value: 'by-range' },
                                    ]" />
                            </div>
                            <div v-if="search_date_type === 'by-day'" class="span3 text-center d-flex mx-3">
                                <MazPicker v-model="search_date_value" no-time format="DD/MM/YYYY"
                                    formatted="DD/MM/YYYY" placeholder="Period Date" />
                            </div>
                            <div v-if="search_date_type === 'by-range'" class="span3 text-center">
                                <MazPicker v-model="filterDate" no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY"
                                    placeholder="Period Date Range" range />
                            </div>
                            <button class="ml-3 btn btn-primary" @click="Deposit">
                                Deposit
                            </button>
                            <button class="ml-auto btn btn-light shadow p3">
                                <b>Available Balance: {{ avaliableBalance() }}</b>
                            </button>
                        </div>

                        <div class="d-flex mt-2">
                            

                        </div>

                    </div>

                </div>

                <sender-table ref="SenderTable" :branch="branch" :publicHealth="publicHealth" />
                <sender-deposit-modal :publicHealth="publicHealth" :branch="branch" />
            </div>
        </div>


    </div>
</template>
<script>
import { db } from "../../db"
import moment from "moment"

export default {

    firestore() {
        return {
            publicHealth: db.collection("publicHealth_lists"),
            pettyCash: db.collection("PettyCash"),
        }
    },
    data() {
        return {

            branch: "HOSPITAL",
            search_date_type: 'by-day',
            search_data_time_value: moment(new Date(), 'DD/MM/YYYY T h:mm:ss').toDate(),
            search_date_value: moment(new Date(), 'DD/MM/YYYY').toDate(),
            filterDate: {
                start: moment(new Date(), "DD/MM/YYYY").startOf('day').toDate(),
                end: moment(new Date(), "DD/MM/YYYY").endOf('day').toDate()
            },
            publicHealth: [],
            pettyCash: [],
        }
    },

    methods: {

        handleFilterDateSearchType(type) {
            this.search_date_type = type
        },
        
        avaliableBalance() {
            const selected = this.pettyCash.find(p => p.branch === this.branch)
            return selected ? selected.amount : 0
        },
        Deposit() {
            this.$bvModal.show("deposit-modal")
        },

    },
    computed: {
        selectedBranchName() {
            const selected = this.$store.state.branches.find(b => b.name_en === this.branch)
            return selected ? selected.name_en : 'Select Branch'
        },

        dropdownVariant() {
            const variants = {
                'HOSPITAL': "primary",
                'SLINKY REFERRAL': "danger",
                'AESTHETIC': "aesthetic",
                'PIER REFERRAL': "success",
                'CENTRAL REFERRAL': "central"
            }
            return variants[this.branch] || "secondary"
        },


    }
}
</script>
<style lang="scss">
.btn-aesthetic {
    background-color: #FBC02D !important;
    color: #fff !important;
}

.btn-central {
    background-color: #7B1FA2 !important;
    color: #fff !important;
}

.box-bookMark-Sender {
    width: 200px;
    height: 100px;
    background-color: #2F6EBA;
    margin-top: -11px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-bookMark-Sender h3 {
    color: #ffffff;
}
</style>