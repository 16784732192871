<template>
    <div class="container-fluid z-2">
        <b-modal id="sender-modal" hide-footer size="xl" no-close-on-backdrop>
            <template #modal-title>
                <div class="d-flex">

                    <div class="">
                        {{ sender.patientList[0].name }}
                    </div>

                </div>
            </template>

            <template>
                <div class="row bg-blue">
                    <div class="row my-3">
                        <div class="col-3">
                            <MazPicker v-model="sender.time" :format="`DD/MM/YYYY HH:mm`"
                                :formatted="`DD/MM/YYYY HH:mm`" placeholder="Time" />
                        </div>
                        <div class="col-3">
                            <MazSelect label="Location" :placeholder="'Location'" v-model="sender.location"
                                :options="prepared_senders" search />
                        </div>
                        <div class="col-3">
                            <MazSelect :placeholder="'Branch'" v-model="sender.branch" :options="branchOptions"
                                search />
                        </div>
                        <div class="col-3">
                            <MazSelect :placeholder="'Staff'" v-model="sender.staff" :options="staff" multiple search />
                        </div>
                    </div>

                </div>

                <div class="row bg-green">
                    <div class="row w-100 my-3">
                        <div class="col-3">
                            <MazInput placeholder="ผู้ส่งเคส" v-model="sender.sender" />
                        </div>
                        <div class="col-3">
                            <MazInput placeholder="ผู้รับเงิน" v-model="sender.recipient" />
                        </div>
                        <div class="col-4">
                            <MazSelect :placeholder="'Details'" v-model="sender.detail" :options="detailOptions"
                                search />
                        </div>
                        <div class="col-2">
                            <MazInput placeholder="Admit (Days)" type="number" v-model="sender.admit" />

                        </div>

                    </div>
                    <div class="row w-100 my-3">
                        <div class="col-3">
                            <MazSelect :placeholder="'Payment Method'" v-model="sender.paymentMethod"
                                :options="paymentMethodOptions" search />
                        </div>
                        <div class="col-3">
                            <MazInput type="number" v-model="sender.withdraw" placeholder="Withdraw" no-buttons />
                        </div>
                        <div class="col-6 d-flex justify-content-end">
                            <div>
                                <button class="btn btn-primary" @click="saveSender()">Save</button>
                            </div>
                            <div class="ml-3">
                                <button class="btn btn-danger" @click="deleteSender()">Delete</button>
                            </div>
                        </div>
                    </div>

                </div>
            </template>


            <div class="d-flex justify-content-end">



            </div>
        </b-modal>
    </div>
</template>

<script>

import { db } from "../../db";
import moment from "moment";
export default {
    props: ['patient_summary'],
    firestore() {
        return {
            locations: db.collection("Sender"),
            details: db.collection("senderDetails"),
        }
    },
    data() {
        return {
            staff: [],
            publicHealth: [],
            sender: {},
            locations: [],
            details: [],
        }
    },
    mounted() {
        this.fetchStaff()
    },
    watch: {
        patient_summary: {
            deep: true,
            handler() {
                this.initDataPatientSummary()
            }
        }
    },
    methods: {
        async fetchStaff() {
            try {
                const [publicHealthSnap] = await Promise.all([

                    db.collection("publicHealth_lists").get(),
                ])


                this.publicHealth = publicHealthSnap.docs.map(doc => doc.data())
                this.staff = [
                    ...this.publicHealth,
                ]

            } catch (error) {
                console.error("Error fetching staff data:", error)
            }

        },
        initDataPatientSummary() {
            let sender = {}
            let patientObj = {
                name: this.patient_summary.patientTitle + " " + this.patient_summary.patientName,
                VN: this.patient_summary.VN,
                HN: this.patient_summary.HN,
            }

            sender.time = moment().format("DD/MM/YYYY HH:mm")
            if (this.patient_summary.sender === 'Other') {
                sender.location = this.patient_summary.sender_other
            }
            else {
                sender.location = this.patient_summary.sender || ''
            }


            if (!Array.isArray(sender.patientList)) {
                sender.patientList = []
            }
            sender.patientList.push(patientObj)

            sender.staff = this.patient_summary.senderLog || []
            sender.branch = this.patient_summary.branch
            this.sender = sender
        },

        async saveSender() {
            let loader = this.$loading.show({})

            try {


            } catch (error) {
                this.$alert(`error ${error}`, null, "error")
                loader.hide()
            }
            loader.hide()
            this.$bvModal.hide("sender-modal")

        },

        async deleteSender() {
            let loader = this.$loading.show({})
            //await db.collection('ReferralPatientSummary').doc(this.patient_summary.id).delete()
            loader.hide()
            this.$alert("Delete Sender Success.", null, "success")
            this.$bvModal.hide("sender-modal")
        },

    },
    computed: {
        prepared_senders() {
            const blankOptions = [{ label: "", value: null }]
            const otherOptions = [{ label: "Other", value: "Other" }]
            return [
                ...blankOptions,
                ...this.locations.map((i) => ({
                    label: i.label,
                    value: i.value,
                })),
                ...otherOptions
            ]
        },
        branchOptions() {
            return this.$store.state.branches.map(dd => ({
                label: dd.name_en,
                value: dd.name_en
            }))
        },
        detailOptions() {
            return [
                ...this.details
            ]
        },
        paymentMethodOptions() {
            return [
                { label: "CASH", value: "CASH" },
                { label: "SCB", value: "SCB" },
                { label: "KTB", value: "KTB" },
                { label: "KRUNGSRI", value: "KRUNGSRI" },
                { label: "KBANK", value: "KBANK" },
                { label: "BBL", value: "BBL" },
            ]
        }
    }



}
</script>

<style lang="scss"></style>