<template>
    <div>
        <div class="container-fluid">
            <div class="card" style="width: 38rem;">
                <img :src="announcement.fileUrl" class="card-img-top">
                <div class="card-body">
                    <h2 class="card-title">{{ announcement.topicTitle }}</h2>
                    <div class="text-right" style="margin-bottom: 0 !important;">
                        <small class="all" v-if="announcement.target === 'all'">#All</small>
                        <span v-else-if="announcement.target === 'department'">
                            <small v-for="(name, index) in announcement.departments" :key="index"
                                :class="getDepartmentClasses(name)">#{{ name }}</small>
                        </span>
                    </div>
                    <div class="text-right text-muted" style="margin-bottom: 0 !important;"><small>{{ formatDate() }}</small></div>
                    <quill-editor v-model="announcement.content" :options="editorOptions"></quill-editor>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { db, storage } from '../../db'
import moment from 'moment'
import { quillEditor } from "vue-quill-editor"

export default {
    components: {
        quillEditor
    },
    firestore() {
        return {
            announcement: db.collection("Ft_announcement").doc(this.$route.params.id)
        }
    },
    data() {
        return {
            announcement_id: '',
            announcement: {},
            editorOptions: {
                theme: 'bubble', // You can change to 'bubble' if you prefer
                readOnly: true, // Set true if you only want to display
                modules: {
                    toolbar: false, // Hide toolbar in view mode
                },
            }
        };
    },

    methods: {

        formatDate() {
            return moment(this.announcement.createdAt.toDate()).format('DD/MM/YYYY HH:mm:ss')
        },
        getDepartmentClasses(dept) {           
            if (dept === 'Management') return 'management'
            if (dept === 'Account') return 'account'
            if (dept === 'Development') return 'development'
            if (dept === 'AR') return 'ar'
            if (dept === 'Insurance') return 'insurance'
            if (dept === 'Doctor') return 'doctor'
            if (dept === 'Nurse') return 'nurse'
            if (dept === 'Public Health') return 'publicHealth'
            if (dept === 'Med Tech') return 'medTech'
            if (dept === 'Wa') return 'wa'
            if (dept === 'Driver') return 'driver'
            if (dept === 'Housekeeper') return 'housekeeper'
        }

    },
    computed: {

    }
};
</script>
<style>
.all {
    font-weight: bold;
    background-color: #fff;
    color: #f44336;
    padding: 3px;
    border: 3px solid #f44336;
    border-radius: 0.5em;

}

.hop {
    font-weight: bold;
    background-color: #fff;
    color: #01579b;
    padding: 3px;
    border: 3px solid #01579b;
    border-radius: 0.5em;
}

.management .account {
    font-weight: bold;
    background-color: #fff;
    color: #ffca28;
    padding: 3px;
    border: 3px solid #ffca28;
    border-radius: 0.5em;
}

.development {
    font-weight: bold;
    background-color: #fff;
    color: #ffa726;
    padding: 3px;
    border: 3px solid #ffa726;
    border-radius: 0.5em;
}

.ar {
    font-weight: bold;
    background-color: #fff;
    color: #e64a19;
    padding: 3px;
    border: 3px solid #e64a19;
    border-radius: 0.5em;
}

.hib .insurance {
    font-weight: bold;
    background-color: #fff;
    color: #4fc3f7;
    padding: 3px;
    border: 3px solid #4fc3f7;
    border-radius: 0.5em;
}

.doctor {
    font-weight: bold;
    background-color: #fff;
    color: #274e13ff;
    padding: 3px;
    border: 3px solid #274e13ff;
    border-radius: 0.5em;
}

.nurse {
    font-weight: bold;
    background-color: #fff;
    color: #388e3c;
    padding: 3px;
    border: 3px solid #388e3c;
    border-radius: 0.5em;
}

.hph .publicHealth .medTech {
    font-weight: bold;
    background-color: #fff;
    color: #8bc34a;
    padding: 3px;
    border: 3px solid #8bc34a;
    border-radius: 0.5em;
}

.wa {
    font-weight: bold;
    background-color: #fff;
    color: #ffecb3;
    padding: 3px;
    border: 3px solid #ffecb3;
    border-radius: 0.5em;
}

.driver {
    font-weight: bold;
    background-color: #fff;
    color: #b71c1c;
    padding: 3px;
    border: 3px solid #b71c1c;
    border-radius: 0.5em;
}

.housekeeper {
    font-weight: bold;
    background-color: #fff;
    color: #f9a825;
    padding: 3px;
    border: 3px solid #f9a825;
    border-radius: 0.5em;
}
</style>