import NewsMeetingReport from "../pages/NewsMeetingReport/";
import DisplayNewsMeeting from "../pages/NewsMeetingReport/Announcement_fulldisplay.vue";

export default [
  {
    path: "/news-meeting-report",
    name: "News & Meeting Report",
    component: NewsMeetingReport,
    meta: {
      print: true
    }
  },
  {
    path: "/news-meeting-report/display/:id",
    name: "Display News & Meeting",
    component: DisplayNewsMeeting,
    meta: {
      print: true
    }
  },

]