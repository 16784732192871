<template>
    <div>
        <b-card class="ManageMeeting">
            <div class="d-flex">
                <div class="ml-auto mb-3 mt-2">
                    <button class="btn btn-primary " @click="showMeeting()">
                        <i class="fas fa-plus"></i> Meeting
                    </button>
                </div>
            </div>
            <b-modal id="meeting-modal" title="Meeting Report" hide-footer size="xl" header-bg-variant="gray"
                no-close-on-backdrop>
                <div class="row">
                    <div class="col-3 mb-3">
                        <MazPicker v-model="meeting.time" :format="`DD/MM/YYYY HH:mm`" :formatted="`DD/MM/YYYY HH:mm`"
                            placeholder="Time" />
                    </div>
                    <div class="col-3 mb-3">
                        <MazSelect placeholder="Department" v-model="meeting.department" :options="[
                            { label: 'All', value: 'All' },
                            { label: 'HOP', value: 'HOP' },
                            { label: 'HPH', value: 'HPH' },
                            { label: 'PH', value: 'PH' },
                            { label: 'HRN', value: 'HRN' },
                            { label: 'RN', value: 'RN' },
                            { label: 'PMD', value: 'PMD' },
                            { label: 'PNS', value: 'PNS' },
                            { label: 'HIB', value: 'HIB' },
                            { label: 'IB', value: 'IB' },
                            { label: 'WA', value: 'WA' },
                            { label: 'FN', value: 'FN' },
                            { label: 'BF', value: 'BF' },
                            { label: 'AR', value: 'AR' },
                            { label: 'DV', value: 'DV' },
                            { label: 'HK', value: 'HK' },
                        ]" multiple search />
                    </div>
                    <div class="col-3">

                    </div>
                    <div class="col-3 ">
                        <button class="btn btn-primary" @click="addTitle">
                            <i class="fas fa-plus"></i> Add Title
                        </button>
                    </div>

                    <div class="col-12">
                        <draggable v-model="meeting.title_items" class="list-group">
                            <div v-for="(item, index) in meeting.title_items" :key="index" class="list-group-item">
                                <div class="row">
                                    <div class="col-3">
                                        <MazInput v-model="item.title" placeholder="Title" class="mb-3" />
                                        <MazSelect placeholder="Task Status" v-model="item.task_status"
                                            :options="task_status" search />
                                    </div>
                                    <div class="col-8">
                                        <draggable v-model="item.content_items" class="list-group">
                                            <div v-for="(content, contentIndex) in item.content_items"
                                                :key="contentIndex" class="list-group-item">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <MazSelect placeholder="Staff Main" v-model="content.staff_main"
                                                            :options="staff" multiple search track-by="value"
                                                            label="label" class="mb-3">
                                                        </MazSelect>
                                                    </div>
                                                    <div class="col-4">
                                                        <MazSelect placeholder="Staff Assist"
                                                            v-model="content.staff_assist" :options="staff" multiple
                                                            search track-by="value" label="label">
                                                        </MazSelect>
                                                    </div>
                                                    <div class="col-4 mb-3">
                                                        <MazPicker v-model="content.due_date"
                                                            :format="`DD/MM/YYYY HH:mm`" :formatted="`DD/MM/YYYY HH:mm`"
                                                            placeholder="Due date" />
                                                    </div>

                                                    <div class="col-11">
                                                        <textarea v-model="content.content" cols="76"
                                                            rows="3"></textarea>
                                                    </div>
                                                    <div class="col-1 mt-3">
                                                        <button class="btn btn-danger"
                                                            @click="deleteContent(index, contentIndex)">
                                                            <i class="fas fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </draggable>

                                    </div>
                                    <div class="col-1">
                                        <button class="btn btn-primary" @click="addContent(index)">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                        <button class="btn btn-danger mt-1" @click="deleteTitle(index)">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </draggable>
                    </div>
                    <div class="col-3 mt-3">
                        <MazPicker v-model="meeting.end_meeting" :format="`DD/MM/YYYY HH:mm`"
                            :formatted="`DD/MM/YYYY HH:mm`" placeholder="End Meeting" />
                    </div>
                    <div class="col-3 mt-3">
                        <MazPicker v-model="meeting.next_meeting" :format="`DD/MM/YYYY`" :formatted="`DD/MM/YYYY`"
                            placeholder="Next Meeting" no-time />
                    </div>
                    <div class="col-6">

                    </div>
                    <div class="col-11 mt-3">
                        <MazSelect placeholder="Onsite Meeting" v-model="meeting.onsite_meeting" :options="staff"
                            multiple search track-by="value" label="label">
                        </MazSelect>
                    </div>
                    <div class="col-11 mt-3">
                        <MazSelect placeholder="Online Meeting" v-model="meeting.online_meeting" :options="staff"
                            multiple search track-by="value" label="label">
                        </MazSelect>
                    </div>
                    <div class="col-2 mt-3">
                        Note :
                    </div>
                    <div class="col-8 mt-3">
                        <textarea v-model="meeting.note" cols="80" rows="3"></textarea>
                    </div>

                </div>
                <div class="d-flex justify-content-end mb-2 mr-3">
                    <div>
                        <button class="btn btn-primary" @click="saveMeeting()">Save</button>
                        <button class="btn btn-danger" @click="deleteMeeting()" v-if="isEditMeeting">Delete</button>
                    </div>
                </div>
            </b-modal>
            <vue-good-table class="meeting-table" :columns="meetingColumns" :rows="meetings"
                :pagination-options="{ enabled: true, perPageDropdown: [50, 100, 200], mode: 'pages' }"
                @on-row-click="showMeeting">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'title_items'">
                        <draggable class="list-group">
                            <div class="row mb-2 " v-for="(item, index) in props.row.title_items" :key="index">
                                <div class="col-1">
                                    {{ index + 1 }}.
                                </div>
                                <div class="col-7">
                                    Title :
                                    {{ item.title }}
                                </div>
                                <div class="col-4">
                                    Task Status :
                                    {{ item.task_status }}
                                </div>
                                <div class="col-12">
                                    <draggable>
                                        <div v-for="(content, contentIndex) in item.content_items" :key="contentIndex">
                                            <div class="row">
                                                <div class="col-1">
                                                </div>
                                                <div class="col-8">
                                                    <i class="fa fa-circle mr-3" aria-hidden="true"
                                                        style="font-size: 9px;"></i> {{ content.content }}
                                                </div>
                                            </div>
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                        </draggable>
                    </div>
                </template>
            </vue-good-table>
        </b-card>
    </div>
</template>

<script>
import { db, storage } from '../../../db'
import draggable from 'vuedraggable'
import moment from 'moment'
export default {
    props: ["staff"],
    components: {
        draggable,
    },
    firestore() {
        return {
            meetings: db.collection("Ft_meeting").orderBy('time', 'desc'),
        }
    },
    data() {
        return {
            meetings: [],
            meeting: {
                title_items: [],
                time: new Date(),
                department: [],
                onsite_meeting: [],
                online_meeting: [],
                end_meeting: new Date(),
                next_meeting: null,
            },
            task_status: [
                { label: "Urgent", value: "Urgent" },
                { label: "Complete", value: "Complete" },
                { label: "In progress", value: "In progress" },
                { label: "Start", value: "Start" },
                { label: "Pending", value: "Pending" },
            ],
            meetingColumns: [
                {
                    label: 'Time',
                    field: 'time',
                    formatFn: (row) => moment(row.toDate()).format("DD/MM/YYYY HH:mm")
                },
                {
                    label: "Department",
                    field: "department",
                    formatFn: (row) => row.join(", "),
                },
                {
                    label: "Details",
                    field: "title_items",

                },
                {
                    label: "Next meeting",
                    field: "next_meeting",
                },
                {
                    label: "Note",
                    field: "note",
                }
            ],
            isEditMeeting: false,
        }
    },

    methods: {
        async showMeeting(params) {
            if (params) {
                this.meeting = {
                    id: params.row.id,
                    ...params.row,
                }
                this.meeting.time = moment(this.meeting.time.toDate()).format("DD/MM/YYYY HH:mm")
                this.meeting.end_meeting = moment(this.meeting.end_meeting.toDate()).format("DD/MM/YYYY HH:mm")
                this.isEditMeeting = true
            } else {
                this.isEditMeeting = false
                this.meeting = {
                    title_items: [],
                    time: new Date(),
                    department: [],
                    onsite_meeting: [],
                    online_meeting: [],
                    end_meeting: new Date(),
                    next_meeting: null,
                }
            }
            this.$bvModal.show('meeting-modal')
        },
        addTitle() {
            this.meeting.title_items.push({
                title: '',
                task_status: '',
                content_items: []
            })
        },
        addContent(index) {
            this.meeting.title_items[index].content_items.push({
                staff_main: [],
                staff_assist: [],
                due_date: '',
                content: ''
            })
        },
        deleteTitle(index) {
            this.meeting.title_items.splice(index, 1)
        },
        deleteContent(titleIndex, contentIndex) {
            this.meeting.title_items[titleIndex].content_items.splice(contentIndex, 1)
        },
        async saveMeeting() {
            let loader = this.$loading.show({})
            try {

                if (this.isEditMeeting) {
                    this.meeting.time = moment(this.meeting.time, "DD/MM/YYYY HH:mm").toDate()
                    this.meeting.end_meeting = moment(this.meeting.end_meeting, "DD/MM/YYYY HH:mm").toDate()
                    delete this.meeting.vgt_id
                    delete this.meeting.originalIndex
                    await db.collection('Ft_meeting').doc(this.meeting.id).update({ ...this.meeting })

                } else {
                    this.meeting.time = moment(this.meeting.time, "DD/MM/YYYY HH:mm").toDate()
                    this.meeting.end_meeting = moment(this.meeting.end_meeting, "DD/MM/YYYY HH:mm").toDate()
                    const Ref = await db.collection("Ft_meeting").add(this.meeting)
                    await db.collection("Ft_meeting").doc(Ref.id).update({ id: Ref.id })
                }
            } catch (error) {
                this.$alert(`error ${error}`, null, "error")
                loader.hide()
                return
            }
            loader.hide()
            this.$alert("success", null, "success")
            this.$bvModal.hide("meeting-modal")
        },
        async deleteMeeting() {
            try {
                await db.collection('Ft_meeting').doc(this.meeting.id).delete()
                this.$alert("success", null, "success")
                this.$bvModal.hide("meeting-modal")
            } catch (error) {
                this.$alert(`error ${error}`, null, "error")
                loader.hide()
                return
            }

        },
    },

}
</script>
<style lang="scss" scoped>
.ManageMeeting {
    font-family: 'Arial';
    background-color: #a0727218;
    height: 840px;
    color: white !important;
}
</style>