<template>
    <div class="container-fluid">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="box-bookMark-Service d-flex align-items-center justify-content-center">
              <h3 class="box-bookMark-Service">Service</h3>
            </div>
          </div>
          <!--<div class="text-muted">Service Management</div>  -->
         
          <b-nav class="mt-2" tabs>
            <b-nav-item :active="currentMenu === menu.name" v-for="(menu, index) in menus" :key="index"
              @click="currentMenu = menu.name">{{ menu.name }}</b-nav-item>
          </b-nav>
  
          <div v-if="currentMenu === 'Service'">
            <service-tab />  
          </div>

        </div>
  
      </div>
      
    </div>
  </template>
  
  <script>

  import Service from './Service.vue'
  
  export default {
    components: {
      
      "service-tab": Service,
    },
    data() {
      return {
        currentMenu: "Service",        
      }
    },
    firestore() {
      return {
      }
    },
    methods: {    
      
    },
    computed: {      
      menus() {
        const menus = [
          { name: "Service" },
        ]
        return menus
      },
    },
  }
  </script>
<style lang="css">
  .box-bookMark-Service {
    width: 200px;
    height: 100px;
    background-color: #2F6EBA;
    margin-top: -11px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box-bookMark-Service h3{
    color: #ffffff;
  }
  </style>