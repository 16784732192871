<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3">
            <div class="box-bookMark-Visit" 
                :class="{
                    'box-bookMark-Visit-3': $store.state.branch == 3,
                    'box-bookMark-Visit': $store.state.branch != 3
                }">
              <h3 class="text-center" :class="$store.state.branch == 3 ? 'text-wa' : 'text-white'">Visit</h3>
            </div>
          </div>
          <div class="cols">
            <div class="col-md-12 offset-sm-0 ">
              <form @submit.prevent="triggerKeywordSearch">
              <div class="input-group mt-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search patient by first name, last name, passport id"
                  v-model="keyword"
                  :readonly="isLoading"
                />
                <div class="input-group-append">
                  <button type="submit" class="btn btn-primary" aria-expanded="false">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="row">
              <div class="d-flex mx-auto gap-x-2 mt-3 mx-2">
              <div class="span3 text-center" v-if="$store.state.branch == 3">
                <MazSelect
                  v-model="branch"
                  style="z-index: 10000"
                  placeholder="select branch"
                  @change="handleFilterBranch"
                  :options="[
                    { label: $store.state.branches[2].name_en, value: Number(3) },                  
                  ]"
                />
              </div>
              <div class="span3 text-center" v-else>
                <MazSelect
                  v-model="branch"
                  style="z-index: 10000"
                  placeholder="select branch"
                  @change="handleFilterBranch"
                  :options="[
                    { label: 'All', value: 'all' },
                    ...$store.state.branches.filter(b=> b.id !== '3').map(b => {
                      return {
                        label: `${b.name_en}`,
                        value: Number(b.id)
                      };
                    })
                  ]"
                />
              </div>
              <div class="span3 text-center mx-2">
                <MazSelect
                  v-model="search_date_type"
                  style="z-index: 10000"
                  placeholder="select date filter type"
                  @change="handleFilterDateSearchType"
                  :options="[
                    { label: 'Search by day', value: 'by-day' },
                    { label: 'Search by range', value: 'by-range' },
                  ]"
                />
              </div>
              <div v-if="search_date_type === 'by-day'" class="span3 text-center d-flex mx-1">
                <button class="btn btn-primary mx-1" @click.prevent="triggerSearchDate(-1)"><i class="fa fa-angle-left" aria-hidden="true"></i></button>
                <MazPicker
                  v-model="search_date_value"
                  style="z-index: 10000"
                  no-time
                  format="DD/MM/YYYY"
                  formatted="DD/MM/YYYY"
                  placeholder="Period Date"
                />
                <button class="btn btn-primary mx-1" @click.prevent="triggerSearchDate(1)"><i class="fa fa-angle-right" aria-hidden="true"></i></button>
              </div>
              <div v-if="search_date_type === 'by-range'" class="span3 text-center">
                <MazPicker
                  v-model="filterDate"
                  style="z-index: 10000"
                  no-time
                  format="DD/MM/YYYY"
                  formatted="DD/MM/YYYY"
                  placeholder="Period Date Range"
                  range
                />
              </div>
            <!-- <div class="col-md-2 col-sm-12 text-center">
              <MazPicker
                v-model="dateEnd"
                style="z-index: 10000"
                no-time
                format="DD/MM/YYYY"
                formatted="DD/MM/YYYY"
                placeholder="Period End Date"
              />
            </div> -->
          </div>
          </div>
          <div class="d-flex">
              <button class="btn btn-primary mx-auto mt-4 px-4" @click.prevent="triggerInitData"><h5>Search</h5></button>
          </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
        <!--  <div class="align-items-center">
            <div class="box-bookMark-Visit align-items-center" 
            :class="{
                'box-bookMark-Visit-3': $store.state.branch == 3,
                'box-bookMark-Visit': $store.state.branch != 3
            }">
              <h3 class="text-center" :class="$store.state.branch == 3 ? 'text-wa' : 'text-white'">Visit</h3>
            </div>
          </div>-->
        <!--<h3 class="text-center" :class="$store.state.branch == 3 ? 'text-wa' : 'text-primary'">Visit Summary</h3>-->
        <!-- <div class="text-center mt-2">
          <b-button-group>
            <b-button
              class="mode-btn"
              :variant="mode === 'all' ? 'primary' : 'outline-dark'"
              @click="mode = 'all'"
              >All</b-button
            >
            <b-button
              class="mode-btn"
              :variant="mode === 'reject' ? 'primary' : 'outline-dark'"
              @click="mode = 'reject'"
              >Reject</b-button
            >
          </b-button-group>
        </div> -->
      <!--  <div class="row w-100">
          <div class="col-md-8 offset-md-2 col-sm-12">
            <form @submit.prevent="triggerKeywordSearch">
              <div class="input-group mt-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search patient by first name, last name, passport id"
                  v-model="keyword"
                  :readonly="isLoading"
                />
                <div class="input-group-append">
                  <button type="submit" class="btn btn-primary" aria-expanded="false">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>-->
        </div>
        
   <!--     <div class="row">
          <div class="d-flex mx-auto gap-x-2 mt-2 mx-2">
            <div class="span3 text-center" v-if="$store.state.branch == 3">
              <MazSelect
                v-model="branch"
                style="z-index: 10000"
                placeholder="select branch"
                @change="handleFilterBranch"
                :options="[
                  { label: $store.state.branches[2].name_en, value: Number(3) },                  
                ]"
              />
            </div>
            <div class="span3 text-center" v-else>
              <MazSelect
                v-model="branch"
                style="z-index: 10000"
                placeholder="select branch"
                @change="handleFilterBranch"
                :options="[
                  { label: 'All', value: 'all' },
                  ...$store.state.branches.filter(b=> b.id !== '3').map(b => {
                    return {
                      label: `${b.name_en}`,
                      value: Number(b.id)
                    };
                  })
                ]"
              />
            </div>
            <div class="span3 text-center mx-2">
              <MazSelect
                v-model="search_date_type"
                style="z-index: 10000"
                placeholder="select date filter type"
                @change="handleFilterDateSearchType"
                :options="[
                  { label: 'Search by day', value: 'by-day' },
                  { label: 'Search by range', value: 'by-range' },
                ]"
              />
            </div>
            <div v-if="search_date_type === 'by-day'" class="span3 text-center d-flex mx-3">
              <button class="btn btn-primary mx-1" @click.prevent="triggerSearchDate(-1)"><i class="fa fa-angle-left" aria-hidden="true"></i></button>
              <MazPicker
                v-model="search_date_value"
                style="z-index: 10000"
                no-time
                format="DD/MM/YYYY"
                formatted="DD/MM/YYYY"
                placeholder="Period Date"
              />
              <button class="btn btn-primary mx-1" @click.prevent="triggerSearchDate(1)"><i class="fa fa-angle-right" aria-hidden="true"></i></button>
            </div>
            <div v-if="search_date_type === 'by-range'" class="span3 text-center">
              <MazPicker
                v-model="filterDate"
                style="z-index: 10000"
                no-time
                format="DD/MM/YYYY"
                formatted="DD/MM/YYYY"
                placeholder="Period Date Range"
                range
              />
            </div>
           <div class="col-md-2 col-sm-12 text-center">
              <MazPicker
                v-model="dateEnd"
                style="z-index: 10000"
                no-time
                format="DD/MM/YYYY"
                formatted="DD/MM/YYYY"
                placeholder="Period End Date"
              />
            </div> 
          </div>
        </div>-->
       <!-- <div class="d-flex">
          <button class="btn btn-primary mx-auto mt-4 px-4" @click.prevent="triggerInitData"><h5>Search</h5></button>
        </div>-->

        <visit-table
          ref="visitTableComponent"
          :dateStart="search_date_type === 'by-day' ? search_date_value : filterDate.start" 
          :dateEnd="search_date_type === 'by-day' ? search_date_value : filterDate.end" 
          :keyword="keyword"
          :branch="branch"
          :mode="mode"
          @onLoading="isLoading=true"
          @onLoaded="isLoading=false"/>
      </div>
    </div>
  </div>
</template>

<script>
import { TimeScale } from "chart.js";
import _ from "lodash";
import moment from "moment";
// import VisitTable from '../../components/Table/VisitTable.vue'
export default {
  components: {
    // VisitTable
  },
  data() {
    return {
      mode: "all",
      keyword: null,
      branch: "all",
      filterDate: {
        start: moment(new Date(), 'DD/MM/YYYY').toDate(),
        end: moment(new Date(), 'DD/MM/YYYY').toDate()
      },
      // dateStart: new Date(),
      // dateEnd: new Date(),
      isLoading: false,
      search_date_value: moment(new Date(), 'DD/MM/YYYY').toDate(),
      search_date_type: 'by-day'
    };
  },
  mounted() {
    console.log("🚀 ~ mounted ~ this.$store.state.selectedBranch:", this.$store.state.branch)
    this.triggerInitData()
    
  },
  methods: {
    onRowClick(params) {
      let HN = params.row.HN;
      this.$router.push(`/patient/${HN}`);
    },
    getAllergyList(rowObj) {
      let allergies = rowObj.allergies;
      let other_allergies = rowObj.other_allergies;
      if (allergies && other_allergies) {
        let allergies_string = allergies
          .map(item => {
            return item.name;
          })
          .join(", ");
        let other_allergies_string = other_allergies
          .map(item => {
            return item.name;
          })
          .join(", ");
        return [allergies_string, other_allergies_string].join(", ");
      } else {
        return "-";
      }
    },
    getInsurance(item) {
      if (item.travel_insurance) return item.travel_insurance.name_en;
      else return item.has_travel_insurance;
    },
    time(rowObj) {
      if(rowObj.visit_date) {
        try {
          return this.$moment(rowObj.visit_date.toDate()).format("HH:mm");
        } catch (error) {
          return '-'          
        }
      }else{
        return '-'
      }
    },
    getEsiColor(esi) {
      switch (esi) {
        case 1:
          return "#CB0000";
          break;
        case 2:
          return "#EA9A99";
          break;
        case 3:
          return "#F1C331";
          break;
        case 4:
          return "#629B49";
          break;
        case 5:
          return "#FFFFFF";
          break;
      }
    },
    handleFilterBranch() {

    },
    triggerInitData() {
      this.$refs.visitTableComponent.initData()
    },
    handleFilterDateSearchType(type) {
      this.search_date_type = type
    },
    triggerSearchDate(step) {
      let currentDateSearch = moment(this.search_date_value, 'DD/MM/YYYY')
      this.search_date_value = currentDateSearch.clone().add(step, 'days').toDate();
    },
    triggerKeywordSearch() {
      this.$refs.visitTableComponent.handleKeywordSearch()
    }
  }
};
</script>

<style lang="scss">
.mode-btn {
  min-width: 100px;
}

.container-fluid {
  margin-bottom: 50px;
}

.box-bookMark-Visit {
  width: 200px;
  height: 100px;
  background-color: #2F6EBA;
  margin-top: -20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-bookMark-Visit-3 {
  width: 200px;
  height: 100px;
  background-color: #f5f5f5 !important;
  margin-top: -20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
