<template>
    <div>
        <b-card class="MeetingIndex">
            <b-card class="mb-3" style="border: none;">
                <div class="row">
                    <div class="col-2">
                        <MazPicker v-model="DateMeeting" :format="`YYYY/MM/DD`" :formatted="`YYYY/MM/DD`"
                             placeholder="DateMeeting" no-time />
                    </div>
                    <div class="col-9">
                        <button class="btn btn-success" @click="SearchDate">Search</button>
                    </div>
                    <div class="col-1">
                        <button class="btn btn-primary ml-5 " @click="goToMeetingReport">
                            <i class="fas fa-print"></i> Print
                        </button>
                    </div>
                </div>
            </b-card>
            <b-tabs content-class="mt-3">
                <b-tab v-for="(meeting, index) in Meetings" :key="index" :title="meeting?.department[0]">
                    <CardData :MeetingsIN="meeting" />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import { db } from '../../db'
import moment from 'moment'
import CardData from "./Meeting/card-data.vue"

export default {
    components: {
        CardData,
    },
    data() {
        return {
            Meetings: [],
            DateMeeting: new Date().toISOString().split('T')[0] , // รูปแบบ YYYY-MM-DD
        }
    },
    computed: {
    },
    mounted() {
        this.fetchMeeting();
    },
    methods: {
        fetchMeeting() {
            db.collection("Ft_meeting")
                .orderBy('time', 'desc')
                .get()
                .then((querySnapshot) => {
                    this.Meetings = []; // Clear the existing meetings array.
                    querySnapshot.forEach((doc) => {
                        const meetingData = doc.data(); // Access the document data.
                        if (meetingData.end_meeting && typeof meetingData.end_meeting.toDate === "function") {
                            meetingData.end_meeting = moment(meetingData.end_meeting.toDate()).format("YYYY/MM/DD HH:mm");
                        }
                        if (meetingData.time && typeof meetingData.time.toDate === "function") {
                            meetingData.time = moment(meetingData.time.toDate()).format("YYYY/MM/DD HH:mm");
                        }
                        const selectedDate = this.DateMeeting; // Make sure 'DateMeeting' is defined (e.g., v-model bound to the input)
                        const meetingDate = meetingData.time.split(' ')[0]; // Extract date part from meeting.time (e.g. "2025-01-14")
                        if (meetingDate === selectedDate) {
                            this.Meetings.push({
                                ...meetingData,
                            });
                        }
                    });

                })
                .catch(error => {
                    console.log("Error getting documents: ", error);
                });
        },
        goToMeetingReport() {
            const meetingsString = JSON.stringify(this.Meetings);
            const url = `/print/MeetingReport?meetings=${encodeURIComponent(meetingsString)}&print=true`;
            window.open(url, '_blank');
        },
        SearchDate() {
            this.fetchMeeting();
        }

    },
};
</script>

<style lang="scss" scoped>
.MeetingIndex {
    font-family: 'Arial';
    margin-top: 10px;
    border-radius: 20px;
    background-color: #ffffff;
    color: white !important;

}
</style>