<template>
  <div>
    <b-card class="ManageIndex"> 
  <!-- Nav Tabs -->
      <b-nav class="ml-0" tabs >
          <b-nav-item :active="currentMenu === menu.name" v-for="(menu, index) in menus" :key="index"
              @click="currentMenu = menu.name">
              {{ menu.name }}
          </b-nav-item>
      </b-nav>
      <!-- Show Announcement Tab -->
      <div v-if="currentMenu === 'Announcement'">
        <div style="margin-top: 5px;">
          <Announcement :staff="staff" />
        </div>
      </div>

      <!-- Show Meeting Tab -->
      <div v-if="currentMenu === 'Meeting'">
        <div style="padding: 3px;">
          <Meeting :staff="staff" />
          </div>
      </div>
    </b-card>
    </div>
 
</template>

<script>
import Announcement from './Manage/ManageNews.vue'
import Meeting from './Manage/ManageMeeting.vue'

export default {
  components: {
      Announcement,
      Meeting
  },
  data() {
      return {
          // Initialize currentMenu to the first menu item
          currentMenu: 'Announcement', // This is the default menu that will be active on load
          menus: [
              { name: 'Announcement' },
              { name: 'Meeting' }
          ],
          staff: [] // You can populate staff data as needed
      }
  },
  mounted() {
      // Optionally fetch or populate staff data when the component is mounted
  }
}
</script>
<style lang="scss" scoped>
.ManageIndex {
  margin-top: 10px;
  border-radius: 20px;
  background-color: #ffffff18;
  color: white !important;
  box-shadow: 0px 0px 20px rgba(5, 140, 202, 0.8);
  transition: box-shadow 0.3s ease; /* เพิ่ม transition เพื่อให้การเปลี่ยนแปลงนุ่มนวล */


.ManageIndex:focus {
  animation: glow 800ms ease-out infinite alternate;
  color: #efe;
  outline: none;
}

/* Animation */
@keyframes glow {
  0% {
    box-shadow: 0 5px 25px rgba(5, 140, 202, 0.8);
  }

  100% {
    box-shadow: 0 5px 25px rgba(5, 140, 202, 1);
  }
}
}
</style>