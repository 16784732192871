<template>
  <div class="container-fluid">
    <div class="d-flex">
      <h3 class="text-primary">Products</h3>

      <button class="btn btn-primary ml-auto" @click="$bvModal.show('import-product')">
        <i class="fas fa-file-import"></i> Import
      </button>
      <!-- <button class="btn btn-primary ml-2" @click="mergeData">
        <i class="fa fa-code-fork" aria-hidden="true"></i> Merge data
      </button> -->
      <button class="btn btn-primary ml-2" @click="registerBarcode">
        <i class="fas fa-plus"></i> Register Barcode
      </button>
      <button class="btn btn-primary ml-2" @click="showProduct()">
        <i class="fas fa-plus"></i> New Products
      </button>
    </div>

    <b-modal id="import-product" title="Import Product" hide-footer>
      <div class="d-flex mt-2">
        <button class="btn btn-primary btn-block" @click="exportCsv(processedMedicines, 'Products_Data')">
          Download CSV
        </button>
      </div>
      <!-- <hr />
      <hr />
      <div class="d-flex mt-2">

        <input id="products-csv" ref="products-csv" type="file" hidden
          accept=".csv, .xls, .xlsx, text/csv, application/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.msexcel, text/anytext, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @change="uploadCsv" />
        <button class="btn btn-primary btn-block" @click="$refs['products-csv'].click()">
          Import CSV
        </button>
      </div> -->
    </b-modal>

    <vue-good-table :columns="medicineColumns" :rows="processedMedicines" @on-row-click="showProduct"
      :search-options="{ enabled: true, }" :pagination-options="{ enabled: true, }">
      <template v-slot:table-row="props">
        <div v-if="props.column.field === 'quantity'">
          <span>{{ props.row.quantity || 0 }}</span>
          <span class="ml-1" style="font-size: smaller;">{{ props.row.units || '' }}</span>
        </div>
        <div v-else-if="props.column.field === 'stock_level'">
          <span>{{ props.row.stock_level || '' }}</span>
          <span class="ml-1" style="font-size: smaller;" v-if="props.row.stock_level > 0">{{ props.row.units || ''
          }}</span>
        </div>
        <div v-else-if="props.column.field === 'order'">
          <span>{{ props.row.order }}</span>
          <span class="ml-1" style="font-size: smaller;" v-if="props.row.order">{{ props.row.units || '' }}</span>
        </div>
        <div v-else-if="props.column.field === 'order_status'">
          <span :class="getOrderStatus(props.row.order)"> {{ props.row.order_status }}</span>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>

      </template>
    </vue-good-table>

    <b-modal id="product-modal" hide-footer size="xl" :title="'Product'">
      <div class="row">
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Item code'" v-model="product.item_code" />
        </div>
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Item name'" v-model="product.item_name" />
        </div>
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Generic name'" v-model="product.item_other_name" />
        </div>
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Item type'" v-model="product.item_type" />
        </div>
        <div class="col-12 mt-2">

          <MazInput type="number" :placeholder="'Price (Thai)'" v-model="product.price_th" v-if="!isEditProduct" />
          <MazInput type="number" :placeholder="'Price (Thai)'" v-model="product.price_th" v-else-if="isEditProduct" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Price (Foreigner)'" v-model="product.price_inter"
            v-if="!isEditProduct" />
          <MazInput type="number" :placeholder="'Price (Foreigner)'" v-model="product.price_inter"
            v-else-if="isEditProduct" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Price (Insurance)'" v-model="product.price_insurance"
            v-if="!isEditProduct" />
          <MazInput type="number" :placeholder="'Price (Insurance)'" v-model="product.price_insurance"
            v-else-if="isEditProduct" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="text" :placeholder="'Brand'" v-model="product.brand" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="text" :placeholder="'Manufacturer'" v-model="product.manufacturer" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="text" :placeholder="'Distributor'" v-model="product.distributor" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Cost'" v-model="product.cost" />
        </div>
        <div class="col-6 mt-2">
          <MazInput type="number" :placeholder="'Quantity'" v-model="product.quantity" />
        </div>
        <div class="col-6 mt-2">
          <MazSelect :placeholder="'Unit'" v-model="product.units" :options="units" search />
        </div>
        <div class="col-12 mt-2">
          <button class="btn btn-primary btn-block" @click="saveProduct">
            <i class="fas fa-save"></i> Save {{ product.id }}
          </button>
        </div>
        <div class="col-12 mt-2" v-if="isEditProduct">
          <button class="btn btn-danger btn-block" @click="deleteProduct(product.item_code)">
            <i class="fas fa-trash"></i> Delete Product {{ product.item_code }}
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal id="register-barcode-modal" hide-footer size="lm" :title="'Register Barcode'" no-close-on-backdrop>
      <div class="row">

        <div class="col-12 mt-2">
          <div v-if="videoInputDevices.length > 1" id="sourceSelectPanel">
            <label for="sourceSelect">Select Camera: </label>
            <select v-model="selectedDeviceId" id="sourceSelect">
              <option v-for="device in videoInputDevices" :key="device.deviceId" :value="device.deviceId">
                {{ device.label }}
              </option>
            </select>
          </div>

          <button @click="startScan" id="startButton" class="float-left">Start</button>
          <button @click="resetScan" id="resetScan" class="float-right">Stop</button>

          <div class="d-flex justify-content-center">
            <video id="video" width="300" height="200" style="border: 1px solid gray"></video>
          </div>
          <label>Result:</label>
          <pre><code> {{ scanResult }}</code></pre>

          <div>
            {{ barcode.item_code }}
          </div>
          <MazInput :placeholder="'Barcode'" v-model="barcode.barcode" />
        </div>

        <div class="col-12 mt-2">
          <v-select class="ml-auto" v-model="barcode.item_code" :options="prepared_products" label="item_name"
            :reduce="item => item.item_code" searchable filterable :filter="filterProduct">
            <template v-slot:option="option">
              <div>
                <span>{{ option.item_name }}</span><br>
                <span v-if="option.item_other_name" style="font-size: smaller; text-decoration: underline;">
                  {{ option.item_other_name }}
                </span>
              </div>
            </template>

            <template v-slot:selected-option="option">
              <div>
                <span style="display: block;">{{ option.item_name }}</span>
                <span v-if="option.item_other_name"
                  style="font-size: smaller; text-decoration: underline; display: block;">
                  {{ option.item_other_name }}
                </span>
              </div>
            </template>
          </v-select>
        </div>


        <div class="col-12 mt-2">
          <button class="btn btn-primary btn-block" @click="saveBarcode">
            <i class="fas fa-save"></i> Save
          </button>
        </div>

      </div>
    </b-modal>

    <b-modal id="import-item-modal" hide-footer size="xl" :title="'Import Item'" no-close-on-backdrop>

      <div class="col-12 mt-2">
        <draggable v-model="import_product_items" group="import_product_items" @start="drag = true" @end="drag = false">
          <div class="row mb-2" v-for="(item, index) in import_product_items" :key="index">
            <!-- <div class="col-1">
              <div class="m-4 p-2">
                <i class="fas fa-bars" style="color: grey"></i>
              </div>
            </div> -->

            <div class="col-3">
              <label class="text-primary">{{ index }}. Item</label>
              <v-select class="ml-auto" v-model="item.item_code" :options="prepared_products" label="item_name"
                :reduce="item => item.item_code" searchable filterable :filter="filterProduct">
                <template v-slot:option="option">
                  <div>
                    <span>{{ option.item_name }}</span><br>
                    <span v-if="option.item_other_name" style="font-size: smaller; text-decoration: underline;">
                      {{ option.item_other_name }}
                    </span>
                  </div>
                </template>

                <template v-slot:selected-option="option">
                  <div>
                    <span style="display: block;">{{ option.item_name }}</span>
                    <span v-if="option.item_other_name"
                      style="font-size: smaller; text-decoration: underline; display: block;">
                      {{ option.item_other_name }}
                    </span>
                  </div>
                </template>
              </v-select>
            </div>

            <!-- <div class="col-2">
              <label class="text-primary">Stock Level</label>
              <input type="number" class="form-control" v-model="item.stock_level">
            </div>
            <div class="col-2">
              <label class="text-primary">Brand</label>
              <input type="text" class="form-control" v-model="item.brand">
            </div>
            <div class="col-2">
              <label class="text-primary">Manufacturer</label>
              <input type="text" class="form-control" v-model="item.manufacturer">
            </div>
            <div class="col-2">
              <label class="text-primary">Distributior</label>
              <input type="text" class="form-control" v-model="item.distributior">
            </div> -->
            <div class="col-2">
              <label class="text-primary">TYPE</label>
              <input type="text" class="form-control" v-model="item.type">
            </div>
            <div class="col-1">
              <button class="btn btn-light mt-4" @click="import_product_items.splice(index, 1)"><i
                  class="fas fa-trash"></i></button>
            </div>

          </div>
        </draggable>
      </div>
      <div class="d-flex flex-row-reverse col-12 mt-2">
        <div class="p-3 col-3">
          <button class="btn btn-success btn-block" @click="saveImport">
            <i class="fas fa-save"></i> Save
          </button>
        </div>
      </div>
    </b-modal>
  </div>

</template>
<script>
import { db } from "../../db"
import Parser from "@json2csv/plainjs/dist/cjs/Parser"
import { BrowserMultiFormatReader, ZXing } from '@zxing/library'
import draggable from 'vuedraggable'
import firebase from "firebase/app"
export default {
  components: {
    draggable,
  },
  data() {
    return {
      keyword1: "",
      medicines: [],
      productsFileData: "",
      preparedProducts: {},
      product: {},
      isEditProduct: false,
      barcode: {},
      medicineColumns: [
        {
          label: "Item Code",
          field: "item_code",
        },
        {
          label: "Item Type",
          field: "item_type",
        },
        {
          label: "Item Name",
          field: "item_name",
        },
        {
          label: "Generic Name",
          field: "item_other_name",
        },
        {
          label: "Brand",
          field: "brand",
        },
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Manufacturer",
          field: "manufacturer",
        },
        {
          label: "Distributor",
          field: "distributor",
        },
        // {
        //   label: "Price",
        //   field: "price_inter",
        //   //thClass: 'text-right',
        //   tdClass: 'text-right',
        // },
        {
          label: "Stock Level",
          field: "stock_level",
          //thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: "Quantity",
          field: "quantity",
          //thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: "Order",
          field: "order",
        },
        {
          label: "Order status",
          field: "order_status",
        },
        {
          label: "Barcode",
          field: "barcode",
          formatFn: (row) => row.join(', '),
        }

      ],
      productsheetTitle: "PRODUCT",
      codeReader: null,               // ZXing code reader instance
      videoInputDevices: [],          // List of video input devices (cameras)
      selectedDeviceId: '',           // ID of the selected camera
      scanResult: '',                 // Result of QR/Barcode scan
      barcodes: [],                   // Data barcodes 
      stream: null,
      units: [
        { label: "Tablet", value: "Tablet" },
        { label: "Bottle", value: "Bottle" },
        { label: "Capsules", value: "Capsules" },
        { label: "Pcs", value: "Pcs" },
        { label: "Box", value: "Box" },
        { label: "Powder", value: "Powder" },
        { label: "Roll", value: "Roll" },
        { label: "Sachets", value: "Sachets" },
        { label: "Pair", value: "Pair" },
        { label: "Set", value: "Set" },
        { label: "Vials", value: "Vials" },
        { label: "Amp", value: "Amp" },
        { label: "Times", value: "Times" },
        { label: "", value: null }
      ],
      import_product_items: [],
    }
  },
  firestore() {
    return {
      medicines: db.collection("Product"),
    }
  },
  watch: {
    'scanResult': function (val) {
      this.barcode.barcode = val

    },
    'barcode.item_name': function (newItemName) {
      const selectedMedicine = this.medicines.find(medicine => medicine.item_name === newItemName)
      if (selectedMedicine) {
        this.barcode.item_code = selectedMedicine.item_code
        this.barcode.item_other_name = selectedMedicine.item_other_name || ''
      } else {
        this.barcode.item_code = ''
        this.barcode.item_other_name = ''
      }
    },
  },
  mounted() {
    //this.updateQuantity()
    //this.fixDistributorField()
  },
  methods: {
    deleteProduct(id) {
      this.$confirm("do you want to delete this product?").then(
        async () => {
          let loader = this.$loading.show({})
          await db.collection("Product").doc(id).delete()

          loader.hide()
          this.$bvModal.hide("product-modal")

          this.$alert(`product item ${id} was deleted.`, null, "success")
        }
      )
    },
    async mergeData() {
      console.log('Starting merging...')

      // try {
      //   let item_code_true = "M24 - 173";
      //   let item_name_true = "Mirax-M ( 10mg )";
      //   let item_code_false = "M24 - 168"; 

      //   const InvoiceRef = await db.collection("Invoice").get();
      //   let batch = db.batch();
      //   let batchSize = 0; 

      //   for (const doc of InvoiceRef.docs) {
      //     const data = doc.data();

      //     const updatedItems = data.product_items.map(item => {
      //       if (item.item_code === item_code_false) {
      //         return {
      //           ...item, 
      //           item_code: item_code_true,
      //           item: {
      //             ...item.item, 
      //             item_code: item_code_true,
      //             item_name: item_name_true,
      //             item_type: 'Medicine',
      //             price_insurance: 65,
      //             price_foreigner: 55,
      //             price_th: 48,
      //           }
      //         };
      //       }
      //       return item;
      //     });

      //     if (JSON.stringify(updatedItems) !== JSON.stringify(data.product_items)) {
      //       const docRef = db.collection("Invoice").doc(doc.id);
      //       batch.update(docRef, { product_items: updatedItems });
      //       batchSize++;
      //     }

      //     if (batchSize >= 500) {
      //       await batch.commit();
      //       console.log(`Committed batch of ${batchSize} updates`);
      //       batch = db.batch(); 
      //       batchSize = 0;
      //     }
      //   }

      //   // Commit any remaining updates
      //   if (batchSize > 0) {
      //     await batch.commit();
      //     console.log(`Final batch of ${batchSize} updates committed`);
      //   }

      //   console.log("All incorrect item_codes have been fixed.");
      // } catch (error) {
      //   console.error("Error during merging:", error);
      // }
      // แก้stock
      // แก้transfer
      // แก้Stocklog
      // แก้จำนวนStock
      try {
        let item_code_true = "M24 - 173"
        const InvoiceRef = await db.collection("Invoice").get()

        let invoicesWithItemCode = []

        for (const doc of InvoiceRef.docs) {
          const data = doc.data()

          // Check if any product_items contain the item_code
          const hasItem = data.product_items.some(item => item.item_code === item_code_true)

          if (hasItem) {
            invoicesWithItemCode.push(data.invoice_id)
          }
        }

        console.log("Invoices with item_code M24 - 173:", invoicesWithItemCode)
      } catch (error) {
        console.error("Error during checking:", error)
      }

    },
    async fixDistributorField() {
      let loader = this.$loading.show({})
      const productCollection = db.collection('Product')
      const batch = db.batch()

      try {
        // Fetch all products
        const productSnapshot = await productCollection.get()

        for (const productDoc of productSnapshot.docs) {
          const productData = productDoc.data()

          if (productData.distributior !== undefined) {
            // Move value from "distributior" to "distributor"
            batch.update(productDoc.ref, {
              distributor: productData.distributior, // Copy value
              distributior: firebase.firestore.FieldValue.delete() // Delete old field
            })
          }
        }

        // Commit batch updates
        await batch.commit()

        this.$alert("Field name fixed successfully!", null, "success")
      } catch (error) {
        this.$alert(`Error: ${error.message}`, null, "error")
      }

      loader.hide()
    },
    async updateQuantity() {
      let loader = this.$loading.show({})
      const productCollection = db.collection('Product')
      const stockCollection = db.collection('Stock')
      const batch = db.batch()

      try {
        // Fetch all products
        const productSnapshot = await productCollection.get()

        // Process each product
        for (const productDoc of productSnapshot.docs) {
          const productData = productDoc.data()
          const itemCode = productData.item_code

          if (!itemCode) continue // Skip if item_code is missing

          // Sum quantity from Stock collection where item_code matches
          const stockSnapshot = await stockCollection.where('item_code', '==', itemCode).get()
          const totalQuantity = stockSnapshot.docs.reduce((sum, stockDoc) => {
            return sum + (stockDoc.data().quantity || 0)
          }, 0)

          // Update product quantity in batch
          batch.update(productDoc.ref, { quantity: totalQuantity })
        }

        // Commit all updates in batch
        await batch.commit()

        this.$alert("Quantity updated successfully", null, "success")
      } catch (error) {
        this.$alert(`Error: ${error.message}`, null, "error")
      }

      loader.hide()
    },
    registerBarcode(params) {
      this.barcode = {}
      this.$bvModal.show("register-barcode-modal")
    },
    async saveBarcode() {
      let loader = this.$loading.show({})

      if (!this.barcode.item_code) {
        this.$alert("please enter item code", null, "error")
        loader.hide()
        return
      }
      const result = await db.collection('Product').doc(this.barcode.item_code).get()
      const data = result.data()
      if ('barcode' in data) {
        if (!data.barcode.includes(this.barcode.barcode)) {
          data.barcode.push(this.barcode.barcode)
        }

      }
      else {
        data.barcode = [this.barcode.barcode]

      }
      loader.hide()
      this.$alert("success", null, "success")
      this.$bvModal.hide("register-barcode-modal")
      try {
        await db.collection('Product').doc(this.barcode.item_code).set(data)

      } catch (error) {
        console.log(error)

      }
    },
    CSVToArray(strData, strDelimiter) {

      strDelimiter = (strDelimiter || ",")

      var objPattern = new RegExp(
        (
          // Delimiters.
          "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
          // Quoted fields.
          "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
          // Standard fields.
          "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi"
      )
      var arrData = [[]]
      var arrMatches = null
      while (arrMatches = objPattern.exec(strData)) {
        var strMatchedDelimiter = arrMatches[1]
        if (
          strMatchedDelimiter.length &&
          strMatchedDelimiter !== strDelimiter
        ) {
          arrData.push([])
        }
        var strMatchedValue;
        if (arrMatches[2]) {
          strMatchedValue = arrMatches[2].replace(
            new RegExp("\"\"", "g"),
            "\""
          )
        } else {
          strMatchedValue = arrMatches[3]
        }
        arrData[arrData.length - 1].push(strMatchedValue)
      }
      return (arrData)
    },
    csvArrayToObj(csvData) {
      return csvData
        .map((csvLine, csvIndex) => {
          if (csvIndex === 0 || !csvLine.length) return null // skip header and empty lines
          return csvLine.reduce((a, v, i) => ({ ...a, [csvData[0][i]]: v }), {})
        })
        .filter((filter) => !!filter) //filter empty lines
    },
    exportCsv(datas, name) {
      try {
        const selectedData = datas.map((data) => ({
          item_code: data.item_code,
          item_name: data.item_name,
          generic_name: data.item_other_name,
          item_type: data.item_type,
          brand: data.brand,
          manufacturer: data.manufacturer,
          distributor: data.distributor,
          stock_level: data.stock_level,
          quantity: data.quantity,
          units: data.units,
          order: data.order,
          order_status: data.order_status,
        }))
        const parser = new Parser()
        const myData = JSON.parse(JSON.stringify(selectedData))
        const csv = parser.parse(myData)
        
        const bom = "\uFEFF";
        const csvWithBom = bom + csv;

        const anchor = document.createElement("a")
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csvWithBom)
        anchor.target = "_blank"
        anchor.download = "data_" + name + ".csv"
        anchor.click()
        anchor.remove()
      } catch (err) {
        console.error(err)
      }
    },
    async uploadCsv() {

      // let promise = new Promise((resolve, reject) => {
      //   const reader = new FileReader()
      //   const fileSelector = document.getElementById("products-csv")
      //   const file = fileSelector.files[0]
      //   reader.onload = (e) => {
      //     resolve((this.productsFileData = reader.result))
      //   }
      //   reader.onerror = reject
      //   reader.readAsText(file)
      // })
      // promise.then(async (result) => {
      //   this.preparedProducts = this.CSVToArray(this.productsFileData)
      //   this.preparedProducts = this.csvArrayToObj(this.preparedProducts)

      //   for (const product of this.preparedProducts) {
      //     const quantity = Number(product.Quantity) || 0
      //     if (quantity <= 0) return

      //     const result = await db.collection('Product').doc(product.Item_code).get()
      //     const data = result.data()
      //     const updatedQuantity = (Number(data.quantity) || 0) + quantity
      //     const payload = {
      //       ...data,
      //       quantity: updatedQuantity
      //     }
      //     try {
      //       await db.collection('Product').doc(product.Item_code).set(payload)
      //     } catch (error) {
      //       console.log(error)
      //     }

      //   }

      // })
      let promise = new Promise((resolve, reject) => {
        const reader = new FileReader()
        const fileSelector = document.getElementById("products-csv")
        const file = fileSelector.files[0]
        reader.onload = (e) => {
          resolve((this.productsFileData = reader.result))
        }
        reader.onerror = reject
        reader.readAsText(file)
        fileSelector.value = ""
      })
      promise.then(async (result) => {
        this.preparedProducts = this.CSVToArray(this.productsFileData)
        this.preparedProducts = this.csvArrayToObj(this.preparedProducts)
        this.$bvModal.show("import-item-modal")
        this.import_product_items = this.preparedProducts
      })


    },
    async saveImport() {
      let loader = this.$loading.show({})
      const batch = db.batch()
      const productCollection = db.collection('Product')
      try {
        this.import_product_items.forEach((product) => {
          const productRef = productCollection.doc(product.item_code)

          batch.set(productRef, {
            // stock_level: Number(product.stock_level),
            // brand: product.brand,
            // manufacturer: product.manufacturer,
            // distributior: product.distributior,
            type: product.type
          }, { merge: true })
        })

        await batch.commit()

        this.$alert("Import successful", null, "success")
        this.$bvModal.hide("import-item-modal")
      } catch (error) {
        this.$alert(`Error: ${error.message}`, null, "error")
      }

      loader.hide()
      this.$alert("success", null, "success")
      this.$bvModal.hide("import-item-modal")
    },
    showProduct(params) {
      if (params) {
        //console.log({ params })
        this.product = {
          id: params.row.id,
          ...params.row,
        };
        this.isEditProduct = true
      } else {
        this.isEditProduct = false
        this.product = {}
      }
      this.$bvModal.show("product-modal")
    },
    async saveProduct() {
      let loader = this.$loading.show({})
      if (!this.product.item_code || !this.product.item_name) {
        this.$alert("please enter item code and item name", null, "error")
        loader.hide()
      }

      try {
        let product = { ...this.product }
        product.id = product.id || null
        product.cost = product.cost || null
        product.price_insurance = product.price_insurance || null
        product.price_th = product.price_th || null
        product.price_inter = product.price_inter || null
        product.item_other_name = product.item_other_name || null
        product.item_type = product.item_type || null
        product.quantity = product.quantity || null

        await db.collection("Product").doc(product.item_code).set(product)
        loader.hide()
        this.$alert("success", null, "success")
      } catch (error) {
        this.$alert(`error ${error}`, null, "error")
        loader.hide()
      }
    },
    async initializeCodeReader() {
      try {
        this.codeReader = new BrowserMultiFormatReader()
        //console.log('ZXing code reader initialized')

        // Get video input devices
        const devices = await this.codeReader.listVideoInputDevices()
        this.videoInputDevices = devices

        // Set the default selected device to the first camera
        if (devices.length > 0) {
          this.selectedDeviceId = devices[0].deviceId
        }

        //console.log('Video input devices:', devices)
      } catch (err) {
        console.error('Error initializing the code reader:', err)
      }
    },
    // Start scanning for QR code/barcode from the selected camera
    startScan() {
      this.requestCameraPermission()
      this.initializeCodeReader()
      if (this.selectedDeviceId) {
        const constraints = {
          video: {
            deviceId: { exact: this.selectedDeviceId }, // Use the selected device
            facingMode: { ideal: 'environment' } // Prefer rear camera on mobile
          }
        }
        this.codeReader.decodeFromVideoDevice(this.selectedDeviceId, 'video', (result, err) => {
          if (result) {
            this.scanResult = result.text
            this.resetScan()
          }
        })
      } else {
        console.error('No camera selected')
      }
    },
    // Reset the scanner and clear the result
    resetScan() {
      this.beforeDestroy()
      //this.scanResult = ''
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop())
        this.stream = null;
      }

    },
    async requestCameraPermission() {
      try {
        // Request camera access explicitly
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: { ideal: 'environment' } // Rear camera
          }
        })
      } catch (error) {
        alert('Please allow camera access in your device settings or browser.')
      }
    },
    beforeDestroy() {
      if (this.codeReader) {
        this.codeReader.reset() // Reset the code reader when the component is destroyed
      }
    },
    filterProduct(options, search) {
      return options.filter(option => {
        const label = option.item_name + ' ' + option.item_other_name
        return this.filterProductBy(option, label, search)
      });
    },
    filterProductBy(_, label, search) {
      return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
    },
    getOrderStatus(order) {
      if (order === null || order === undefined || order === '') {
        return '';
      }
      return order > 0 ? "Order" : "Ok";
    },
  },
  computed: {

    prepared_products() {
      return this.medicines.map((i) => {
        return {
          id: i.id,
          item_name: i.item_name || null,
          item_other_name: i.item_other_name || null,
          item_type: i.item_type || null,
          item_code: i.item_code || null,
          price_th: Number(i.price_th) || null,
          price_inter: Number(i.price_inter) || null,
          price_insurance: Number(i.price_insurance) || null,
        }
      })
    },
    processedMedicines() {
      return this.medicines.map(med => {
        const stockLevel = med.stock_level || 0
        const quantity = med.quantity || 0
        const order = (stockLevel - quantity) * 2 || null
        const order_status = (order == null) ? '' : (order > 0 ? 'Order' : 'Okay')

        return {
          ...med,
          order,
          order_status,
        }
      })
    }




  },
}
</script>
<style lang="scss">
.Order {
  font-weight: bold;
  background-color: #f5c6cb;
  color: #dc3545;
  padding: 3px;
  border: 3px solid #f5c6cb;
  border-radius: 0.5em;
}

.Ok {
  font-weight: bold;
  background-color: white;
  color: #274e13ff;
  padding: 3px;
  border: 3px solid #274e13ff;
  border-radius: 0.5em;
}
</style>