<template>
  <div style="padding: 1em;">
    <b-nav tabs justified>
      <b-nav-item :active="this.$route.name === 'General Info'"
        :to="{ name: 'General Info', params: { id: this.$route.params.id } }">General Info</b-nav-item>
      <b-nav-item :active="this.$route.name === 'Visit Summary' ||
        $route.meta.visit_active === true
        " :to="{ name: 'Visit Summary', params: { id: this.$route.params.id } }">Visit Summary</b-nav-item>
      <b-nav-item :active="this.$route.name === 'Patient Billing Summary'"
        :to="{ name: 'Patient Billing Summary', params: { id: this.$route.params.id } }">Billing</b-nav-item>
      <b-nav-item disabled style="opacity: 0.5;" @click="InvestSumClick" :active="this.$route.name === 'Investigation Summary' ||
        $route.meta.invest_active === true
        " :to="{
          name: 'Investigation Summary',
          params: { id: this.$route.params.id }
        }">Investigation Summary</b-nav-item>
      <b-nav-item disabled style="opacity: 0.5;" :active="this.$route.name === 'Admission Summary'" :to="{
        name: 'Admission Summary',
        params: { id: this.$route.params.id }
      }">Admission Summary</b-nav-item>
      <b-nav-item disabled style="opacity: 0.5;" :active="this.$route.name === 'Operation Summary'" :to="{
        name: 'Operation Summary',
        params: { id: this.$route.params.id }
      }">Operation Summary</b-nav-item>
      <b-nav-item disabled style="opacity: 0.5;" :active="this.$route.name === 'Documents'"
        :to="{ name: 'Documents', params: { id: this.$route.params.id } }">Documents</b-nav-item>
      <b-nav-item disabled style="opacity: 0.5;" @click="appointmentClick" :active="this.$route.name === 'Patient Appointments' ||
        $route.meta.appt_active === true
        " :to="{
          name: 'Patient Appointments',
          params: { id: this.$route.params.id }
        }">Appointments</b-nav-item>
      <b-nav-item disabled style="opacity: 0.5;" :active="this.$route.name === 'Medicines'"
        :to="{ name: 'Medicines', params: { id: this.$route.params.id } }">Medicines</b-nav-item>
      <b-nav-item disabled style="opacity: 0.5;" :active="this.$route.name === 'Others'"
        :to="{ name: 'Others', params: { id: this.$route.params.id } }">Others</b-nav-item>
    </b-nav>
    <div class="card">
      <div class="card-body">
        <router-view class="mt-2" :patient="patient"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { db } from "../../db";
import moment from "moment";
export default {
  data() {
    return {
      patient: {}
    };
  },
  async created() {
    let loader = this.$loading.show({});

    let patientId = this.$route.params.id;
    let patientRef = await db
      .collection("Patient")
      .doc(patientId)
      .get();

    let patient = {
      ...patientRef.data()
    };
    
    if (patient.travel_insurance) {
      let pinRef = (
        await db
          .collection("Insurance")
          .doc(patient.travel_insurance.id)
          .get()
      ).data();

      patient.travel_insurance = {
        id: patient.travel_insurance.id,
        ...pinRef
      }
    }

    if (patient.allergies) {
      patient.allergies = await Promise.all(
        patient.allergies.map(async item => {
          let allergy = await db
            .collection("Allergy")
            .doc(item.id)
            .get();
          return {
            id: item.id,
            ...allergy.data()
          };
        })
      );
    }
    if (patient.other_allergies) {
      patient.other_allergies = await Promise.all(
        patient.other_allergies.map(async item => {
          let allergy = await db
            .collection("Allergy")
            .doc(item.id)
            .get();
          return {
            id: item.id,
            ...allergy.data()
          };
        })
      );
    }

    if (patient.date_of_birth) {
      try {
        let now = moment();
        let bd = moment(patient.date_of_birth.toDate());
        let diff = now.diff(bd, "months");
        let day_diff = now.diff(bd, "days");
        patient.age = `${Math.floor(diff / 12)}y${diff % 12 !== 0 ? diff % 12 + 'm' : ''}`;
        patient.ageMonth = diff; //use for vital sign calculate
        patient.ageDay = day_diff; //use for vital sign calculate
      } catch (error) {
        patient.age = "please edit date of birth";
      }
    }
    // if (patient.date_of_birth) {
    //   patient.date_of_birth = moment(patient.date_of_birth.toDate()).format('DD/MM/YYYY')
    // }
    // if (patient.departure_date) {
    //   patient.departure_date = moment(patient.departure_date.toDate()).format('DD/MM/YYYY')
    // }
    if (!(patient.registerLog)) {
      patient.registerLog = [];
    }
    this.patient = patient;
    loader.hide();
  },
  methods: {
    InvestSumClick(params) {
      this.$router.push({
        name: "List of all tests",
        params: {
          id: this.$route.params.id
        }
      });
    },
    appointmentClick(params) {
      this.$router.push({
        name: "Up Coming Appointments",
        params: {
          id: this.$route.params.id
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.card {
  min-height: 100vh;
}
</style>
