<template>
    <div class="card shadow News">
        <div class="end">
            V 1.0.0
        </div>
        <div class="card-body">
            <div class="row" style="margin-top: -20px;">
                <div class="col-10 text-center Group-Head">
                    <button type="button" style="margin: 1em;" @click="goHome">
                        Home
                    </button>
                    <button type="button" style="margin: 1em;" @click="showAnnouncementIndex"
                        :class="{ active: isAnnouncementIndexVisible }">
                        Announcement
                    </button>
                    <button type="button" style="margin: 1em;" @click="showMeetingReport"
                        :class="{ active: isMeetingReportVisible }">
                        Meeting
                    </button>
                    <button type="button" style="margin: 1em;" @click="showSchedule"
                        :class="{ active: isScheduleVisible }">
                        Schedule
                    </button>
                    <button type="button" style="margin: 1em;" @click="showGuideline"
                        :class="{ active: isGuidelineVisible }">
                        Guideline & Training
                    </button>
                    <button type="button" style="margin: 1em;" @click="showPolicy" :class="{ active: isPolicyVisible }">
                        Policy
                    </button>
                    <button v-if="shouldShowManageButton" type="button" style="margin: 1em;" @click="showManageIndex"
                        :class="{ active: isManageIndexVisible }">
                        Manage
                    </button>
                </div>
            </div>
            <AnnouncementIndex v-if="isAnnouncementIndexVisible" :selectedDepartment="selectedOption" />
            <ManageIndex v-if="isManageIndexVisible" />
            <MeetingReport v-if="isMeetingReportVisible" />
            <!-- <Schedule v-if="isScheduleVisible" /> -->
        </div>

    </div>
</template>

<script>
import { db, storage } from '../../db'
import AnnouncementIndex from "./AnnouncementIndex.vue";
import ManageIndex from "./ManageIndex.vue"
import MeetingReport from "./MeetingIndex.vue"
//import Schedule from "./Meeting/card-test.vue"
export default {
    components: {
        AnnouncementIndex,
        ManageIndex,
        MeetingReport,
        // Schedule,
    },
    data() {
        return {
            isAnnouncementIndexVisible: true,
            isManageIndexVisible: false,
            isMeetingReportVisible: false,
            isScheduleVisible: false,
            isGuidelineVisible: false,
            isPolicyVisible: false,
            shouldShowManageButton: false, // เงื่อนไขเริ่มต้น
            selectedOption: 'All',
            devOptions: [
                { value: 'Management', label: 'Management' },
                { value: 'Account', label: 'Account' },
                { value: 'Development', label: 'Development' },
                { value: 'AR', label: 'AR' },
                { value: 'Insurance', label: 'Insurance' },
                { value: 'Doctor', label: 'Doctor' },
                { value: 'Nurse', label: 'Nurse' },
                { value: 'Public Health', label: 'Public Health' },
                { value: 'Med Tech', label: 'Med Tech' },
                { value: 'Wa', label: 'Wa' },
                { value: 'Driver', label: 'Driver' },
                { value: 'Housekeeper', label: 'Housekeeper' },
                { value: 'All', label: 'All' },
            ],
            userPermission: null
        };
    },
    mounted() {
        this.fetchUser()
        this.shouldShowManageButton = this.checkUserPermission(); // ตรวจสอบสิทธิ์ผู้ใช้
    },
    computed: {
    },
    methods: {
        fetchUser() {
            db.collection("News_Permissions")
                .where("Name", "==", this.$store.getters.user.displayName) // ใช้ "==" เท่านั้น
                .get()
                .then((querySnapshot) => {
                    if (querySnapshot.empty) {
                        console.log("No matching permissions found.");
                        this.userPermission = null; // กำหนดให้เป็น null ถ้าไม่มีข้อมูล
                    } else {
                        this.userPermission = querySnapshot.docs[0].data(); // ดึงข้อมูลจากเอกสารแรกที่เจอ
                    }
                })
                .catch(error => {
                    console.error("Error getting documents:", error);
                });
        },

        goHome() {
            this.$router.push('/');
        },
        showAnnouncementIndex() {
            this.isAnnouncementIndexVisible = true;
            this.isManageIndexVisible = false;
            this.isMeetingReportVisible = false;
            this.isScheduleVisible = false;
            this.isGuidelineVisible = false;
            this.isPolicyVisible = false;
        },
        showManageIndex() {
            this.isManageIndexVisible = true;
            this.isAnnouncementIndexVisible = false;
            this.isMeetingReportVisible = false;
            this.isScheduleVisible = false;
            this.isGuidelineVisible = false;
            this.isPolicyVisible = false;
        },
        showMeetingReport() {
            this.isMeetingReportVisible = true;
            this.isAnnouncementIndexVisible = false;
            this.isManageIndexVisible = false;
            this.isScheduleVisible = false;
            this.isGuidelineVisible = false;
            this.isPolicyVisible = false;
        },
        showSchedule() {
            this.isScheduleVisible = true;
            this.isAnnouncementIndexVisible = false;
            this.isManageIndexVisible = false;
            this.isMeetingReportVisible = false;
            this.isGuidelineVisible = false;
            this.isPolicyVisible = false;
        },
        showGuideline() {
            this.isGuidelineVisible = true;
            this.isAnnouncementIndexVisible = false;
            this.isManageIndexVisible = false;
            this.isMeetingReportVisible = false;
            this.isScheduleVisible = false;
            this.isPolicyVisible = false;
        },
        showPolicy() {
            this.isPolicyVisible = true;
            this.isAnnouncementIndexVisible = false;
            this.isManageIndexVisible = false;
            this.isMeetingReportVisible = false;
            this.isScheduleVisible = false;
            this.isGuidelineVisible = false;
        },
        checkUserPermission() {
            let plainPermission = Object.assign({}, this.userPermission); // แปลงเป็น plain object
            if (plainPermission) {
                return true;
            }
            return false;
        }
    },
};

</script>

<style lang="scss" scoped>

.News {
    font-family: 'Arial';
    min-height: 800px;
    background: #2e2e44;
    .Group-Head {
        display: flex;
        align-items: center;
        /* จัดให้อยู่ตรงกลางแนวตั้ง */
        justify-content: flex-start;
        /* จัดให้ Announcement อยู่หน้าสุด */
        flex-wrap: nowrap;
        /* ไม่ให้ขึ้นบรรทัดใหม่ */
        gap: 10px;
        /* กำหนดระยะห่างระหว่างปุ่ม */
        margin-top: 20px;

        button {
            background: #2d2f41;
            background: linear-gradient(#3b435c, #01183585);
            border: 1px solid #0ee2d8;
            border-radius: 5px;
            color: #ffffff;
            font-family: arial;
            font-size: 13px;
            font-weight: 400;
            height: 40px;
            width: 200px;
            margin: 0;
            white-space: nowrap;
            /* ป้องกันปุ่มขึ้นบรรทัดใหม่ */

            &:focus {
                animation: glow 800ms ease-out infinite alternate;
                color: #efe;
                outline: none;
            }
        }
    }

    /* ใช้สำหรับการกำหนดคลาส active สำหรับ b-dropdown */
    ::v-deep(.b-dropdown.active .btn) {
        background: #0ee2d8 !important;
        color: black !important;
        font-weight: bold;
    }

    /* กำหนดรูปแบบการแสดงของปุ่มภายใน dropdown */
    ::v-deep(.b-dropdown .btn) {
        background: #2d2f41;
        background: linear-gradient(#3b435c, #01183585);
        border: 1px solid #0ee2d8;
        border-radius: 5px;
        color: #ffffff;
        font-family: Arial, sans-serif;
        font-size: 13px;
        font-weight: 400;
        height: 40px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* การกำหนดการแสดงของปุ่มลูกศร (toggle) */
    ::v-deep(.b-dropdown .dropdown-toggle) {
        width: 30px;
        height: 40px;
        background: #2d2f41;
        border: 1px solid #0ee2d8;
        border-left: none;
        border-radius: 0 5px 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* สีของลูกศร */
    ::v-deep(.b-dropdown .dropdown-toggle::after) {
        color: #050505;
    }

    /* ปรับให้ dropdown-item มีรูปแบบที่เหมาะสม */
    ::v-deep(.b-dropdown-item) {
        color: #ffffff;
        background-color: #2d2f41;
        border: 1px solid #0ee2d8;
    }

    ::v-deep(.b-dropdown-item:hover) {
        background-color: #0ee2d8;
        color: black;
    }

    /* ปรับปรุงรูปแบบเมื่อมีการเลือก */
    ::v-deep(.b-dropdown .btn.active) {
        background-color: #0ee2d8 !important;
        color: black !important;
        font-weight: bold;
    }

    // Animation
    @keyframes glow {

        0% {

            box-shadow: 0 5px 25px rgba(5, 140, 202, 0.8);

        }

        100% {

            box-shadow: 0 5px 25px rgba(5, 140, 202, 1);

        }
    }

    .end {
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        /* เพิ่มระยะห่างเล็กน้อย */
    }

    button.active {
        background: #0ee2d8 !important;
        color: black !important;
        font-weight: bold;
    }
}
</style>