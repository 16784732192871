<template>
  <div>
    <div class="container-fluid">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="box-bookMark-Billing d-flex align-items-center justify-content-center">
              <h3 class="box-bookMark-Billing">Billing</h3>
            </div>
          
          <!--<div class="d-flex mt-2 mb-2">
           <div class="mr-auto">
              <h4 class="text-primary">Billing</h4>
            </div>-->
            <div class="ml-auto d-flex align-items-center justify-content-center">
            <div class="timer">
              <h4 class="text-primary text-center">
                {{ timer | moment("dddd") }}
              </h4>
              <h3 class="text-primary text-center">
                {{ timer | moment("HH:mm A") }}
              </h3>
              <div class="text-primary text-center">
                {{ timer | moment("DD/MMMM/YYYY") }}
              </div>
            </div></div>
          <!--</div>-->
        </div>
          <b-nav class="mb-2" tabs>
            <b-nav-item :active="$route.matched.some(route => route.path.includes(menu.path_name))" v-for="menu in menus" :key="menu.path_name" :to="`/billing/${menu.path_name}`">
              {{ menu.name }}
            </b-nav-item>
          </b-nav>

          <hr />
          <date-filter v-model="filter"></date-filter>
          <hr />

          <router-view :filter="filter"></router-view>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: ["patient", "nursing"],
  data() {
    return {
      menus: [
        { name: 'DASHBOARD', path_name: 'billing-dashboard' },
        { name: 'SUMMARY', path_name: 'billing-summary' },       
      ],
      timer: new Date(),
      filter: {
        from: this.$moment().format('DD/MM/YYYY'),
        to: this.$moment().format('DD/MM/YYYY'),
        period: { name: 'Day', from: this.$moment().format('DD/MM/YYYY'), to: this.$moment().format('DD/MM/YYYY'),
        income: 'Daily',
        total: 'TODAY\'S' },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.timer {
  border-radius: 0.25em;
  padding: 10px;
  min-width: 220px;
  height: 120px;
  box-shadow: 2px 2px 10px #bfbfbf;
}

.card {
  min-height: 800px;
}
.box-bookMark-Billing {
  width: 200px;
  height: 100px;
  background-color: #2F6EBA;
  margin-top: -26px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-bookMark-Billing h3{
  color: #ffffff;
}
</style>