<template>
  <div class="ManageNewIndex">
    <b-card class="ManageNewIndex">
      <div class="row">
        <div class="col-1">
          <h2 style="margin-top: -7px;">News</h2>
        </div>
        <div class="col-3">
          <div class="mb-1">
            <input type="text" class="form-control" v-model="searchQuery" placeholder="🔍  Search" />
          </div>
        </div>
        <div class="col-4">
          <button class="btn btn-primary" @click="openAddModal">Add News</button>
        </div>
        <div class="col-4">
          <nav class="d-flex justify-content-end w-100 ml-2">
            <ul class="pagination">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="prevPage">Previous</button>
              </li>
              <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: page === currentPage }">
                <button class="page-link" @click="currentPage = page">{{ page }}</button>
              </li>
              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <button class="page-link" @click="nextPage">Next</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="row">
        <!-- Table -->
        <table class="table table-striped table-bordered">
          <thead class="">
            <tr>
              <th></th>
              <th>#</th>
              <th>Category</th>
              <th>Title</th>
              <th>Subtitle</th>
              <th>Department</th>
              <th>Author</th>
              <th>CreatedAt</th>
              <th>UpdatedAt</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(News, index) in paginatedNews" :key="News.id" @click="editNews(News)" style="cursor: pointer;">
              <td>
                <!-- <input type="checkbox" v-model="News.Hold" :disabled=false> -->
                <i class="fa fa-tag" aria-hidden="true" v-if="News.Hold === true" style="color:forestgreen;" />
              </td>
              <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
              <td>{{ News.Category }}</td>
              <td>{{ News.Title }}</td>
              <td>{{ News.Subtitle }}</td>
              <td>
                <div class="gap-2 text-center">
                  <button v-for="(dept, index) in News.Department" :key="index" class="btn btn-sm btn-primary mx-1">
                    {{ dept.label }} <!-- แสดง label เป็นข้อความของปุ่ม -->
                  </button>
                </div>
              </td>
              <td>{{ News.Author }}</td>
              <td>{{ News.createdAt }}</td>
              <td>{{ News.updatedAt }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <!---------------------------------------------- Add Data Modal ---------------------------------------------------------------------------->
    <div v-if="showModal" class="modal" tabindex="-1">
      <div class="modal-content">
        <h4 class="modal-title modal-header" style="margin-top: -15px;">
          {{ isEditing ? 'Edit News' : 'Add News' }}
          <button v-if="isEditing" class="btn btn-danger delete-btn" @click="openConfirmDelete" :disabled="loading">
            Delete
          </button>
        </h4>
        <!-- Category -->
        <div class="form-group">

          <div class="row">
            <div class="col-1 d-flex align-items-center">
              <label>Category:</label>
            </div>
            <div class="col-2">
              <select class="form-control" v-model="newsForm.Category">
                <option v-for="option in PartOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
            <div class="col-1 d-flex align-items-center">
              <label>Group:</label>
            </div>
            <div class="col-3">
              <select class="form-control" v-model="newsForm.Group">
                <option v-for="option in GroupOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
            <div class="col-1 d-flex align-items-center">
              <label>Priority:</label>
            </div>
            <div class="col-2">

              <select id="priorityLevel" class="form-control" v-model="newsForm.Level">
                <option v-for="option in LevelOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
            <div class="col-2" v-if="newsForm.Category === 'Main'">
              <input class="form-check-input" type="checkbox" v-model="newsForm.Hold"
                :disabled="TotalHold >= 10 && !newsForm.Hold">
              <p>Hold</p>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row ">
            <div class="col-1">
              <label>Title:</label>
            </div>
            <div class="col-11">
              <input type="text" class="form-control" v-model="newsForm.Title" />
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row ">
            <div class="col-1">
              <label>Subtitle:</label>
            </div>
            <div class="col-11">
              <input type="text" class="form-control" v-model="newsForm.Subtitle" />
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Content:</label>
          <quill-editor v-model="newsForm.Content" :options="editorOptions" />
        </div>

        <div class="form-group row">
          <div class="col d-flex align-items-center">
            <label class="col-4">Images:</label>
            <div class="col-8">
              <b-form-file v-model="newsForm.Images" accept="image/*" />
            </div>
          </div>

          <div class="col d-flex align-items-center">
            <label class="col-4">File:</label>
            <div class="col-8">
              <b-form-file v-model="newsForm.File" accept=".docx,.xlsx,.pdf,.pptx" />
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col d-flex align-items-center">
            <label class="col-4">Link:</label>
            <div class="col-8">
              <input type="text" class="form-control" v-model="newsForm.Link" />
            </div>
          </div>
          <div class="col d-flex align-items-center">
            <label class="col-4">Start Showing:</label>
            <div class="col-8">
              <MazPicker v-model="newsForm.Showing" :format="`YYYY-MM-DD`" :formatted="`YYYY-MM-DD`"
                placeholder="Showing date" />
              <!-- <input id="dateInput" type="date" class="form-control" v-model="newsForm.Showing" /> -->
            </div>
          </div>
        </div>


        <div class="form-group row">
          <div class="col d-flex align-items-center">
            <label class="col-4">Department:</label>
            <div class="col-8">
              <multiselect v-model="newsForm.Department" :options="devOptions" multiple track-by="value" label="label"
                :allow-empty="false" :close-on-select="false" class="w-100"></multiselect>
            </div>
          </div>
          <div class="col d-flex align-items-center">
            <label class="col-4">Author:</label>
            <div class="col-8">
              {{ isEditing ? newsForm.Author : AuthorLogin }}
            </div>
          </div>
        </div>
        <!-- Show loading spinner or progress bar when saving -->
        <div v-if="loading" class="d-flex justify-content-center my-3">
          <!-- Simple loading spinner -->
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <!-- Modal Footer -->
        <div class="modal-footer">
          <button class="btn btn-success" @click="openConfirmModal" :disabled="loading">Save</button>
          <button class="btn btn-secondary" @click="closeModal" :disabled="loading">Cancel</button>
        </div>
      </div>
    </div>

    <!-- Modal HTML -->
    <div class="modal-confirm" v-if="showConfirmModal" tabindex="-1">
      <div class="modal-confirm-content">
        <div class="modal-confirm-header flex-column">
          <div class="icon-box" style="border: 3px solid dodgerblue;">
            <div class="succes succes-animation icon-top" style="color: dodgerblue;">
              <i class="fa fa-plus"></i>
            </div>
          </div>
          <h4 class="modal-title w-100">Are you sure?</h4>
        </div>
        <div class="modal-confirm-body">
          <p>Do you want to save this news?</p>
        </div>
        <div class="modal-confirm-footer justify-content-center">
          <button class="btn btn-info" @click="confirmSaveNews">Save</button>
          <button class="btn btn-secondary" @click="closeConfirmModal" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>

    <!-- Modal Confirm Delete -->
    <div class="modal-confirm" v-if="showConfirmDeleteModal" tabindex="-1">
      <div class="modal-confirm-content">
        <div class="modal-confirm-header flex-column">
          <div class="icon-box">
            <i class="fa fa-trash" style="color: brown;"></i>
          </div>
          <h4 class="modal-title w-100">Confirm Delete</h4>
        </div>
        <div class="modal-confirm-body">
          <p>Are you sure you want to delete this news? This action cannot be undone.</p>
        </div>
        <div class="modal-confirm-footer justify-content-center">
          <button class="btn btn-danger" @click="confirmDeleteNews">Delete</button>
          <button class="btn btn-secondary" @click="closeConfirmDeleteModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { db, storage } from '../../../db'
import firebase from 'firebase/app';
import moment from 'moment'
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.snow.css' // for snow theme

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      showConfirmDeleteModal: false,
      showConfirmModal: false,
      loading: false,
      isEditing: false,
      searchQuery: "",
      showModal: false,
      TotalHold: 0,
      newsForm: {
        id: null,
        Category: "",
        Group: "",
        Hold: false,
        Title: "",
        Subtitle: "",
        Images: null,
        ImagesOld: "",
        File: null,
        FileOld: "",
        Content: "",
        Showing: "",
        Link: null,
        Department: [],
        Author: "",
        Level: "",
        createdAt: "",
        updatedAt: "",
      },
      AuthorOptions: [],
      News: [],
      currentPage: 1,
      itemsPerPage: 12,
      PartOptions: [
        { value: 'Main', label: 'Main' },
        { value: 'MainSub', label: 'MainSub' },
        { value: 'Article', label: 'Article' },
      ],
      GroupOptions: [
        { value: "Company", label: "Company" },
        { value: "Human Resources", label: "Human Resources" },
        { value: "Financial", label: "Financial" },
        { value: "Meeting", label: "Meeting" },
        { value: "Employee Activities", label: "Employee Activities" },
        { value: "Project or New Initiative", label: "Project or New Initiative" },
        { value: "Internal Communication", label: "Internal Communication" },
        { value: "Safety", label: "Safety" },
        { value: "Training and Development", label: "Training and Development" },
        { value: "Health and Welfare", label: "Health and Welfare" },
      ],
      devOptions: [
        { value: 'Management', label: 'Management' },
        { value: 'Account', label: 'Account' },
        { value: 'Development', label: 'Development' },
        { value: 'AR', label: 'AR' },
        { value: 'Insurance', label: 'Insurance' },
        { value: 'Doctor', label: 'Doctor' },
        { value: 'Nurse', label: 'Nurse' },
        { value: 'Public Health', label: 'Public Health' },
        { value: 'Med Tech', label: 'Med Tech' },
        { value: 'Wa', label: 'Wa' },
        { value: 'Driver', label: 'Driver' },
        { value: 'Housekeeper', label: 'Housekeeper' },
        { value: 'Public', label: 'Public' },
        { value: 'All', label: 'All' },

      ],
      LevelOptions: [
        { value: 'low', label: 'Low' },
        { value: 'medium', label: 'Medium' },
        { value: 'high', label: 'High' }
      ],
      editorContent: '',
      editorOptions: {
        theme: 'snow',
      }
    }
  },
  mounted() {
    this.fetchNews()
  },

  computed: {
    AuthorLogin() {
      const userName = this.$store.getters.user.displayName;
      return userName;
    },
    filteredUNews() {
      if (!this.searchQuery) return this.News;
      return this.News.filter((News) => {
        const category = News.Category ? News.Category.toLowerCase() : '';
        const title = News.Title ? News.Title.toLowerCase() : '';
        const subtitle = News.Subtitle ? News.Subtitle.toLowerCase() : '';
        const department = News.Department ? News.Department.map(dept => dept.label.toLowerCase()).join(' ') : '';
        const author = News.Author ? News.Author.toLowerCase() : '';

        const query = this.searchQuery.toLowerCase();

        return (
          category.includes(query) ||
          title.includes(query) ||
          subtitle.includes(query) ||
          department.includes(query) ||
          author.includes(query)
        );
      });
    },
    totalPages() {
      return Math.ceil(this.filteredUNews.length / this.itemsPerPage);
    },
    paginatedNews() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.filteredUNews.slice(start, start + this.itemsPerPage);
    },
  },
  watch: {
    searchQuery() {
      this.currentPage = 1;
    },
    News: {
      handler() {
        this.currentPage = 1;
      },
      deep: true,
    },
    // 'newsForm.Category'(newValue) {
    // if (newValue === 'main') {
    //   console.log("Category ถูกเปลี่ยนเป็น main แล้ว!");

    // }
    // }

  },
  methods: {
    fetchNews() {
      db.collection("Ft_announcement")
        .orderBy('createdAt', 'desc')
        .get()
        .then((querySnapshot) => {
          this.News = [];
          this.TotalHold = 0; // ตัวแปรเก็บผลรวมของ Hold
          querySnapshot.forEach((doc) => {
            let newsData = doc.data();

            // แปลง createdAt เป็น "YYYY-MM-DD HH:mm"
            if (newsData.createdAt && typeof newsData.createdAt.toDate === "function") {
              newsData.createdAt = moment(newsData.createdAt.toDate()).format("YYYY-MM-DD HH:mm");
            }

            // แปลง Showing เป็น "YYYY-MM-DD"
            if (newsData.Showing && typeof newsData.Showing.toDate === "function") {
              newsData.Showing = moment(newsData.Showing.toDate()).format("YYYY-MM-DD");
              //console.log(newsData.Showing); // ตรวจสอบค่าที่แปลง
            }
            // แปลง updatedAt เป็น "YYYY-MM-DD HH:mm"
            if (newsData.updatedAt && typeof newsData.updatedAt.toDate === "function") {
              newsData.updatedAt = moment(newsData.updatedAt.toDate()).format("YYYY-MM-DD HH:mm");
            }
            // นับค่า Hold ถ้ามีอยู่
            if (newsData.Hold && !isNaN(newsData.Hold)) {
              this.TotalHold += Number(newsData.Hold);
            }

            // เก็บข้อมูลที่แปลงแล้วใน this.News
            this.News.push({
              ...newsData,
              ImagesOld: newsData.Images, // แสดงผล
              FileOld: newsData.File
            });
          });
        })
        .catch(error => {
          console.log("Error getting documents: ", error);
        });
    },

    async uploadImage(file, author) {
      if (!file) {
        alert("กรุณาเลือกไฟล์รูปภาพ!");
        return null;
      }
      else {
        // สร้างชื่อไฟล์ใหม่: YYYYMMDDTHHmm_Author
        const timestamp = moment().format("YYYYMMDDTHHmm");
        const fileExtension = file.name.split('.').pop(); // ดึงนามสกุลไฟล์
        const authorName = author ? author.replace(/\s+/g, "_") : "Unknown"; // แทนที่ช่องว่างเป็น "_"
        const newFileName = `${timestamp}_${authorName}.${fileExtension}`;
        // อัปโหลดไฟล์ไปยัง Firebase Storage
        const storageRef = storage.ref(`Ft_announce/images/${newFileName}`);
        const uploadTask = await storageRef.put(file);

        // รับ URL ของไฟล์ที่อัปโหลดสำเร็จ
        const downloadURL = await uploadTask.ref.getDownloadURL();
        //console.log(downloadURL);
        return downloadURL;
      }
    },
    async uploadFile(fileData, author) {
      if (!fileData) {
        alert("กรุณาเลือกไฟล์!");
        return null;
      }
      else {
        // สร้างชื่อไฟล์ใหม่: YYYYMMDDTHHmm_Author
        const timestamp = moment().format("YYYYMMDDTHHmm");
        const fileExtension = fileData.name.split('.').pop(); // ดึงนามสกุลไฟล์
        const authorName = author ? author.replace(/\s+/g, "_") : "Unknown"; // แทนที่ช่องว่างเป็น "_"
        const newFileName = `${timestamp}_${authorName}.${fileExtension}`;
        // อัปโหลดไฟล์ไปยัง Firebase Storage
        const storageRef = storage.ref(`Ft_announce/file/${newFileName}`);
        const uploadTask = await storageRef.put(fileData);
        // รับ URL ของไฟล์ที่อัปโหลดสำเร็จ
        const downloadURL = await uploadTask.ref.getDownloadURL();
        // console.log(`FileUrl`,downloadURL);
        return downloadURL;
      }
    },
    extractPathFromUrl(url) {
      try {
        const startIndex = url.indexOf('o/') + 2;
        const endIndex = url.indexOf('?');
        const encodedFilePath = url.substring(startIndex, endIndex);
        return decodeURIComponent(encodedFilePath);
      } catch (error) {
        console.error("Invalid URL:", error);
        return null; // คืนค่า null หาก URL ไม่ถูกต้อง
      }
    },
    // ฟังก์ชันลบไฟล์โดยใช้ URL
    async deleteFileByUrl(url) {
      try {
        const filePath = this.extractPathFromUrl(url);
        if (filePath) {
          const fileRef = firebase.storage().ref(filePath);
          await fileRef.delete();
        } else {
          console.error('Invalid file path:', filePath);
        }
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    },
    // ฟังก์ชันที่เปิด modal ยืนยันการบันทึก
    confirmSaveNews() {
      this.saveNews();  // เรียกใช้ฟังก์ชันบันทึกข่าว
      this.closeConfirmModal();  // ปิด modal
    },
    // ฟังก์ชันที่เปิด modal ยืนยัน
    openConfirmModal() {
      this.showConfirmModal = true;
    },
    // ฟังก์ชันปิด modal ยืนยัน
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    openConfirmDelete() {
      this.showConfirmDeleteModal = true;
    },
    closeConfirmDeleteModal() {
      this.showConfirmDeleteModal = false;
    },
    async confirmDeleteNews() {
      try {
        if (!this.newsForm || !this.newsForm.id) {
          alert("Cannot delete. News data is missing.");
          return;
        }

        if (this.newsForm.ImagesOld) await this.deleteFileByUrl(this.newsForm.ImagesOld);
        if (this.newsForm.FileOld) await this.deleteFileByUrl(this.newsForm.FileOld);

        // ลบข้อมูลจาก Firestore
        await db.collection('Ft_announcement').doc(this.newsForm.id).delete();
        // ปิด Modal
        this.showConfirmDeleteModal = false;
        this.showModal = false;
        // โหลดข่าวใหม่
        await this.fetchNews();
      } catch (error) {
        console.error("Error deleting news:", error);
        alert("Failed to delete news. Please try again.");
      }
    },
    openAddModal() {
      this.isEditing = false; // กำหนดว่าไม่ใช่การแก้ไข
      this.newsForm = {
        id: null,
        Category: "",
        Group: "",
        Hold: false,
        Title: "",
        Subtitle: "",
        Images: null,
        File: null,
        Content: "",
        Showing: moment().format("YYYY-MM-DD"), // ตั้งค่าวันที่ปัจจุบัน (วันนี้) เป็นค่าเริ่มต้น
        Link: null,
        Department: [],
        Author: "",
        Level: "low",
        createdAt: "",
        updatedAt: "",
      };
      this.showModal = true; // เปิด Modal
    },
    async saveNews() {
      const isFormValid = this.newsForm.Category && this.newsForm.Title && this.newsForm.Subtitle &&
        this.newsForm.Showing && this.newsForm.Department.length > 0 && this.newsForm.Level && this.newsForm.Group;

      if (!isFormValid) {
        alert('กรุณากรอกข้อมูลให้ครบถ้วนก่อนบันทึก!');
        return;
      }
      this.loading = true;
      try {
        let imageUrl = this.newsForm.Images || null;
        let fileUrl = this.newsForm.File || null;

        // ถ้ามีการเลือกไฟล์รูปใหม่
        if (this.newsForm.Images) {
          imageUrl = await this.uploadImage(this.newsForm.Images, this.AuthorLogin);
        } else if (this.newsForm.ImagesOld) {
          // ใช้รูปเดิม (ถ้ามี)
          imageUrl = this.newsForm.ImagesOld;
        }

        // ถ้ามีการเลือกไฟล์ใหม่
        if (this.newsForm.File) {
          fileUrl = await this.uploadFile(this.newsForm.File, this.AuthorLogin);
        } else if (this.newsForm.FileOld) {
          // ใช้ไฟล์เดิม (ถ้ามี)
          fileUrl = this.newsForm.FileOld;
        }

        // ตรวจสอบวันที่ในรูปแบบที่ถูกต้อง
        const createdAtMoment = moment(this.newsForm.createdAt, "YYYY-MM-DDTHH:mm", true);
        const formattedDate = createdAtMoment.isValid() ? createdAtMoment.toDate() : new Date();

        // เตรียมข้อมูลข่าว
        const newsData = {
          Category: this.newsForm.Category,
          Group: this.newsForm.Group,
          Level: this.newsForm.Level,
          // Hold: CheckHold,
          Title: this.newsForm.Title,
          Subtitle: this.newsForm.Subtitle,
          Images: imageUrl,
          File: fileUrl,
          Content: this.newsForm.Content,
          Showing: this.newsForm.Showing,
          Link: this.newsForm.Link || "",
          Department: this.newsForm.Department,
          Author: this.AuthorLogin || '',
        };

        // ตรวจสอบค่า Category และการตั้งค่า Hold
        let CheckHold = false;
        if (newsData.Category === 'Main') {
          CheckHold = this.newsForm.Hold;
        }

        newsData.Hold = CheckHold;  // นำค่า CheckHold ไปตั้งค่าที่ newsData

        if (this.isEditing) {
          // อัปเดตข่าว
          newsData.updatedAt = formattedDate; // เพิ่ม timestamp แก้ไขล่าสุด
          await db.collection("Ft_announcement").doc(this.newsForm.id).update(newsData);
          alert("News updated successfully!");
          await this.fetchNews();
        } else {
          // เพิ่มข่าวใหม่
          newsData.createdAt = formattedDate; // เพิ่ม timestamp สร้างข่าว
          newsData.updatedAt = formattedDate;

          const docRef = await db.collection("Ft_announcement").add(newsData);
          await db.collection("Ft_announcement").doc(docRef.id).update({ id: docRef.id }); // อัปเดต ID ในฐานข้อมูล
          alert("News added successfully!");
          await this.fetchNews();
        }
      } catch (error) {
        alert('❌ เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่!');
      } finally {
        this.loading = false;
        this.closeModal();
        this.currentPage = 1;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    editNews(news) {
      this.isEditing = true;
      this.newsForm = {
        id: news.id,
        Category: news.Category || "",
        Group: news.Group || "",
        Level: news.Level || "Low",
        Hold: news.Hold || false,
        Title: news.Title || "",
        Subtitle: news.Subtitle || "",
        Images: null,
        ImagesOld: news.ImagesOld || "",
        File: null,
        FileOld: news.FileOld || "",
        Content: news.Content || "",
        Showing: news.Showing || moment().format("YYYY-MM-DD"), // ตั้งค่าวันที่ปัจจุบัน (วันนี้) เป็นค่าเริ่มต้น
        Link: news.Link || "",
        Department: news.Department || [],
        Author: this.AuthorLogin || "",
      };
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ManageNewIndex {
  font-family: 'Arial';
  background-color: #ffffff18;
  height: 840px;
  color: white !important;


  .table-container {
    width: 100%;
    overflow-x: auto;
    padding: 1rem;
  }

  .table {
    width: 100%;
    min-height: 100%;
    background: #fff;
    font-family: Arial;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    th,
    td {
      padding: 12px 15px;
      text-align: center;
      border: none;
    }

    thead {
      background: #2d2f41;
      color: #ffffff;

      th {
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }

    tbody {
      tr {
        border-bottom: 2px solid #00000059;

        &:nth-child(even) {
          background: #b6b7bd5b;
        }

        &:hover {
          background: #2d2f41a8;
          color: #ffffff;
        }
      }
    }
  }

  .pagination .page-item .page-link {
    cursor: pointer;
  }

  /* Modal */
  .modal {
    color: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1040;
    padding-top: 20px;
  }

  .modal-header {
    position: relative;
  }

  .modal-confirm-body {
    color: #000;
  }

  .delete-btn {
    position: absolute;
    top: 1;
    right: 0;
    margin: 5px 10px;
    padding: 5px 10px;
  }

  .modal-title {
    color: #000;
    height: 70px;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 1100px;
    max-height: 100vh;
    overflow-y: auto;
  }

  .modal-footer {
    height: 50px;

    button {
      width: 100px;
    }
  }

  button {
    cursor: pointer;
  }

  /* เพิ่มการจัดการความสูงของรูปภาพใน Quill Editor */
  .ql-editor img {
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
  }

  .quill-editor {
    height: 300px;
    max-height: 280px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }


  .ql-toolbar {
    position: fixed;
    top: 0;
    z-index: 10;
    background: white;
    border-bottom: 1px solid #ddd;
  }

  .ql-container {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 400px;
  }


  /* Confirm Modal */
  .modal-confirm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    padding: 0 20px;
  }

  .modal-confirm-content {
    background-color: white;
    border-radius: 10px;
    width: 400px;
    padding: 50px;
    text-align: center;
  }

  .modal-confirm-header {
    position: relative;
  }

  .icon-box {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid #79aaa6;
    text-align: center;
    margin: 0 auto 20px;
  }

  .icon-box i {
    font-size: 46px;
    margin-top: 13px;
  }

  .modal-title {
    font-size: 26px;
    margin-bottom: 20px;
  }

  .modal-confirm-footer {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }

  .modal-confirm-footer .btn {
    width: 100px;
    margin: 0 10px;
  }
}
</style>
