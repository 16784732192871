<template>
    <div class="container-fluid">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="box-bookMark-Package d-flex align-items-center justify-content-center">
              <h3 class="box-bookMark-Package">Package</h3>
            </div>
          </div>
         <!-- <div class="text-muted">Package Management</div>  -->
          <b-nav class="mt-2" tabs>
            <b-nav-item :active="currentMenu === menu.name" v-for="(menu, index) in menus" :key="index"
              @click="currentMenu = menu.name">{{ menu.name }}</b-nav-item>
          </b-nav>
  
          <div v-if="currentMenu === 'Package'">
            <package-tab />  
          </div>
  

        </div>
  
      </div>
      
    </div>
  </template>
  
  <script>

  import Package from './Package.vue'

  
  export default {
    components: {
      
      "package-tab": Package,
    
    },
    data() {
      return {
        currentMenu: "Package",        
      }
    },
    firestore() {
      return {
      }
    },
    methods: {    
      
    },
    computed: {      
      menus() {
        const menus = [
          { name: "Package" },
          
        ]
        return menus
      },
    },
  }
  </script>
<style lang="css">
.box-bookMark-Package {
  width: 200px;
  height: 100px;
  background-color: #2F6EBA;
  margin-top: -11px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-bookMark-Package h3{
  color: #ffffff;
}
</style>