<template>
    <div>
        <div class="d-flex">
            <div class="ml-auto">
                Sum Deposit: {{ totalDeposit.toLocaleString() }}
            </div>
            <div class="ml-3">
                Sum Withdrawal: {{ totalWithdrawal.toLocaleString() }}
            </div>
        </div>

        <vue-good-table :columns="columns" :rows="filter_sender" :lineNumbers="true" :sort-options="{ enabled: false, }"
            class="sender-table">

            <template slot="table-row" slot-scope="props">

                <div v-if="props.column.field == 'detail'">
                    <div>{{ props.row.detail }}</div>
                    <div class="text-secondary" v-if="props.row.location">
                        Location : {{ props.row.location }}

                    </div>
                    <div class="text-secondary" v-if="props.row.admit">
                        Admit : {{ props.row.admit }} Days
                    </div>
                </div>
                <div v-else-if="props.column.field == 'time'">
                    {{ formatTime(props.row.time) }}
                </div>
                <div v-else-if="props.column.field == 'patientList'">
                    <div v-for="(patient, index) in props.row.patientList" :key="'patient-' + index">
                        {{ patient.visit_date }} {{ patient.name }}
                    </div>
                </div>

                <div v-else-if="props.column.field == 'staff'">
                    <span v-for="(name, index) in props.row.staff" :key="'staff-' + index" style="font-size: 75%;">
                        <Tag :text="name" :tagColor="getRoleColor(name)" class="" />
                    </span>
                </div>
                <div v-else-if="props.column.field == 'paymentMethod'">
                    <div class="mt-1">
                        <span :class="{
                            'cash': props.row.paymentMethod === 'CASH',
                            'scb': props.row.paymentMethod === 'SCB',
                            'ktb': props.row.paymentMethod === 'KTB',
                            'krungsri': props.row.paymentMethod === 'KRUNGSRI',
                            'bbl': props.row.paymentMethod === 'BBL',
                            'kbank': props.row.paymentMethod === 'KBANK',
                        }">
                            {{ props.row.paymentMethod }}
                        </span>
                    </div>

                </div>
                <div v-else-if="props.column.field == 'deposit'">
                    <span :class="{ 'deposit-row': props.row.deposit > 0 }">{{ props.row.deposit.toLocaleString()
                        }}</span>
                </div>
                <div v-else-if="props.column.field == 'withdrawal'">
                    <span :class="{ 'withdraw-row': props.row.withdrawal > 0 }">{{
                        props.row.withdrawal.toLocaleString()
                        }}</span>
                </div>

            </template>

        </vue-good-table>

    </div>
</template>

<script>
import { db } from "../../db"
import moment from "moment"
import Parser from "@json2csv/plainjs/dist/cjs/Parser"

export default {
    props: ['branch', 'publicHealth'],
    firestore() {
        return {
            senders: db.collection("SenderLog"),
        }
    },
    data() {
        return {

            columns: [
                { label: "Time", field: "time", },
                { label: "Detail", field: "detail", tdClass: (row) => { return this.changeColorDetail(row.detail) } },
                { label: "Sender", field: "sender" },
                { label: "Recipient", field: "recipient", tdClass: (row) => { return this.changeColorDetail(row.detail) } },
                { label: "Patient", field: "patientList", },
                { label: "Admit", field: "admit", },
                { label: "Deposit", field: "deposit" },
                { label: "Withdrawal", field: "withdrawal" },
                { label: "Balance", field: "balance", },
                { label: "Staff", field: "staff", },
                { label: "Payment ", field: "paymentMethod", }
            ],
            senders: [
                // {
                //     time: "6/3/2025 7:00:00",
                //     sender: "คุณ เอ็กซ์",
                //     detail: "ค่า Incentive Sender",
                //     recipient: "นาย ธนวุฒิ วงศาชลาศัย",
                //     location: "หน้าหาด",
                //     patientList: [{ name: "Mr. joey de jager", visit_date: "06/03/2025" }, { name: "นาย ชัยธวัช กิตติคุณากร", visit_date: "28/07/2024" }],
                //     admit: 1,
                //     deposit: 0,
                //     withdrawal: 800,
                //     balance: 4224,
                //     staff: ["NUNGNING"],
                //     paymentMethod: "CASH",
                //     branch: "HOSPITAL",
                // },
                // {
                //     time: "7/3/2025 9:00:00",
                //     sender: "",
                //     detail: "เติมเงินเข้าระบบ petty cash",
                //     recipient: "HOSPITAL",
                //     location: "",
                //     patientList: [],
                //     admit: 0,
                //     deposit: 1000,
                //     withdrawal: 0,
                //     balance: 5224,
                //     staff: ["DEDE"],
                //     paymentMethod: "SCB",
                //     branch: "HOSPITAL",
                // },
                // {
                //     time: "8/3/2025 9:00:00",
                //     sender: "",
                //     detail: "เติมเงินเข้าระบบ petty cash",
                //     recipient: "HOSPITAL",
                //     location: "",
                //     patientList: [],
                //     admit: 0,
                //     deposit: 1000,
                //     withdrawal: 0,
                //     balance: 6224,
                //     staff: ["LIN"],
                //     paymentMethod: "KTB",
                //     branch: "HOSPITAL",
                // },
                // {
                //     time: "9/3/2025 9:00:00",
                //     sender: "",
                //     detail: "เติมเงินเข้าระบบ petty cash",
                //     recipient: "HOSPITAL",
                //     location: "",
                //     patientList: [],
                //     admit: 0,
                //     deposit: 1000,
                //     withdrawal: 0,
                //     balance: 7224,
                //     staff: ["DEDE"],
                //     paymentMethod: "KRUNGSRI",
                //     branch: "HOSPITAL",
                // },
                // {
                //     time: "9/3/2025 10:00:00",
                //     sender: "คุณ เอ็กซ์",
                //     detail: "ค่า Incentive Sender",
                //     recipient: "นาย ธนวุฒิ วงศาชลาศัย",
                //     location: "หน้าหาด",
                //     patientList: [{ name: "Mr. joey de jager", visit_date: "06/03/2025" }, { name: "นาย ชัยธวัช กิตติคุณากร", visit_date: "28/07/2024" }],
                //     admit: 1,
                //     deposit: 0,
                //     withdrawal: 1000,
                //     balance: 6224,
                //     staff: ["NUNGNING"],
                //     paymentMethod: "KBANK",
                //     branch: "HOSPITAL",
                // },
                // {
                //     time: "9/3/2025 16:00:00",
                //     sender: "คุณ เอ็กซ์",
                //     detail: "ค่า Incentive Sender",
                //     recipient: "นาย ธนวุฒิ วงศาชลาศัย",
                //     location: "หน้าหาด",
                //     patientList: [{ name: "Mr. joey de jager", visit_date: "06/03/2025" }, { name: "นาย ชัยธวัช กิตติคุณากร", visit_date: "28/07/2024" }],
                //     admit: 1,
                //     deposit: 0,
                //     withdrawal: 500,
                //     balance: 5724,
                //     staff: ["NUNGNING"],
                //     paymentMethod: "BBL",
                //     branch: "HOSPITAL",
                // },
            ],
        }
    },
    methods: {
        getRoleColor(name) {
            if (this.publicHealth.some(element => element.label === name)) {
                return "#a4c964ff"
            }
            return ""
        },
        changeColorDetail(detail) {
            if (detail === 'เติมเงินเข้าระบบ petty cash') {
                return 'detail-row'
            }

            else {
                return ''
            }
        },
        formatTime(date) {
            return moment(date.toDate()).format("DD/MM/YYYY HH:mm")
        },
    },
    computed: {
        filter_sender() {
            return this.senders.filter(doc => doc.branch === this.branch)
        },
        totalDeposit() {
            return this.senders.reduce((sum, row) => sum + row.deposit, 0)
        },
        totalWithdrawal() {
            return this.senders.reduce((sum, row) => sum + row.withdrawal, 0)
        },
    }
}
</script>

<style lang="scss">
.sender-table {
    .detail-row {
        font-weight: bold;
        color: #1976D2;
    }

    .deposit-row {
        font-weight: bold;
        color: #28a745;
    }

    .withdraw-row {
        font-weight: bold;
        color: #dc3545;
    }

    .cash {
        background-color: #1976D2;
        color: #fff;
        padding: 5px;
        border-radius: 0.5em;
        font-weight: bold;
    }

    .scb {
        background-color: #4b2d85;
        color: #f2b234;
        padding: 5px;
        border-radius: 0.5em;
        font-weight: bold;
    }

    .ktb {
        background-color: #00a4e4;
        color: white;
        padding: 5px;
        border-radius: 0.5em;
        font-weight: bold;

    }

    .krungsri {
        background-color: #ffd400;
        color: #554242;
        padding: 5px;
        border-radius: 0.5em;
        font-weight: bold;

    }

    .bbl {
        background-color: #1976D2;
        color: #ffa912;
        padding: 5px;
        border-radius: 0.5em;
        font-weight: bold;
    }

    .kbank {
        background-color: #00A94F;
        color: white;
        padding: 5px;
        border-radius: 0.5em;
        font-weight: bold;
    }


}
</style>