<template>
    <b-modal>
        <draggable />

    </b-modal>
</template>
<script>


import { db, Timestamp } from '../../db'
import draggable from 'vuedraggable'
export default {
    props: ["invoice"],
    components: {
        draggable
    },

    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    },
    watch: {

    },
}
</script>