<template>
    <div v-if="loading" class="Announcement-carousel">
        <div class="loader-container">
            <div class="loader-ring"></div>
            <span>loading...</span>
        </div>
    </div>
    <div v-else class="Announcement-carousel">
        <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel" data-interval="6000">
            <ol class="carousel-indicators">
                <li v-for="(item, index) in newsSlides" :key="index" :class="{ 'active': index === slideIndex }"
                    @click="goToSlide(index)"></li>
            </ol>
            <div class="carousel-inner">
                <div v-for="(item, index) in newsSlides" :key="index"
                    :class="['carousel-item', { 'active': index === slideIndex }]">
                    <img :src="item.Images ? item.Images : require('./images/NoImgHead.svg')" class="d-block w-100"
                        :alt="item.Title" :style="{ width: '500px', height: '640px' , borderRadius: '10px' }" />
                    <div class="text-overlay">
                        <div style="font-size: 25px; padding: 20px;">
                            {{ item.Title }}
                        </div>
                        <div style="font-size: 20px; padding: 15px;">
                            {{ item.Subtitle }}
                        </div>
                        <div style="padding: 20px; font-family: Arial;">
                            <button class="btn btn-danger" @click.stop="openModalSlides(item)">ดูเพิ่มเติม</button>

                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" @click="prevSlide">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" @click="nextSlide">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </button>
        </div>


        <!-- ModalSlides -->
        <div v-if="showModalSlides" class="ModalSlides" @click.self="closeModalSlides">
            <div :class="['ModalSlides-content', { 'fullscreen': isFullScreen }]">
                <div class="row" v-if="!isFullScreen">

                    <div class="col-11">
                        <h1>{{ ModalSlidesContent.Title }}</h1>
                    </div>

                    <div class="col-1">
                        <button class="btn-icon" @click="closeModalSlides">
                            <i class="fa fa-times-circle"></i>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 ModalSlides-image-container">
                        <img :src="ModalSlidesContent.Images || require('./images/NoImgHead.svg')" alt="Image"
                            class="ModalSlides-image" @click="toggleFullScreen" />
                    </div>
                </div>
                <div style="padding: 15px;">
                <div class="row" v-if="!isFullScreen">
                    <div class="col-12">
                        <h2>{{ ModalSlidesContent.Subtitle }}</h2>
                    </div>
                </div>
                <div class="row" v-if="!isFullScreen">
                    <div class="col-12">
                        <h3 v-html="ModalSlidesContent.Content"></h3>
                    </div>
                </div>
                <div class="row" v-if="!isFullScreen">
                    <div class="col-6" v-if="ModalSlidesContent.Link">
                        <h5>Link:

                            <a v-if="ModalSlidesContent.Link" :href="ModalSlidesContent.Link" target="_blank"
                                rel="noopener noreferrer">
                                {{ ModalSlidesContent.Link }}
                            </a>
                        </h5>
                    </div>
                    <div class="col-6" v-if="ModalSlidesContent.File">
                        <h5>Documents :
                            <a v-if="ModalSlidesContent.File" :href="ModalSlidesContent.File"
                                target="_blank">Download</a>
                        </h5>
                    </div>
                </div>
                <div class="row" v-if="!isFullScreen">
                    <div class="col-12">
                        <small class="custom-footer">Last updated: {{ ModalSlidesContent.updatedAt }}</small>
                    </div>
                </div>
               </div>
        </div>
    </div>

    </div>
</template>

<script>
export default {
    props: {
        newsSlides: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            isFullScreen: false, // เช็คว่าอยู่ในโหมดเต็มจอหรือไม่
            showModalSlides: false,
            ModalSlidesContent: {},
            slideIndex: 0,
            interval: 6000, // เวลาที่จะใช้เลื่อนสไลด์
            loading: true,
            autoSlideInterval: null // เก็บ interval ของการเลื่อน
        };
    },
    methods: {
        toggleFullScreen() {
            this.isFullScreen = !this.isFullScreen;
        },
        closeModalSlides() {
            this.isFullScreen = false;
            this.showModalSlides = false;
            this.startAutoSlide(); // กลับมาเริ่ม auto slide เมื่อปิด modal
        },
        nextSlide() {
            this.slideIndex = (this.slideIndex + 1) % this.newsSlides.length;
        },
        prevSlide() {
            this.slideIndex = (this.slideIndex - 1 + this.newsSlides.length) % this.newsSlides.length;
        },
        goToSlide(index) {
            this.slideIndex = index;
        },
        changeSlide() {
            this.nextSlide();
        },
        startAutoSlide() {
            clearInterval(this.autoSlideInterval); 
            if (!this.showModalSlides) { 
                this.autoSlideInterval = setInterval(this.changeSlide, this.interval);
            }
        },
        openModalSlides(content) {
            this.showModalSlides = true;
            this.ModalSlidesContent = content;
            clearInterval(this.autoSlideInterval);
        }
    },
    watch: {
        newsSlides: {
            handler(newItems) {
                this.loading = newItems.length === 0;
                if (newItems.length > 0) {
                    this.startAutoSlide(); // เริ่มการเลื่อนอัตโนมัติเมื่อมีข่าว
                }
            },
            immediate: true
        },
        showModalSlides(newValue) {
            if (newValue) {
                // เมื่อ modal เปิดจะหยุดการคลิกใน carousel
                document.querySelector('.Announcement-carousel').classList.add('modal-open');
            } else {
                // เมื่อ modal ปิดจะเปิดการคลิกใน carousel
                document.querySelector('.Announcement-carousel').classList.remove('modal-open');
            }
        }
    },
    mounted() {
        if (this.newsSlides.length > 0) {
            this.loading = false;
            this.startAutoSlide();
        }
    },
    beforeDestroy() {
        clearInterval(this.autoSlideInterval);
    }
};

</script>

<style lang="scss" scoped>
.Announcement-carousel {

    .carousel-inner {
        transition: transform 1s ease-in-out;
    }

    .carousel-item {
        transition: opacity 1s ease-in-out;
    }

    .carousel-item.active {
        opacity: 1;
    }

    .carousel-item {
        opacity: 0;
    }

    .loader-container {
        margin: 20px;
        left: 0;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        background-color: #00000025;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        padding: 5px;
    }

    .loader-ring {
        position: absolute;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        animation: loader-ring 2s linear infinite;
    }

    @keyframes loader-ring {
        0% {
            transform: rotate(0deg);
            box-shadow: 1px 5px 2px #e65c00;
        }

        50% {
            transform: rotate(180deg);
            box-shadow: 1px 5px 2px #18b201;
        }

        100% {
            transform: rotate(360deg);
            box-shadow: 1px 5px 2px #0456c8;
        }
    }

    .loader-ring:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        box-shadow: 0 0 5px rgba(255, 255, 255, .3);
    }

    span {
        color: #737373;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 200px;
        animation: text 3s ease-in-out infinite;
    }

    @keyframes text {
        50% {
            color: black;
        }
    }

    .btn-icon {
        background: none;
        border: none;
        font-size: 32px;
        cursor: pointer;
        padding: 1px;
        margin-left: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .btn-icon i {
        color: #ff0000;
        font-size: 40px;
    }

    .text-overlay {
        width: 80% !important;
        min-width: 80% !important;
        position: absolute;
        color: wheat;
        background-color: rgba(51, 51, 51, 0.5);
        backdrop-filter: blur(10px);
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: auto;
        text-align: center;
        border-radius: 10px;
    }

    .custom-footer {
        color: #ff5722;
        font: 1em Arial;
        font-weight: bold;
    }

    .ModalSlides {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(30, 31, 41, 0.829);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        overflow: auto;
    }

    .ModalSlides-content {
        background-color: rgb(255, 255, 255);
        width: 100%;
        max-width: 1200px;
        max-height: 90vh;    
        overflow: auto;   
        padding: 20px;
        border-radius: 5px;
    }

    .ModalSlides-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
    }

    .ModalSlides-image {
        width: auto;
        height: 100vh;       
        max-width: 100%;    
        object-fit: contain;       
        cursor: pointer;
    }

    .fullscreen {
        width: 100vw;
        height: 100vh;
        max-width: 100%;
        max-height: 100%;
        background: black;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fullscreen .ModalSlides-image {
        width: 100vw;
        height: 100vh;
        object-fit: contain;
    }

}
</style>
