<template>
  <div class="mt-4">
    <div class="d-flex mb-2">
      <h3 class="text-primary">Invoice</h3>
      <a target="_blank" :href="`/print/invoices?patientId=${$route.params.id}`" class="btn btn-light ml-auto"><i
          class="fas fa-print"></i> Print Invoice Summary</a>
      <button class="btn btn-primary ml-1" @click="showNewInvoice()" tabs v-if="!$route.meta.print">
        <i class="fas fa-plus"></i> New Invoice
      </button>
    </div>

    <invoice-table :data="invoices" @showInvoice="showInvoice" @displayPaymentModal="showPayment"
      @displayNewPaymentModal="showNewPayment" />

    <patient-invoice-modal :invoice="invoice" :visits="visits" @refetchInvoices="fetchInvoices"
      @showNewPayment="showNewPayment" />
  </div>
</template>

<script>
import { db } from '../../../db'
import { getTotalDiscount } from '../../../helper/invoice'

export default {
  props: ['patient'],
  data() {
    return {
      invoices: [],
      invoice: {
        invoice_id: null,
        product_items: [],
        billable_items: [],
      },
      rawInvoices: [],
      visits: [],
      total_invoiced: 0,
      payment_applied: 0,
      total_outstanding: 0,
    }
  },
  mounted() {
    this.fetchInvoices()
  },
  methods: {
    async fetchInvoices() {
      const loader = this.$loading.show({});
      const patientId = this.$route.params.id;
      const invoices = []

      const invoicesCollection = await db.collection('Invoice').where('HN', '==', patientId).orderBy('issue_date', 'desc').get()

      const invoicePromises = invoicesCollection.docs.map(async invoiceDoc => {
        const invoiceData = invoiceDoc.data();
        if (invoiceData && invoiceData.invoice_id) {
          const paymentsCollection = await db.collection('Payment')
            .where('invoice_id', '==', invoiceData.invoice_id)
            .get();

          const payments = paymentsCollection.docs.map(paymentDoc => paymentDoc.data());
          invoiceData.payments = payments;
        }
        if (invoiceData.HN) {
            const patientDoc = await db.collection("Patient").where("HN", "==", invoiceData.HN).get()
            if (!patientDoc.empty) {
              const patientData = patientDoc.docs[0].data()
              invoiceData.patient_name = `${patientData.first_name} ${patientData.last_name}`
              
            } 
          }
        invoiceData.discount = Math.round(getTotalDiscount(invoiceData));

        return invoiceData;
      });

      const resolvedInvoices = await Promise.all(invoicePromises);
      invoices.push(...resolvedInvoices);

      this.rawInvoices = invoices

      const invoicesByDate = _.groupBy(invoices, item => {
        return this.$moment(item.issue_date.toDate()).format("DD MMM YYYY");
      });

      this.invoices = Object.entries(invoicesByDate).map(data => {
        return {
          mode: 'span',
          label: data[0],
          html: false,
          children: data[1]
        }
      })

      loader.hide()
    },
    async showNewInvoice() {
      this.invoice = {
        invoice_id: null,
        issue_date: this.$moment().format('DD/MM/YYYY HH:mm'),
        visit_date: this.$moment().format('DD/MM/YYYY HH:mm'),
        concession_type: 'Foreigner',
        insurance_name: null,
        insurance_type: null,
        insurance_name_other: null,
        HN: null,
        VN: null,
        patient_name: null,
        status: 'unpaid',
        product_items: [],
        billable_items: [],
        note: null,
        payment_applied: 0,
        outstanding: 0,
        total_invoiced: 0,
        practitioner: null,
        billLog: [],
        insurance_level: null,
        assist_level: null,
        insurance_class: [],
        assist_class: [],
        type: null,
        discount: 0,
        case_type: 'OPEN',

      }

      if (this.patient) {
        this.invoice.HN = this.patient.HN
        this.invoice.patient_name = `${this.patient.first_name} ${this.patient.last_name}`

        if (this.patient.travel_insurance) {
          this.invoice.concession_type = 'Insurance'
          this.invoice.insurance_name = this.patient.travel_insurance?.name_en || null
          this.invoice.insurance_type = this.patient.travel_insurance?.type || null
          this.invoice.insurance_name_other = this.patient.travel_insurance_other || null
          this.invoice.billed_to = `${this.patient.first_name} ${this.patient.last_name}`
          this.invoice.assist_insurance = this.patient.assist_insurance || null
          this.invoice.insurance = _.find(this.$store.state.insurances, { 'name_en': this.patient.travel_insurance?.name_en }) || {}
          if (this.invoice.insurance.length > 0) {
            this.invoice.insurance_name = this.invoice.insurance.name_en
            this.invoice.insurance_type = this.invoice.insurance.type
            this.invoice.insurance_id = this.invoice.insurance.id
            this.invoice.insurance_level = this.invoice.insurance.level || ''
            this.invoice.billed_to = this.invoice.insurance_billed_to || `${this.patient.first_name} ${this.patient.last_name}`
            this.invoice.insurance_class = this.invoice.insurance.class || []
            this.invoice.insurance_note = this.invoice.insurance.note || ''
          }

        } else if (this.patient.is_resident || this.patient.is_phiphi_resident) {
          this.invoice.concession_type = 'Resident'
          this.invoice.billed_to = `${this.patient.first_name} ${this.patient.last_name}`
        } else {
          this.invoice.concession_type = 'Foreigner'
          this.invoice.billed_to = `${this.patient.first_name} ${this.patient.last_name}`
        }
      }
      this.visits = await this.fetchVisits(this.invoice.HN)
      setTimeout(() => this.$bvModal.show('new-patient-invoice-modal'), 1)
    },
    async showInvoice(invoiceId) {

      const selectedInvoice = this.rawInvoices.filter(invoice => invoice.invoice_id === invoiceId)[0]
      this.invoice = {
        ...selectedInvoice,
        issue_date: this.$moment(selectedInvoice.issue_date.toDate()).format('DD/MM/YYYY HH:mm'),
        visit_date: selectedInvoice.visit_date ? this.$moment(selectedInvoice.visit_date.toDate()).format('DD/MM/YYYY HH:mm') : null,
        billLog: selectedInvoice.billLog ? selectedInvoice.billLog : [],
      }

      this.visits = await this.fetchVisits(selectedInvoice.HN)
      setTimeout(() => this.$bvModal.show('new-patient-invoice-modal'), 1)
    },
    async showPayment(paymentId) {
      this.$emit('displayPaymentModal', paymentId)
    },
    async showNewPayment(invoice) {
      this.$emit('displayNewPaymentModal', invoice)
    },
    async fetchVisits(patientId) {
      const visits = (await db.collection('Visit')
        .where('patient', '==', db.collection('Patient').doc(patientId))
        .orderBy('visit_date', 'desc')
        .get())
        .docs.map(visit => visit.data())

      if (!visits.length) this.$alert('No visit information, patient is required to register new visit first before proceed.', null, 'error')

      return visits
    },
  }
}
</script>