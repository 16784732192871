<template>
    <div class="card-data">
        <b-card v-for="(titleItem, titleIndex) in MeetingsIN?.title_items" :key="titleIndex" class="mb-3 ">
            <div class="card-body" style="padding: 10px; border-radius: 70px;">
                <div class="d-flex justify-content-between">
                    <h4>{{ titleItem.title }}</h4>
                    <span :class="getStatusClass(titleItem.task_status)" style="font-size: 25px;">{{ titleItem.task_status }}</span>
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <table class="table">
                        <thead>
                            <tr class="tr">
                                <th class="th text-center">Result</th>
                                <th class="text-center">Main</th>
                                <th class="text-center">Asst</th>
                                <th class="text-center">Due Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(contentItem, contentIndex) in titleItem.content_items" :key="contentIndex">
                                <td style="vertical-align: middle !important; ">{{ contentItem.content }}</td>


                                <td style="vertical-align: middle !important; "
                                    v-if="!contentItem.staff_assist.length || contentItem.staff_assist[0] === 'N/A'"
                                    colspan="2">
                                    {{ contentItem.staff_main[0] }}
                                </td>


                                <td v-else>{{ contentItem.staff_main[0] }}</td>
                                <td style="vertical-align: middle !important; "
                                    v-if="contentItem.staff_assist.length && contentItem.staff_assist[0] !== 'N/A'">
                                    {{ contentItem.staff_assist[0] }}
                                </td>

                                <td style="vertical-align: middle !important; ">{{ contentItem.due_date || 'N/A' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </b-card>

    </div>
</template>

<script>
// import { db, storage } from '../../db'
// import moment from 'moment'
export default {
    components: {},
    props: {
        MeetingsIN: {
            type: Object,  // Change this to 'Object' instead of 'Array'
            required: true
        }
    },
    data() {
        return {
        }
    },

    watch: {
    },
    mounted() {

    },
    methods: {
        getStatusClass(status) {
            switch (status) {
                case "Complete":
                    return "badge badge-success";
                case "In progress":
                    return "badge badge-warning";
                default:
                    return "badge badge-secondary";
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.card-data {
    color: #000 !important;
    border-radius: 15px;


.stepper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.table {
    width: 100% !important;
    // background-color: #0cb3f5;
    color: rgb(7, 7, 7);
    // border-collapse: collapse;
    // border: 1px solid rgb(139, 135, 135);
}

::v-deep .tr {
    background-color: #0cb3f5;
    border: 3px solid rgb(167, 170, 170) !important;
  
}
 ::v-deep td {
    background-color: #ffffff;
    border: 2px solid rgb(139, 135, 135) !important;
    color: rgb(0, 0, 0);
    padding: 20px;
    text-align: center;
}

/* ปรับขนาดคอลัมน์ "Result" ให้ใหญ่ขึ้น */
 :deep(th:nth-child(1)),
 :deep(td:nth-child(1)) {
    width: 50% !important;
}

/* ปรับขนาดคอลัมน์อื่นให้เล็กลง */
 :deep(th:nth-child(2)),
 :deep(td:nth-child(2)),
 :deep(th:nth-child(3)),
 :deep(td:nth-child(3)),
 :deep(th:nth-child(4)),
 :deep(td:nth-child(4)) {
    width: 16.67% !important;
}

/* ปรับข้อความแถวแรกใน td ให้ชิดซ้าย */

 :deep(td:first-child) {
    text-align: left !important;
    font-family: 'Arial';
    padding-left: 20px;
}

/* จัดแนวตั้งสำหรับ td ที่มี rowspan */
 :deep(td[rowspan]) {
    vertical-align: middle !important;
    text-align: center;
}
}
</style>
