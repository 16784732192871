<template>
    <div class="container-fluid">
        <div class="d-flex mt-2 mb-2">
            <h3 class="text-primary">Insurance</h3>

            <button class="btn btn-primary ml-auto" @click="$bvModal.show('import-insurance')">
                <i class="fas fa-file-import"></i> Manage with CSV
            </button>

            <button class="btn btn-primary ml-3" @click="showInsurance()">
                <i class="fas fa-plus"></i> Insurance
            </button>



        </div>

        <vue-good-table class="insurance-table" :columns="InsuranceColumns" :rows="insurances" :pagination-options="{
            enabled: true,
            perPageDropdown: [50, 100, 200],
            mode: 'pages'
        }" :search-options="{
            enabled: true,
        }">
            <template slot="table-row" slot-scope="props">

                <div v-if="props.column.field === 'logo'">
                    <div v-if="props.row.logo">
                        <img :src="props.row.logo" alt="Logo Preview" style="max-width: 100px; max-height: 100px;" />
                    </div>
                </div>
                <div v-else-if="props.column.field === 'name_en'">
                    <span class="text-primary clickable" @click="showInsurance(props.row)">
                        {{ props.row.name_en }}
                    </span>
                </div>

                <div v-else-if="props.column.field === 'level'">
                    <span :class="{
                        'best-row': props.row.level === 'BEST',
                        'good-row': props.row.level === 'GOOD',
                        'normal-row': props.row.level === 'NORMAL',
                        'graylist-row': props.row.level === 'GRAYLIST',
                        'temporary-row': props.row.level === 'TEMPORARY BAN',
                        'blacklist-row': props.row.level === 'BLACKLIST',
                    }">
                        <!-- {{ props.row.level }} -->
                        {{ getLevelText(props.row.level) }}
                    </span>

                </div>
                <div v-else-if="props.column.field === 'class'">
                    <draggable v-model="props.row.class" group="class" @start="drag = true" @end="drag = false">
                        <div class="row mb-2" v-for="(item, index) in props.row.class" :key="index">
                            <div class="col-1">
                                {{ index + 1 }}.
                            </div>
                            <div class="col-3">
                                Class :
                                {{ item.class }}
                            </div>
                            <div class="col-3">
                                Type :
                                {{ item.type }}
                            </div>
                            <div class="col-5">
                                Condition :
                                {{ item.discountLog }}
                            </div>
                            <div class="col-1">
                            </div>
                            <div class="col-3">
                                Credit Note :
                                {{ item.credit_note }}%
                            </div>
                            <div class="col-3">
                                Put on Invoice :
                                {{ item.put_on_invoice }}%
                            </div>
                            <div class="col-3">
                                Kick back :
                                {{ item.kick_back }}%

                            </div>
                        </div>
                    </draggable>
                </div>
            </template>

        </vue-good-table>

        <b-modal id="insurance-item-modal" hide-footer size="lg" :title="'Insurance'">

            <div class="row">
                <div class="col-12 mt-2 d-flex row mb-2">
                    <div class="col-1">
                        <label class="text-primary">Logo</label>
                    </div>
                    <div class="col-5">
                        <input type="file" class="form-control" accept="image/*" @change="uploadLogo" />
                    </div>
                    <div v-if="insurance.logo" class="col-6">
                        <img :src="insurance.logo" alt="Logo Preview" style="max-width: 100px; max-height: 100px;" />
                    </div>

                </div>

                <div class="col-3">
                    <MazInput :placeholder="'Id'" v-model="insurance.id" />
                </div>

                <div class="col-3">
                    <MazInput :placeholder="'Name'" v-model="insurance.name_en" />
                </div>
                <div class="col-3">
                    <MazSelect v-model="insurance.type" :placeholder="'Type'" :options="[
                        { label: '', value: '' },
                        { label: 'Inter', value: 'Inter' },
                        { label: 'Local', value: 'Local' },
                        { label: 'Other', value: 'Other' },
                    ]" />
                </div>
                <div class="col-3">
                    <MazSelect v-model="insurance.level" :placeholder="'Level'" :options="[
                        { label: '', value: '' },
                        { label: 'BEST', value: 'BEST' },
                        { label: 'GOOD', value: 'GOOD' },
                        { label: 'NORMAL', value: 'NORMAL' },
                        { label: 'GRAYLIST', value: 'GRAYLIST' },
                        { label: 'TEMPORARY BAN', value: 'TEMPORARY BAN' },
                        { label: 'BLACKLIST', value: 'BLACKLIST' }]" />
                </div>

                <div class="col-12 mt-2">
                    <MazInput :placeholder="'Billed to'" v-model="insurance.billed_to" />
                    <hr />
                </div>
                <div class="col-12 d-flex">
                    <button class="btn btn-primary ml-auto" @click="addClass">
                        <i class="fas fa-plus"></i> Add Class
                    </button>
                </div>

                <div class="col-12 mt-2">
                    <draggable v-model="insurance.class" group="class" @start="drag = true" @end="drag = false">
                        <div class="row mb-2" v-for="(item, index) in insurance.class" :key="index">
                            <div class="col-1">
                                <div class="m-4 p-2">
                                    <i class="fas fa-bars" style="color: grey"></i>
                                </div>
                            </div>
                            <div class="col-3">
                                <label class="text-primary">Class</label>
                                <MazSelect v-model="item.class" label="Level" :options="[
                                    { label: 'OPEN', value: 'OPEN' },
                                    { label: 'OPEN+', value: 'OPEN+' },
                                    { label: 'SEND', value: 'SEND' },
                                    { label: 'SEND+', value: 'SEND+' },
                                    { label: 'ALL CLASS', value: 'ALL CLASS' },
                                    { label: 'COMISSION', value: 'COMISSION' }]" />
                            </div>
                            <div class="col-3">
                                <label class="text-primary">Type</label>
                                <MazSelect v-model="item.type" label="Level" :options="[
                                    { label: 'OPD', value: 'OPD' },
                                    { label: 'IPD', value: 'IPD' },
                                    { label: 'PA', value: 'PA' },
                                ]" />
                            </div>
                            <div class="col-3">
                                <label class="text-primary">Condition</label>
                                <MazInput :placeholder="'Discount Log'" v-model="item.discountLog" />
                            </div>

                            <div class="col-1 mt-4">
                                <button class="btn btn-light" @click="insurance.class.splice(index, 1)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                            <div class="col-1">
                            </div>
                            <div class="col-1">
                            </div>
                            <div class="col-3">
                                <label class="text-primary">Credit Note</label>

                                <b-input-group append="%">
                                    <b-form-input v-model="item.credit_note" type="number"></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="col-3">
                                <label class="text-primary">Put on Invoice</label>
                                <b-input-group append="%">
                                    <b-form-input v-model="item.put_on_invoice" type="number"></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="col-3">
                                <label class="text-primary">Kick Back</label>
                                <b-input-group append="%">
                                    <b-form-input v-model="item.kick_back" type="number"></b-form-input>
                                </b-input-group>
                            </div>





                            <hr />
                        </div>
                    </draggable>
                    <hr />
                    <div class="col-12 mt-2">
                        <MazInput :placeholder="'Note'" v-model="insurance.note" />
                    </div>
                </div>

                <!-- <div class="col-12 mt-2">
                    <MazSelect v-model="insurance.type" label="Item type"
                        :options="[{ label: 'Inter', value: 'Inter' }, { label: 'Local', value: 'Local' }, { label: 'Other', value: 'Other' }]" />
                </div> -->
                <div class="d-flex justify-content-end mt-2 col-12">
                    <div class="p-2">
                        <button class="btn btn-primary btn-block" @click="saveInsurance">
                            <i class="fas fa-save"></i> Save
                        </button>
                    </div>
                    <div class="p-2" v-if="isEditInsurance && (($store.state.user.assigned_roles[0].name === 'SystemAdmin') || ($store.state.user.assigned_roles[0].name === 'HOP'))">
                        <button class="btn btn-danger btn-block" @click="deleteInsurance(insurance.id)">
                            <i class="fas fa-trash"></i> Delete Insurance
                            {{ deleteInsurance.id }}
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal id="import-insurance" title="Import Insurance" hide-footer>
            <div class="d-flex mt-2">
                <button class="btn btn-primary btn-block" @click="exportCsv">
                    Download CSV
                </button>
            </div>
            <hr />
            <div class="d-flex mt-2">
                <button class="btn btn-primary btn-block" @click="$refs['products-csv'].click()">
                    Upload CSV
                </button>
                <input id="products-csv" ref="products-csv" type="file" hidden
                    accept=".csv, .xls, .xlsx, text/csv, application/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.msexcel, text/anytext, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    @change="uploadCsv" />
            </div>
        </b-modal>

        <b-modal id="import-item-modal" hide-footer size="xl" :title="'Import Item'" no-close-on-backdrop>
            
            <div class="col-12 mt-2">
                <draggable v-model="import_product_items" group="import_product_items" @start="drag = true"
                    @end="drag = false">

                    <div class="row mb-2" v-for="(item, index) in import_product_items" :key="index">
                        <div class="col-1">
                            <div class="m-4 p-2">
                                <i class="fas fa-bars" style="color: grey"></i>
                            </div>
                        </div>
                        <div class="col-2">
                            <label class="text-primary">ID</label>
                            <input class="form-control" v-model="item.id">
                        </div>
                        <div class="col-4">
                            <label class="text-primary">Name</label>
                            <input class="form-control" v-model="item.name_en">
                        </div>
                        <div class="col-2">
                            <label class="text-primary">Type</label>
                            <MazSelect v-model="item.type" :placeholder="'Type'" :options="[
                                { label: '', value: '' },
                                { label: 'Inter', value: 'Inter' },
                                { label: 'Local', value: 'Local' },
                                { label: 'Other', value: 'Other' },
                            ]" />
                        </div>
                        <div class="col-2">
                            <label class="text-primary">Level</label>
                            <MazSelect v-model="item.level" :placeholder="'Level'" :options="[
                                { label: '', value: '' },
                                { label: 'BEST', value: 'BEST' },
                                { label: 'GOOD', value: 'GOOD' },
                                { label: 'NORMAL', value: 'NORMAL' },
                                { label: 'GRAYLIST', value: 'GRAYLIST' },
                                { label: 'TEMPORARY BAN', value: 'TEMPORARY BAN' },
                                { label: 'BLACKLIST', value: 'BLACKLIST' }]" />
                        </div>

                        <div class="col-1">
                            <button class="btn btn-light mt-4" @click="import_product_items.splice(index, 1)"><i
                                    class="fas fa-trash"></i></button>
                        </div>
                        <div class="col-1">

                        </div>
                        <div class="col-5 d-flex">
                            <label class="text-primary">Billed to</label>
                            <input class="form-control ml-3" v-model="item.billed_to">
                        </div>
                        <div class="col-5 d-flex">
                            <label class="text-primary">Note</label>
                            <input class="form-control ml-3" v-model="item.note">
                        </div>
                    </div>
                </draggable>
            </div>
            <div class="d-flex flex-row-reverse col-12 mt-2">
                <div class="p-3 col-3">
                    <button class="btn btn-success btn-block" @click="saveImport">
                        <i class="fas fa-save"></i> Save
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { db, storage } from "../../db"
import Parser from "@json2csv/plainjs/dist/cjs/Parser"
import draggable from "vuedraggable"
export default {
    components: {
        draggable,
    },
    data() {
        return {
            insurances: [],
            insurance: {},
            isEditInsurance: false,
            import_product_items: [],
            InsuranceColumns: [
                {
                    label: "Id",
                    field: "id",
                },
                {
                    label: "Logo",
                    field: "logo",
                    type: "html",
                },
                {
                    label: "Name",
                    field: "name_en",
                },
                {
                    label: "Level",
                    field: "level",
                    width: "180px",
                },
                {
                    label: "Type",
                    field: "type",
                },
                {
                    label: "Billed to",
                    field: "billed_to",
                },
                {
                    label: "Class",
                    field: "class",
                    width: "700px",

                },
                {
                    label: "Note",
                    field: "note",
                    width: "180px",
                }

            ],
        }
    },
    firestore() {
        return {
            insurances: db.collection("Insurance").withConverter({ toFirestore: (data) => data, fromFirestore: (snap) => ({ ...snap.data(), id: snap.id }) })
        }
    },
    mounted() {
        //this.migrate_data()
    },
    methods: {
        getLevelText(level) {
            switch(level) {
                case 'BEST':
                return 'BEST';
                case 'GOOD':
                return 'GOOD';
                case 'NORMAL':
                return 'NORMAL';
                case 'GRAYLIST':
                return 'GRAY';
                case 'TEMPORARY BAN':
                return 'TEMP BAN';
                case 'BLACKLIST':
                return 'BLACK';
                default:
                return '';
            }
        },
        async migrate_data() {
            console.log('Starting migration...')
            try {
                const patientRef = await db.collection('Patient').get()
                const docs = patientRef.docs

                const BATCH_SIZE = 400 // Firestore allows up to 500 operations per batch
                let batchCount = 0

                for (let i = 0; i < docs.length; i += BATCH_SIZE) {
                    const batch = db.batch()
                    const chunk = docs.slice(i, i + BATCH_SIZE)

                    for (const doc of chunk) {
                        const data = doc.data()

                        // Check if travel_insurance matches the reference path of 'II0034'
                        if (data.travel_insurance === '/Insurance/II034') {
                            batch.update(doc.ref, {
                                travel_insurance: '/Insurance/IL003'
                            })
                        }
                    }

                    await batch.commit()
                    batchCount++
                    console.log(`Batch ${batchCount} committed successfully.`)
                }
                console.log('Migration completed successfully!')
            } catch (error) {
                console.error('Error during migration:', error)
            }
        },

        async uploadLogo(event) {
            const file = event.target.files[0]
            if (!file) return

            try {
                const storageRef = storage.ref(`insurance/${this.insurance.id}/${file.name}`)
                const uploadTask = await storageRef.put(file)
                const downloadURL = await uploadTask.ref.getDownloadURL()

                this.insurance.logo = downloadURL
                this.$alert("Logo uploaded successfully!", null, "success")
            } catch (error) {
                console.error("Error uploading logo:", error)
                this.$alert("Failed to upload logo. Please try again.", null, "error")
            }
        },
        addClass() {
            this.insurance.class.push({ class: null, type: null, discountLog: null, credit_note: 0, put_on_invoice: 0, kick_back: 0 })
        },
        exportCsv() {
            let name = "insurance"
            let datas = this.insurances
            try {
                const selectedData = datas.map((data) => ({
                    id: data.id,
                    name_en: data.name_en,
                    type: data.type,
                    level: data.level || "",
                    billed_to: data.billed_to || "",
                    note: data.note || "",
                }))
                const parser = new Parser()
                const myData = JSON.parse(JSON.stringify(selectedData))
                const csv = parser.parse(myData)
                const bom = "\uFEFF";
                const csvWithBom = bom + csv;
                const anchor = document.createElement("a")
                anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csvWithBom)
                anchor.target = "_blank"
                anchor.download = "data_" + name + ".csv"
                anchor.click()
                anchor.remove()
            } catch (err) {
                console.error(err)
            }
        },
        async uploadCsv() {
            let promise = new Promise((resolve, reject) => {
                const reader = new FileReader()
                const fileSelector = document.getElementById("products-csv")
                const file = fileSelector.files[0]
                reader.onload = (e) => {
                    resolve((this.productsFileData = reader.result))
                }
                reader.onerror = reject
                reader.readAsText(file)
                fileSelector.value = ""
            })
            promise.then(async (result) => {
                this.preparedProducts = this.CSVToArray(this.productsFileData)
                this.preparedProducts = this.csvArrayToObj(this.preparedProducts)
                this.$bvModal.show("import-item-modal")
                this.import_product_items = this.preparedProducts
            })
        },
        async saveImport() {
            let loader = this.$loading.show({})
            const batchLimit = 500 // Firestore limit
            let batch = db.batch()
            let counter = 0

            try {
                for (const insurance of this.import_product_items) {
                    const docRef = db.collection("Insurance").doc(insurance.id)
                    batch.set(docRef, insurance, { merge: true })
                    counter++
                    // Commit the batch if it reaches the limit
                    if (counter === batchLimit) {
                        await batch.commit()
                        batch = db.batch() // Start a new batch
                        counter = 0 // Reset counter
                    }
                }
                // Commit any remaining writes in the final batch
                if (counter > 0) {
                    await batch.commit()
                }
                this.$alert("Import successful", null, "success")
            } catch (error) {
                this.$alert(`Error: ${error.message}`, null, "error")
            } finally {
                loader.hide()
                this.$bvModal.hide("import-item-modal")
            }
        },
        CSVToArray(strData, strDelimiter) {
            strDelimiter = (strDelimiter || ",")
            var objPattern = new RegExp(
                (
                    // Delimiters.
                    "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
                    // Quoted fields.
                    "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
                    // Standard fields.
                    "([^\"\\" + strDelimiter + "\\r\\n]*))"
                ),
                "gi"
            )
            var arrData = [[]]
            var arrMatches = null
            while (arrMatches = objPattern.exec(strData)) {
                var strMatchedDelimiter = arrMatches[1]
                if (
                    strMatchedDelimiter.length &&
                    strMatchedDelimiter !== strDelimiter
                ) {
                    arrData.push([])
                }
                var strMatchedValue;
                if (arrMatches[2]) {
                    strMatchedValue = arrMatches[2].replace(
                        new RegExp("\"\"", "g"),
                        "\""
                    )
                } else {
                    strMatchedValue = arrMatches[3]
                }
                arrData[arrData.length - 1].push(strMatchedValue)
            }
            return (arrData)
        },
        csvArrayToObj(csvData) {
            return csvData
                .map((csvLine, csvIndex) => {
                    if (csvIndex === 0 || !csvLine.length) return null // skip header and empty lines
                    return csvLine.reduce((a, v, i) => ({ ...a, [csvData[0][i]]: v }), {})
                })
                .filter((filter) => !!filter) //filter empty lines
        },
        showInsurance(params) {
            if (params) {
                if (!params.class) {
                    params.class = []
                }
                this.insurance = { ...params }
                this.isEditInsurance = true
            } else {
                this.isEditInsurance = false
                this.insurance = { class: [] }
            }
            this.$bvModal.show("insurance-item-modal")
        },
        async saveInsurance() {
            let loader = this.$loading.show({})
            if (!this.insurance.id || !this.insurance.name_en) {
                this.$alert("please enter id and name", null, "error")
                loader.hide()
            }
            try {
                let insurance = { ...this.insurance }
                await db
                    .collection("Insurance")
                    .doc(this.insurance.id)
                    .set(insurance)
                loader.hide()
                this.$alert("success", null, "success")                  
                this.$bvModal.hide("insurance-item-modal")
                              
            } catch (error) {
                this.$alert(`error ${error}`, null, "error")
                loader.hide()
            }
        },
        deleteInsurance(id) {
            this.$confirm("do you want to delete this insurance?").then(
                async () => {
                    let loader = this.$loading.show({})
                    await db.collection("Insurance").doc(id).delete()

                    loader.hide()
                    this.$bvModal.hide("insurance-item-modal")

                    this.$alert(`insurance ${id} was deleted.`, null, "success")
                }
            )
        },
    },

}
</script>


<style lang="scss">
.clickable {
    cursor: pointer;
    transition: color 0.3s ease;
    /* Optional: smooth color transition */
}

.clickable:hover {
    color: #0056b3;
    /* Adjust hover color */
    text-decoration: underline;
    /* Optional: underline on hover */
}

.insurance-table {

    .best-row {
        font-weight: bold;
        background-color: #007f5f;
        color: #fff;
        padding: 3px;
        border: 3px solid #007f5f;
        border-radius: 0.5em;
    }

    .good-row {
        font-weight: bold;
        background-color: #80b918;
        color: #fff;
        padding: 3px;
        border: 3px solid #80b918;
        border-radius: 0.5em;
    }

    .normal-row {
        font-weight: bold;
        background-color: #ffdc2e;
        color: black;
        padding: 3px;
        border: 3px solid #ffdc2e;
        border-radius: 0.5em;
    }

    .graylist-row {
        font-weight: bold;
        background-color: #5f6266;
        color: #fff;
        padding: 3px;
        border: 3px solid #5f6266;
        border-radius: 0.5em;
    }

    .temporary-row {
        font-weight: bold;
        background-color: #990000;
        color: white;
        padding: 3px;
        border: 3px solid #990000;
        border-radius: 0.5em;
    }

    .blacklist-row {
        font-weight: bold;
        background-color: #1b1b1e;
        color: #fff;
        padding: 3px;
        border: 3px solid #1b1b1e;
        border-radius: 0.5em;
    }

}
</style>