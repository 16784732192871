<template>
    <div class="container-fluid">
      <div class="card shadow">
        <div class="card-body">
          <div class="align-items-center ">
            <div class="box-bookMark-Product d-flex align-items-center justify-content-center">
              <h3 class="box-bookMark-Product">Product</h3>
            </div>
          </div>
          <!--<div class="text-muted">Product Management</div>-->  
          <b-nav class="mt-2" tabs>
            <b-nav-item :active="currentMenu === menu.name" v-for="(menu, index) in menus" :key="index"
              @click="currentMenu = menu.name">{{ menu.name }}</b-nav-item>
          </b-nav>
  
          <div v-if="currentMenu === 'Product'">
            <product-tab />  
          </div>
  
          <div v-if="currentMenu === 'Product Summary'">
            <productsummary-tab />  
          </div>

          <div v-if="currentMenu === 'Product Transfer'">
            <producttransfer-tab />
          </div>

          <div v-if="currentMenu === 'Transfer Report'">
            <transferreport-tab />
          </div>

        </div>
  
      </div>
      
    </div>
  </template>
  
  <script>

  import Product from './Product.vue'
  import ProductSummary from './ProductSummary.vue'
  import ProductTransfer from './ProductTransfer.vue'
  import TransferReport from './TransferReport.vue'
  export default {
    components: {      
      "product-tab": Product,
      "productsummary-tab": ProductSummary,
      "producttransfer-tab": ProductTransfer,
      "transferreport-tab": TransferReport,
    },
    data() {
      return {
        currentMenu: "Product",        
      }
    },
    firestore() {
      return {
      }
    },
    methods: {    
      
    },
    computed: {      
      menus() {
        const menus = [
          { name: "Product" },
          { name: "Product Summary" },
          { name: "Product Transfer" },
          { name: "Transfer Report"}
        ]
        return menus
      },
    },
  }
  </script>
<style lang="css">
  .box-bookMark-Product {
    width: 200px;
    height: 100px;
    background-color: #2F6EBA;
    margin-top: -11px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box-bookMark-Product h3{
    color: #ffffff;
  }
</style>