<template>
  <div class="container-fluid">
    <div class="d-flex">
      <button class="btn mr-2 shadow p-3" v-for="(menu, index) in menus" :key="`menu${index}`"
        @click="$router.push(menu.path)" :class="{ 'btn-primary': $route.path === menu.path }">
        <div><b>{{ menu.name }}</b></div>
        <div v-if="menu.name !== 'Overall'">{{ Math.round(menu.amount).toLocaleString() }}</div>
      </button>

      <button class="ml-auto btn btn-light shadow p3">
        <b>INCOME: {{ Math.round(menus[0].amount).toLocaleString() }}</b>
      </button>
    </div>
    <hr>

    <router-view :payments="filteredItems" :period="filter.period" :invoices="filteredInvoices"></router-view>

  </div>
</template>

<script>
import { db } from '../../db'
export default {
  props: ['filter', 'period'],
  data() {
    return {
      payments: [],
      invoices: [],
      from: this.$moment().add(-1, 'day').format('DD/MM/YYYY'),
      to: this.$moment().format('DD/MM/YYYY'),
      initMenus: [
        { name: 'Overall', path: '/billing/billing-dashboard', amount: 0 },
        { name: 'Cash', path: '/billing/billing-dashboard/cash', amount: 0 },
        { name: 'Card', path: '/billing/billing-dashboard/card', amount: 0 },
        { name: 'Internet Banking', path: '/billing/billing-dashboard/internetbanking', amount: 0 },
        { name: 'Insurance', path: '/billing/billing-dashboard/insurance', amount: 0 },
        { name: 'Others', path: '/billing/billing-dashboard/others', amount: 0 },

      ]
    }
  },

  methods: {
    async fetchData() {
      const loader = this.$loading.show({})
      try {
        const start = this.$moment(this.filter.from, "DD/MM/YYYY").startOf("day").toDate()
        const end = this.$moment(this.filter.to, "DD/MM/YYYY").endOf("day").toDate()
        // Fetch Invoices within the date range
        let invoicesSnapshot = await db
          .collection("Invoice")
          .where("issue_date", ">", start)
          .where("issue_date", "<", end)
          .orderBy("issue_date", "desc")
          .onSnapshot(snapshot => {
            this.invoices = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          })

        //this.invoices = invoicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        // Fetch Payments within the date range
        let paymentsSnapshot = await db
          .collection("Payment")
          .where("issue_date", ">", start)
          .where("issue_date", "<", end)
          .orderBy("issue_date", "desc")
          .onSnapshot(snapshot => {
            this.payments = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          })



        //this.payments = paymentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))

      } catch (error) {
        console.error("Error fetching data:", error)
      }
      loader.hide()
    },


  },

  computed: {
    menus() {
      const data = this.initMenus
      data[0].amount = 0
      data[1].amount = 0
      data[2].amount = 0
      data[3].amount = 0
      data[4].amount = 0
      data[5].amount = 0

      for (let item of this.filteredItems) {
        data[0].amount += (item.cash + item.credit + item.insurance + item.other + item.internet_banking)
        data[1].amount += item.cash
        data[2].amount += item.credit
        data[3].amount += item.internet_banking
        data[4].amount += item.insurance
        data[5].amount += item.other
      }

      return data
    },
    filteredItems() {
      let from = this.$moment(this.filter.from, 'DD/MM/YYYY')
      let to = this.$moment(this.filter.to, 'DD/MM/YYYY')

      let filter_payment = null

      if (this.$store.state.branch != 3) {
        filter_payment = this.payments.filter(doc => {
          return doc.payment_id.slice(0, 2) !== "03"
        })
      } else {
        filter_payment = this.payments.filter(doc => {
          return doc.payment_id.slice(0, 2) === "03"
        })
      }

      return this._.filter(filter_payment, item => {
        if (item.issue_date) {
          return this.$moment(item.issue_date.toDate()).isAfter(from.startOf('day')) && this.$moment(item.issue_date.toDate()).isBefore(to.endOf('day'))
        } else {
          return this.$moment(item.created_at.toDate()).isAfter(from.startOf('day')) && this.$moment(item.created_at.toDate()).isBefore(to.endOf('day'))
        }
      })
    },
    filteredInvoices() {
      let from = this.$moment(this.filter.from, 'DD/MM/YYYY')
      let to = this.$moment(this.filter.to, 'DD/MM/YYYY')
      let filter_invoice = null

      if (this.$store.state.branch != 3) {
        filter_invoice = this.invoices.filter(doc => {
          return doc.invoice_id.slice(0, 2) !== "03"
        })
      } else {
        filter_invoice = this.invoices.filter(doc => {
          return doc.invoice_id.slice(0, 2) === "03"
        })
      }

      return this._.filter(filter_invoice, item => {
        if (item.issue_date) {
          return this.$moment(item.issue_date.toDate()).isAfter(from.startOf('day')) && this.$moment(item.issue_date.toDate()).isBefore(to.endOf('day'))
        } else {
          return this.$moment(item.created_at.toDate()).isAfter(from.startOf('day')) && this.$moment(item.created_at.toDate()).isBefore(to.endOf('day'))
        }
      })
    }

  },
  watch: {
    filter: {
      handler() {
        this.fetchData()
      },
      deep: true
    }
  },
}
</script>