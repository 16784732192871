<template>
  <div>
    <table class="table table-bordered bg-white text-center data.form-table" v-if="preparedShortBillableItems.length > 0">
        <thead class="bg-table-header-print" style="color: #306797;">
          <tr>
            <th width="40%" class="text-left">Services</th>
            <th width="15%" v-if="totalDiscount > 0">Price</th>
            <th width="10%" v-if="totalDiscount > 0">Discount</th>
            <th width="10%" >Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in preparedShortBillableItems" :key="row.id" >
            <td class="text-left">{{ row.item_name }}</td>
            <td class="text-right" v-if="totalDiscount > 0">฿{{ Number(row.price).toLocaleString(undefined) }}</td>
            <td class="text-right" v-if="totalDiscount > 0">฿{{ Number(row.discount).toLocaleString(undefined) }}</td>
            <td class="text-right">฿{{ Number(row.total).toLocaleString(undefined) }}</td>
          </tr>
        </tbody>
      </table>
      <table class="table table-bordered bg-white text-center data.form-table" v-if="preparedShortProduct.length > 0">
        <thead class="bg-table-header-print" style="color: #306797;">
          <tr>
            <th width="40%" class="text-left">Product Item</th>
            <th width="15%" v-if="totalDiscount > 0">Price</th>
            <th width="10%" v-if="totalDiscount > 0">Discount</th>
            <th width="10%">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in preparedShortProduct" :key="row.id">
            <td class="text-left">{{ row.item_name }}</td>
            <td class="text-right" v-if="totalDiscount > 0">฿{{ Number(row.price).toLocaleString(undefined) }}</td>
            <td class="text-right" v-if="totalDiscount > 0">฿{{ Number(row.discount).toLocaleString(undefined) }}</td>
            <td class="text-right">฿{{ Number(row.total).toLocaleString(undefined) }}</td>
          </tr>
        </tbody>
      </table>
  </div>
</template>

<script>
export default { 
  props: ['data'],
  computed: {
    preparedShortProduct() {
      let data = [
        { item_name: 'LABORATORY', price: 0, discount: 0, total: 0},
        { item_name: 'IV MED BAG', price: 0, discount: 0, total: 0},
        { item_name: 'MEDICINE', price: 0, discount: 0, total: 0},
        { item_name: 'SUPPLY', price: 0, discount: 0, total: 0},
        { item_name: 'DURABLE MEDICAL EQUIPMENT', price: 0, discount: 0, total: 0},
      ]

      for(let item of this.data.product_items) {
        if(item.item_code.slice(0,1) === 'L') {
          let price = item.price*item.quantity
          let discount = item.price*item.quantity*(item.discount/100)
          data[0].price += price
          data[0].discount += discount
          data[0].total += price - discount
        }
        if(item.item_code.slice(0,3) === 'IVB') {
          let price = item.price*item.quantity
          let discount = item.price*item.quantity*(item.discount/100)
          data[1].price += price
          data[1].discount += discount
          data[1].total += price - discount
        }
        if(item.item_code.slice(0,1) === 'M') {
          let price = item.price*item.quantity
          let discount = item.price*item.quantity*(item.discount/100)
          data[2].price += price
          data[2].discount += discount
          data[2].total += price - discount
        }
        if(item.item_code.slice(0,1) === 'S') {
          let price = item.price*item.quantity
          let discount = item.price*item.quantity*(item.discount/100)
          data[3].price += price
          data[3].discount += discount
          data[3].total += price - discount
        }
        if(item.item_code.slice(0,3) === 'DME') {
          let price = item.price*item.quantity
          let discount = item.price*item.quantity*(item.discount/100)
          data[4].price += price
          data[4].discount += discount
          data[4].total += price - discount
        }
      }

      return this._.filter(data, d => {
        return d.total>0
      })
    },
    preparedShortBillableItems() {
      let data = [
        { item_name: 'DOCTOR EVALUATION FEE', price: 0, discount: 0, total: 0},
        { item_name: 'NURSING FEE', price: 0, discount: 0, total: 0},
        { item_name: 'PACKAGED MEDICAL CHARGE', price: 0, discount: 0, total: 0},
        { item_name: 'DOCTOR PROCEDURE FEE', price: 0, discount: 0, total: 0},
        { item_name: 'DOCTOR ESCORT', price: 0, discount: 0, total: 0},
        { item_name: 'NURSE ESCORT', price: 0, discount: 0, total: 0},
        { item_name: 'SPECIAL EQUIPMENTS', price: 0, discount: 0, total: 0},
        { item_name: 'AMBULANCE TRANSPORTATION', price: 0, discount: 0, total: 0},
        { item_name: 'PATIENT ACCOMMODATION', price: 0, discount: 0, total: 0},
        { item_name: 'OTHERS', price: 0, discount: 0, total: 0},
      ]

      for(let item of this.data.billable_items) {
        for(let i = 0 ; i <= 9; i++) {
          if(item.item_code.slice(0,2) === `F${i}`) {
            let price = item.price*item.quantity
            let discount = item.price*item.quantity*(item.discount/100)
            data[i].price += price
            data[i].discount += discount
            data[i].total += price - discount
          }
        }
      }

      return this._.filter(data, d => {
        return d.total>0 || d.discount>0
      })
    },
    totalDiscount() {
      let sum_product = _.sum(this.preparedShortBillableItems.map(item => {
        return Number(item.discount)
      }))
      let sum_billable_items = _.sum(this.preparedShortProduct.map(item => {
        return Number(item.discount)
      }))

      return sum_product + sum_billable_items
    },
  }
}
</script>

<style scoped lang="css">
.receipt-form {
  font-size: 13px;
}
.receipt-form-table {
  font-size: 11px;
}
.table th, .table td {
  padding: .3rem;
}

@page {
    size: A4;
    margin: 5mm 0mm ;
}
.sheet {
    overflow: visible;
    height: auto !important;
}
</style>