<template>
    <div class="container-fluid mt-2">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex mt-2 mb-2">
              <div class="mr-auto">
                <h4 class="text-primary">Endorsement</h4>
              </div>            
            </div>
             
          <date-filter v-model="filter" ></date-filter>
        
          <b-nav class="row mt-3" tabs v-if="!$route.meta.print">
            <b-nav-item :active="(currentMenu === menu.name)" v-for="menu in menus" :key="menu.name"
              @click="(currentMenu = menu.name)" class="mt-2">{{ menu.name }}</b-nav-item>
          </b-nav>
          <ib_endorsement v-if="currentMenu === 'IB Endorsement'"/>
          <ph_endorsement v-if="currentMenu === 'PH Endorsement'"/>
          <rn_endorsement v-if="currentMenu === 'RN Endorsement'"/>  
          
        </div>
      </div>   
      
    </div>
  </template>
  
  <script>
 
  
  import IB_Endorsement from './IB_Endorsement.vue'
  import PH_Endorsement from './PH_Endorsement.vue'
  import RN_Endorsement from './RN_Endorsement.vue'

  
  export default {
    components: {
      'ib_endorsement': IB_Endorsement,
      'ph_endorsement': PH_Endorsement,
      'rn_endorsement': RN_Endorsement,
    },
  
    data() {
      return {
        currentMenu: 'IB Endorsement',
        menus: [
          { name: 'IB Endorsement' },
          { name: 'PH Endorsement' },
          { name: 'RN Endorsement' },
    
        ],
        
        filter: {
          from: this.$moment().format('DD/MM/YYYY'),
          to: this.$moment().format('DD/MM/YYYY'),
          period: {
            name: 'Day', from: this.$moment().add(-1, 'day').format('DD/MM/YYYY'), to: this.$moment().format('DD/MM/YYYY'),
            income: 'Daily',
            total: 'TODAY\'S'
          },
        }
      }
    },
    
    methods: {
    
    },
  }
  </script>