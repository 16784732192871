<template>
    <div class="mt-4">
        audit page
        {{ start }} {{ end }}
    </div>
</template>
<script>

import { db } from "../../../db"

export default {
  props: ["start", "end"],
  data() {
    return {
      
    }
  },
  mounted() {
   
  },
  methods: {
   
  },
  watch: {
    
  },
}
</script>