import Endorsement from "../pages/Endorsement/";


export default [
  {
    path: "/endorsement",
    name: "Endorsement",
    component: Endorsement,
  },

]