<template>
  <div>
    <section class="sheet p-3" style="pointer-events: none">
      <div class="label text-center" style="font-size: 26px;">
        {{ $t("pageTitle") }}
      </div>
      <div>
        <div class="d-flex">
          <div class="label">
            <div v-if="branch">
              {{ $t("branch_select") }}: {{ branch.name_en }}
            </div>
            <h5>
              HN: {{ patient.HN }}
            </h5>
          </div>
          <div class="ml-auto label">
            <div>{{ $t("visit_date") }}</div>
            <div class="form-control">{{ getVisitDate }}</div>
          </div>
        </div>
        <div class="mt-2 bg-gray p-2">
          <div class="d-flex">
            <div class="flex-1">
              <div class="label-head">
                <b>{{ $t("patient_info") }}</b>
              </div>
              <div class="profile-box mt-3">
                <img v-if="patient.img_url" class="img-preview" :src="patient.img_url" height="150" />
                <p v-if="!patient.img_url">
                  <i class="fa fa-camera-retro" aria-hidden="true"
                    style="font-size: 40px; color: rgba(0, 0, 0, 0.1);"></i>
                </p>
              </div>
            </div>
            <div class="flex-5 mt-3">
              <div class="d-flex">
                <div class="flex-1">
                  <div class="label">
                    <b>{{ $t("title") }}</b>
                  </div>
                  <div id="title" class="form-control">
                    {{ patient.title ? patient.title : "-" }}
                  </div>
                </div>
                <div class="flex-2 ml-1">
                  <div class="label">
                    <b>{{ $t("firstName") }}</b>
                  </div>
                  <div id="first_name" class="form-control">
                    {{ patient.first_name ? patient.first_name : "-" }}
                  </div>
                </div>
                <div class="flex-2 ml-1">
                  <div class="label">
                    <b>{{ $t("lastName") }}</b>
                  </div>
                  <div id="last_name" class="form-control">
                    {{ patient.last_name ? patient.last_name : "-" }}
                  </div>
                </div>
                <div class="flex-2 ml-1">
                  <div class="label">
                    <b>{{ $t("dob") }}</b>
                  </div>
                  <div id="date_of_birth" class="form-control">
                    {{
                      patient.date_of_birth
                        ? patient.date_of_birth.toDate()
                        : "-" | moment("DD/MM/YYYY")
                    }}
                  </div>
                </div>
                <div class="flex-1 ml-1">
                  <div class="label">
                    <b>{{ $t("age") }}</b>
                  </div>
                  <div id="age" class="form-control">
                    {{
                      displayAge
                    }}
                  </div>
                </div>
                <div class="flex-1 ml-1">
                  <div class="label">
                    <b>{{ $t("gender") }}</b>
                  </div>
                  <div id="gender" class="form-control">
                    {{ patient.gender ? patient.gender : "-" }}
                  </div>
                </div>
              </div>
              <div class="d-flex mt-2">
                <div class="flex-1">
                  <div class="label">
                    <b>{{ $t("marital_status") }}</b>
                  </div>
                  <div id="marital_status" class="form-control">
                    {{ patient.marital_status ? patient.marital_status : "-" }}
                  </div>
                </div>
                <div class="flex-1 ml-1">
                  <div class="label">
                    <b>{{ $t("nation") }}</b>
                  </div>
                  <div id="nationality" class="form-control">
                    {{ patient.nationality ? patient.nationality : "-" }}
                  </div>
                </div>
                <div class="flex-2 ml-1">
                  <div class="label">
                    <b>{{ $t("id_no") }}</b>
                  </div>
                  <div id="id_card" class="form-control">
                    {{ patient.passport_id ? patient.passport_id : "-" }}
                  </div>
                </div>
                <div class="flex-2 ml-1">
                  <div class="label">
                    <b>{{ $t("occupation") }}</b>
                  </div>
                  <div id="occupation" class="form-control">
                    {{ patient.occupation ? patient.occupation : "-" }}
                  </div>
                </div>
                <div class="flex-1 ml-1">
                  <div class="label">
                    <b>{{ $t("religion") }}</b>
                  </div>
                  <div id="religion" class="form-control">
                    {{ patient.religion ? patient.religion : "-" }}
                  </div>
                </div>
                <div class="flex-1 ml-1">
                  <div class="label">
                    <b>{{ 'Blood group' }}</b>
                  </div>
                  <div id="occupation" class="form-control">
                    {{ patient.blood_group ? patient.blood_group : "-" }}
                  </div>
                </div>
              </div>
              <div class="d-flex mt-2">
                <div class="flex-1">
                  <div class="label">
                    <b>{{ $t("phone_th") }}</b>
                  </div>
                  <div id="phone_th" class="form-control">
                    {{ patient.phone_th ? patient.phone_th : "-" }}
                  </div>
                </div>
                <div class="flex-1 ml-1">
                  <div class="label">
                    <b>{{ $t("phone_inter") }}</b>
                  </div>
                  <div id="phone_inter" class="form-control">
                    {{ patient.phone_inter ? patient.phone_inter : "-" }}
                  </div>
                </div>
                <div class="flex-1 ml-1">
                  <div class="label">
                    <b>{{ $t("email") }}</b>
                  </div>
                  <div id="email" class="form-control">
                    {{ patient.email ? patient.email : "-" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-red p-2 mt-2">
          <div class="flex-1 mb-2">
            <div class="label">
              <b>{{ $t("symptom") }}</b>
            </div>
            <div id="symptom" class="form-control">
              {{ patient.symptom ? patient.symptom : "-" }}
            </div>
          </div>
          <span class="label"><b>{{ $t("allergy") }}</b></span>
          <div class="d-flex">
            <div class="flex-1">
              <div class="d-flex">
                <div class="flex-1">
                  <label class="label" for="Drug Allergy">{{
                    $t("drug_allergy")
                  }}</label>
                </div>
                <div class="flex-fill mt-1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="is_allergy" id="allergy_yes" :value="true"
                      v-model="patient.is_allergy" />
                    <label class="form-check-label label" for="allergy_yes" v-bind:class="{
                      'fade-noselect': patient.is_allergy === false
                    }">{{ $t("yes") }}</label>
                  </div>
                  <div class="form-check form-check-inline pl-5">
                    <input class="form-check-input" type="radio" name="is_allergy" id="allergy_no" :value="false"
                      v-model="patient.is_allergy" />
                    <label class="form-check-label label" for="allergy_no" v-bind:class="{
                      'fade-noselect': patient.is_allergy === true
                    }">{{ $t("no") }}</label>
                  </div>
                </div>
              </div>
              <ul>
                <li class="text-danger" :key="index" v-for="(item, index) in allergies">
                  <div>{{ item.name }}</div>
                  <!-- <small class="text-muted">{{item.reactions}}</small> -->
                </li>
              </ul>
            </div>
            <div class="flex-1 ml-3">
              <div class="d-flex">
                <div class="flex-1">
                  <label class="label" for="Drug Allergy">{{
                    $t("other_allergy")
                  }}</label>
                </div>
                <div class="flex-fill mt-1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="is_other_allergy" id="other_allergu_yes"
                      :value="true" v-model="patient.is_other_allergy" />
                    <label class="form-check-label label" for="other_allergu_yes" v-bind:class="{
                      'fade-noselect': patient.is_other_allergy === false
                    }">{{ $t("yes") }}</label>
                  </div>
                  <div class="form-check form-check-inline pl-5">
                    <input class="form-check-input" type="radio" name="is_other_allergy" id="other_allergu_no"
                      :value="false" v-model="patient.is_other_allergy" />
                    <label class="form-check-label label" for="other_allergu_no" v-bind:class="{
                      'fade-noselect': patient.is_other_allergy === true
                    }">{{ $t("no") }}</label>
                  </div>
                </div>
              </div>
              <ul>
                <li class="text-danger" :key="index" v-for="(item, index) in other_allergies">
                  <div>{{ item.name }}</div>
                  <!-- <small class="text-muted">{{item.reactions}}</small> -->
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="bg-gray mt-2 p-2">
          <div class="d-flex">
            <div class="flex-2">
              <div class="label">
                <b>{{ $t("home_addr") }}</b>
              </div>
              <div id="address" class="form-control">
                {{ patient.home_address ? patient.home_address : "-" }}
              </div>
            </div>
            <div class="flex-1 ml-1">
              <div class="label">
                <b>{{ $t("city") }}</b>
              </div>
              <div id="city" class="form-control">
                {{ patient.home_city ? patient.home_city : "-" }}
              </div>
            </div>
            <div class="flex-1 ml-1">
              <div class="label">
                <b>{{ $t("country") }}</b>
              </div>
              <div id="country" class="form-control">
                {{ patient.home_country ? patient.home_country : "-" }}
              </div>
            </div>
            <div class="flex-1 ml-1">
              <div class="label">
                <b>{{ $t("zipcode") }}</b>
              </div>
              <div id="zipcode" class="form-control">
                {{ patient.home_zipcode ? patient.home_zipcode : "-" }}
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="label">
              <b>{{ $t("type_stay_phiphi") }}</b>
            </div>
          </div>
          <div class="d-flex mt-2">
            <div class="flex-1" v-if="patient.is_tourist === true">
              <div>
                <div class="form-check">
                  <input class="form-check-input my-auto" type="checkbox" id="is_tourist"
                    v-model="patient.is_tourist" />
                  <label class="form-check-label label my-auto" for="is_tourist">{{
                    $t("tourist")
                  }}</label>
                </div>
              </div>
              <div class="d-flex mt-1">
                <div class="flex-fill">
                  <div class="label">
                    <b>{{ $t("arrival_date_in") }}</b>
                  </div>
                  <div id="arrival_date" class="form-control">
                    {{
                      patient.arrival_date ?
                        patient.arrival_date.toDate() : "-" | moment("DD/MM/YYYY")
                    }}
                  </div>
                </div>
                <div class="flex-fill ml-1">
                  <div class="label">
                    <b>{{ $t("departure_date_out") }}</b>
                  </div>
                  <div id="departure_date" class="form-control">
                    {{
                      patient.departure_date ?
                        patient.departure_date.toDate() : "-" | moment("DD/MM/YYYY")
                    }}
                  </div>
                </div>
                <div class="flex-fill ml-1">
                  <div class="label">
                    <b>{{ $t("hotel_name") }}</b>
                  </div>
                  <div id="present_addr" class="form-control">
                    <span v-if="patient.tourist_hotel !== 'Other'">{{ patient.tourist_hotel ? patient.tourist_hotel :
                      "-" }}</span>
                    <span v-if="patient.tourist_hotel === 'Other'">{{ patient.tourist_hotel_other ?
                      patient.tourist_hotel_other : "-" }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-1 ml-4" v-if="patient.is_resident === true">
              <div>
                <div class="form-check">
                  <input class="form-check-input my-auto" type="checkbox" id="is_resident"
                    v-model="patient.is_resident" />
                  <label class="form-check-label label my-auto" for="is_resident">{{
                    $t("resident")
                  }}</label>
                </div>
              </div>
              <div class="d-flex mt-1">
                <div class="flex-fill">
                  <div class="label">
                    <b>{{ $t("work_place") }}</b>
                  </div>
                  <div id="work_place" class="form-control">
                    {{
                      patient.resident_workplace
                        ? patient.resident_workplace
                        : "-"
                    }}
                  </div>
                </div>
                <div class="flex-fill ml-1">
                  <div class="label">
                    <b>{{ $t("resident_name") }}</b>
                  </div>
                  <div id="resident_name" class="form-control">
                    <span v-if="patient.resident_hotel !== 'Other'">{{ patient.resident_hotel ? patient.resident_hotel :
                      "-" }}</span>
                    <span v-if="patient.resident_hotel === 'Other'">{{ patient.resident_hotel_other ?
                      patient.resident_hotel_other : "-" }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="label mt-2">
            <b>{{ $t("emergency_contact") }}</b>
          </div>
          <div class="d-flex mt-1">
            <div class="flex-fill">
              <div class="label">
                <b>{{ $t("firstName") }}</b>
              </div>
              <div id="emergency_first_name" class="form-control">
                {{
                  patient.emergency_first_name
                    ? patient.emergency_first_name
                    : "-"
                }}
              </div>
            </div>
            <div class="flex-fill ml-1">
              <div class="label">
                <b>{{ $t("lastName") }}</b>
              </div>
              <div id="emergency_last_name" class="form-control">
                {{
                  patient.emergency_last_name
                    ? patient.emergency_last_name
                    : "-"
                }}
              </div>
            </div>
            <div class="flex-fill ml-1">
              <div class="label">
                <b>{{ $t("relation") }}</b>
              </div>
              <div id="relation" class="form-control">
                {{
                  patient.emergency_relationship
                    ? patient.emergency_relationship
                    : "-"
                }}
              </div>
            </div>
            <div class="flex-fill ml-1">
              <div class="label">
                <b>{{ $t("phone") }}</b>
              </div>
              <div id="phone" class="form-control">
                {{ patient.emergency_phone ? patient.emergency_phone : "-" }}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="flex-2">
              <div class="label">
                <b>{{ $t("home_addr") }}</b>
              </div>
              <div id="address" class="form-control">
                {{
                  patient.emergency_address ? patient.emergency_address : "-"
                }}
              </div>
            </div>
            <div class="flex-1 ml-1">
              <div class="label">
                <b>{{ $t("city") }}</b>
              </div>
              <div id="city" class="form-control">
                {{ patient.emergency_city ? patient.emergency_city : "-" }}
              </div>
            </div>
            <div class="flex-1 ml-1">
              <div class="label">
                <b>{{ $t("country") }}</b>
              </div>
              <div id="country" class="form-control">
                {{
                  patient.emergency_country ? patient.emergency_country : "-"
                }}
              </div>
            </div>
            <div class="flex-1 ml-1">
              <div class="label">
                <b>{{ $t("zipcode") }}</b>
              </div>
              <div id="zipcode" class="form-control">
                {{
                  patient.emergency_zipcode ? patient.emergency_zipcode : "-"
                }}
              </div>
            </div>
          </div>
          <div class="d-flex mt-2">
            <div class="flex-fill">
              <div style="margin-top: 5px;">
                <span class="label"><b>{{ $t("insurance") }}</b></span>
                <div class="form-check form-check-inline ml-3">
                  <input class="form-check-input" type="radio" value="yes" v-model="patient.has_travel_insurance" />
                  <label class="form-check-label label" v-bind:class="{
                    'fade-noselect': patient.has_travel_insurance !== 'yes'
                  }">{{ $t("yes") }}</label>
                </div>
                <div class="form-check form-check-inline ml-2">
                  <input class="form-check-input" type="radio" value="no" v-model="patient.has_travel_insurance" />
                  <label class="form-check-label label" v-bind:class="{
                    'fade-noselect': patient.has_travel_insurance !== 'no'
                  }">{{ $t("no") }}</label>
                </div>
                <div class="form-check form-check-inline ml-2">
                  <input class="form-check-input" type="radio" value="notsure" v-model="patient.has_travel_insurance" />
                  <label class="form-check-label label" v-bind:class="{
                    'fade-noselect': patient.has_travel_insurance !== 'notsure'
                  }">{{ $t("not_sure") }}</label>
                </div>
              </div>
              <div style="margin-top: 7px;" id="insurance" class="form-control"
                v-if="patient.has_travel_insurance === 'yes' && travel_insurance">
                {{ travel_insurance ? travel_insurance.name_en : "-" }}
              </div>
              <div v-else>
                <div style="margin-top: 7px;" id="insurance" class="form-control"
                  v-if="patient.has_travel_insurance === 'yes' && patient.travel_insurance_other">
                  {{ patient.travel_insurance_other ? patient.travel_insurance_other : "-" }}
                </div>
              </div>
            </div>
            <div class="flex-fill ml-1 mt-3">
              <div class="label">
                <b>{{ $t("how_did_you_find_us") }}</b>
              </div>
              <div class="form-control" id="how_did_you_find_us">
                <span v-for="(i, index) in patient.how_did_you_find_us" :key="index">{{ i }}
                  <span v-if="index < patient.how_did_you_find_us.length - 1">,
                  </span>
                </span>
              </div>
            </div>
            <div class="flex-fill ml-1 mt-3">
              <div class="label">
                <b>{{ $t("note") }}</b>
              </div>
              <div id="note" class="form-control">
                {{ patient.note ? patient.note : "-" }}
              </div>
            </div>
          </div>
          <div class="mt-2 box-border bg-white" style="width: 40%;">
            <div class="form-check label">
              <input class="form-check-input" type="checkbox" value id="is_certify" v-model="patient.is_certify" />
              <label class="form-check-label mt-1" for="is_certify">{{
                $t("certify_check")
              }}</label>
            </div>
          </div>
          <div class="sign-border bg-white" style="text-align: center; width: 40%;">
            <span class="text-muted label">{{ $t("signature") }}</span>
            <div v-if="patient.signature_url">
              <img :src="patient.signature_url" height="150" />
            </div>
          </div>
          <div class="sign-detail label">
            <div class="row">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="certify_relation" id="sign_patient" value="Patient"
                  v-model="patient.certify_relation" />
                <label class="form-check-label" for="sign_patient">{{
                  $t("patient")
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="certify_relation" id="sign_parents" value="Parent"
                  v-model="patient.certify_relation" />
                <label class="form-check-label" for="sign_parents">{{
                  $t("parent")
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="certify_relation" id="sign_spouse" value="Spouse"
                  v-model="patient.certify_relation" />
                <label class="form-check-label" for="sign_spouse">{{
                  $t("spouse")
                }}</label>
              </div>
            </div>
            <div class="row mt-2">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="certify_relation" id="sign_other" value="Other"
                  v-model="patient.certify_relation" />
                <label class="form-check-label" for="sign_other">{{
                  $t("other")
                }}</label>
              </div>
              <div style="width: 70%" class="form-check form-check-inline" v-if="
                patient.certify_relation === 'Other' &&
                patient.certify_relation_other
              ">
                <div class="form-control">
                  {{
                    patient.certify_relation_other
                      ? patient.certify_relation_other
                      : "-"
                  }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="label">
                <b>{{ $t("fullname_information") }}</b>
              </div>
              <div class="form-control">
                {{
                  patient.patient_full_name ? patient.patient_full_name : "-"
                }}
              </div>
            </div>
            <div class="row mt-2">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="certify_relation" id="Staff" value="Staff"
                  v-model="patient.certify_relation" />
                <label class="form-check-label" for="Staff">{{
                  $t("Staff")
                }}</label>
                <div class="ml-3" v-if="patient.certify_relation === 'Staff'">
                  <div class="form-control">
                    {{
                      patient.registerLog
                        ? patient.registerLog.join(", ")
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { db } from "../../db";
import moment from "moment";
export default {
  data() {
    return {
      patient: {},
      branch: null,
      allergies: [],
      other_allergies: [],
      displayAge: null,

    };
  },
  async created() {
    let loader = this.$loading.show({});
    try {
      let patientId = this.$route.params.id;
      let patient = await db
        .collection("Patient")
        .doc(patientId)
        .get();

      if (patient.data().branch) {
        let bRef = await db
          .collection("Branch")
          .doc(`${patient.data().branch}`)
          .get();
        this.branch = { ...bRef.data() };
      }

      if (patient.data().allergies.length > 0) {
        this.allergies = await Promise.all(
          patient.data().allergies.map(async item => {
            let allergyRef = await db
              .collection("Allergy")
              .doc(`${item.id}`)
              .get();
            return {
              ...allergyRef.data()
            };
          })
        );
      }
      if (patient.data().other_allergies.length > 0) {
        this.other_allergies = await Promise.all(
          patient.data().other_allergies.map(async item => {
            let allergyRef = await db
              .collection("Allergy")
              .doc(`${item.id}`)
              .get();
            return {
              ...allergyRef.data()
            };
          })
        );
      }

      if (
        patient.data().has_travel_insurance === "yes" &&
        patient.data().travel_insurance
      ) {
        let ref = await db
          .collection("Insurance")
          .doc(patient.data().travel_insurance.id)
          .get();
        this.travel_insurance = { ...ref.data() };
      }
      if (patient.data().date_of_birth) {
        console.log('patient.data().date_of_birth: ', patient.data().date_of_birth);
        let now = moment();
        let bd = moment(patient.data().date_of_birth.toDate());
        console.log('bd: ', bd);
        let diff = now.diff(bd, "months");
        console.log('diff: ', diff);
        // let day_diff = now.diff(bd, "days");
        let displayYear = `${Math.floor(diff / 12)}y`
        console.log('displayYear: ', displayYear);
        let displayMonth = diff % 12 !== 0 ? diff % 12 + 'm' : ''
        this.displayAge = `${displayYear} ${displayMonth}`
        console.log('displayAge: ', this.displayAge);
        console.log('patient.data().age: ', patient.data().age);
      }
      this.patient = {
        ...patient.data()
      };
      console.log('this.patient: ', this.patient);
    } catch (error) {
      console.error(error);
    }
    loader.hide();
  },
  mounted() {
    document.body.className = "A4";
  },
  computed: {
    getVisitDate() {
      return this.$moment(this.patient?.visit_date?.toDate()).format("DD/MM/YYYY HH:mm");
    }
  }
};
</script>
<style lang="scss" scoped>
div {
  font-size: 9px;
}

.form-control {
  padding: 5px;
  font-size: 9px;
  height: 26px;
}

.label {
  font-size: 9px;
}

.label-head {
  font-size: 12px;
}

.text-head {
  font-size: 9px;
}

.box {
  border: 1px solid #adadad;
}

.title-box {
  padding: 10px;
  border-bottom: 1px solid #adadad;
  background-color: #ededed;
}

.content-box {
  padding: 10px;
  min-height: 50px;
}

.img-preview {
  height: 100%;
  width: 100%;
  border-radius: 0.25em;
}

.profile-box {
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  height: 110px;
  /* minimum height */
  width: 110px;
  position: relative;
  background-color: #fff;
  border-radius: 0.25em;

  &:hover {
    background: #e6e6e6;
  }

  p {
    font-size: 1em;
    text-align: center;
    padding: 30px 10px;
  }
}

.box-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin: auto;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  // margin-bottom: 20px;
}

.sign-border {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  // padding: 10px;
  margin: auto;
  width: 100%;
  height: 170px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.sign-detail {
  padding: 10px;
  margin: auto;
  width: 40%;
}

.text-allergy {
  color: #dc0000ff;
}

.fade-noselect {
  color: rgba(0, 0, 0, 0.3);
}

// @media print {
//     bg-red {
//       background-color: #ffe8e8 !important;
//       -webkit-print-color-adjust: exact;
//     }
//     bg-gray {
//       background-color: #efefef !important;
//       -webkit-print-color-adjust: exact;
//     }
// }</style>
<i18n>
{
  "en": {
    "pageTitle": "Regular Registration Form",
    "patient_info": "Patient Information",
    "branch_select": "Branch",
    "branch_hospital": "Hospital",
    "branch_clinic": "Clinic",
    "visit_date": "First Visit Date",
    "title": "Title",
    "firstName": "First Name",
    "lastName": "Last Name",
    "dob": "Date of Birth",
    "age": "Age",
    "male": "Male",
    "female": "Female",
    "gender": "Gender",
    "marital_status": "Marital Status",
    "nation": "Nationality",
    "id_no": "I.D. No./ Passport No.",
    "occupation": "Occupation",
    "religion": "Religion",
    "phone_th": "Thai Mobile Phone",
    "phone_inter": "International Mobile Phone",
    "phone": "Mobile Phone",
    "email": "Email Address",
    "symptom": "Symptom",
    "allergy": "Allergy",
    "drug_allergy": "Drug Allergy",
    "yes": "Yes",
    "no": "No",
    "other_allergy": "Other Allergy",
    "other_allergy_ph": "",
    "home_addr": "Home Address",
    "city": "City",
    "country": "Country",
    "zipcode": "Zip Code",
    "type_stay_phiphi": "Type of Stay in Phiphi",
    "tourist": "Tourist",
    "arrival_date_in": "Arrival Date in Phiphi",
    "departure_date_out": "Departure Date out in Phiphi",
    "present_addr_th": "Present Address in Thailand",
    "resident": "Resident",
    "work_place": "Work Place",
    "hotel_name": "Hotel Name",
    "resident_name": "Resident Name",
    "emergency_contact": "Emergency Contact Person",
    "relation": "Relation",
    "same_home_addr": "Same as my home address",
    "address_placeholder": "Bldg. No. / Blk No. / Street / District",
    "insurance": "Travel Insurance",
    "not_sure": "Not Sure",
    "how_did_you_find_us": "How did you find us?",
    "note": "Note",
    "certify_check": "I certify that the information provided above is true and correct.",
    "signature": "Signature",
    "patient": "Patient",
    "parent": "Parents / Guardian",
    "spouse": "Spouse / Friend",
    "staff": "Staff",
    "other": "Other",
    "relation_to_patient": "Relationship to patient",
    "fullname_information": "Full Name of person who give the information",
    "create_patient": "Create Patient",
    "cancel": "Cancel"
  },
  "th": {
    "pageTitle": "ลงทะเบียนผู้ป่วยใหม่",
    "patient_info": "ข้อมูลพื้นฐาน",
    "branch_select": "สาขา",
    "branch_hospital": "สาขา เวิลด์เมดบีชฟร้อน (ข้างสถานีตำรวจ)",
    "branch_clinic": "สาขา เวิลด์เมดคลินิก (ซอยสลิงกี้)",
    "visit_date": "วันที่ลงทะเบียน",
    "title": "คำนำหน้า",
    "firstName": "ชื่อ",
    "lastName": "นามสกุล",
    "dob": "วันเกิด",
    "age": "อายุ",
    "male": "ชาย",
    "female": "หญิง",
    "gender": "เพศ",
    "marital_status": "สถานภาพ",
    "nation": "สัญชาติ",
    "id_no": "หมายเลขบัตรประชาชน/ หนังสือเดินทาง",
    "occupation": "อาชีพ",
    "religion": "ศาสนา",
    "phone_th": "เบอร์โทรศัพท์ไทย",
    "phone_inter": "",
    "phone": "เบอร์โทรศัพท์",
    "email": "อีเมล",
    "symptom": "อาการสำคัญ",
    "allergy": "ประวัติการแพ้",
    "drug_allergy": "แพ้ยา",
    "yes": "มี",
    "no": "ไม่มี",
    "other_allergy": "แพ้สิ่งอื่นๆ",
    "other_allergy_ph": "",
    "home_addr": "ที่อยู่ปัจจุบัน",
    "city": "จังหวัด",
    "country": "ประเทศ",
    "zipcode": "รหัสไปรษณี",
    "type_stay_phiphi": "วัตถุประสงค์ในเกาะพีพี",
    "tourist": "ท่องเที่ยว",
    "arrival_date_in": "วันที่เดินทางมาถึงเกาะพีพี",
    "departure_date_out": "วันที่เดินทางออกจากเกาะพีพี",
    "present_addr_th": "ที่พักในเกาะพีพี",
    "resident": "ทำงาน/อาศัยอยู่",
    "work_place": "สถานที่ทำงาน",
    "hotel_name": "ที่พักในเกาะพีพี",
    "resident_name": "ที่อยู่ประจำในเกาะพีพี",
    "emergency_contact": "บุคคลติดต่อกรณีฉุกเฉิน",
    "relation": "ความสัมพันธ์กับผู้ป่วย",
    "same_home_addr": "ที่อยู่เดียวกันกับข้าพเจ้า",
    "address_placeholder": "เลขที่/ ตรอก/ ซอย/ ถนน/ ตำบล/ อำเภอ",
    "insurance": "ประกันสุขภาพ",
    "not_sure": "ไม่แน่ใจ",
    "how_did_you_find_us": "รู้จักเราได้อย่างไร",
    "note": "เพิ่มเติม",
    "certify_check": "ข้าพเจ้ารับรองว่าข้อมูลดังกล่าวข้างต้นเป็นความจริง",
    "signature": "ลายมือชื่อผู้ให้ข้อมูล",
    "patient": "ผู้ป่วย",
    "parent": "ผู้ปกครอง",
    "spouse": "คู่สมรส/เพื่อน",
    "staff": "พนักงาน",
    "other": "อื่นๆ",
    "relation_to_patient": "ความสัมพันธ์กับคนไข้",
    "fullname_information": "ชื่อ-นามสกุล ผู้ให้ข้อมูลแทนผู้ป่วย",
    "create_patient": "บันทึก",
    "cancel": "ยกเลิก"
  }
}
</i18n>
